import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import { modificaEmailLogin, modificaSenhaLogin, resetaUsuarioSemCadastro } from "../../../acoes/Auth";
import { criarConta } from "../../../acoes/cadastroEspecialistas/CriarConta";
import { modificaTipoCampoSenha, modificaIp } from "../../../acoes/AuxiliaresAcoes";

import Botao from "../../../global/BotaoAcao";
import ControlaVisibilidadeSenha from "../../../global/forms/ControlaVisibilidadeSenha";
import FormErroCadastro from "./formCadastroUsuario/FormErroCadastroEspecialista";

class FormCadastroUsuario extends Component {

  componentDidMount() {

    this.props.modificaTipoCampoSenha("password");
    this.props.resetaUsuarioSemCadastro();
    this.props.modificaIp();
  }

  exibeBotao = texto => {

    if(this.props.temErro && (this.props.msgErro === "Usuário já cadastrado")) 
      return(
          <div>

            <p className="texto-area">An account is already linked to this email.</p>

            <Link to="/especialistas/entrar">Click here to access</Link>

          </div>
        )
      
    return <Botao textoBotao="Create account" classeBotao="botao-acao" />
  }

  acaoForm = e => {

    e.preventDefault();

    let { email, senha, contagemErros, ip, idRecrutador } = this.props;

    this.props.criarConta({ email, senha, contagemErros, ip, idRecrutador });
  }

  render() {

    if(this.props.contagemErros > 1) 
      return(<FormErroCadastro />)

    return (
      <form id="form-cadastro-usuario" onSubmit={this.acaoForm}>

        <div className="topo-form" style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 0 }}>

          <p className="texto-area">Please, register your email and password. By creating your account, you agree to our <Link to="/termos-de-uso">Terms of Use</Link> and <Link to="/politica-de-privacidade">Privacy Policy</Link>.</p>

        </div>

        <div className="corpo-form">

          <div className="input-group">

            <label>Email</label>

            <input
              type="email"
              className="input-field"
              required
              value={this.props.email}
              onChange={e => this.props.modificaEmailLogin(e.target.value)}
            />

          </div>

          <div className="input-group" style={{ marginBottom: 20 }}>

            <label>Password</label>

            <input
              type={this.props.tipoCampoSenha}
              className="input-field"
              required
              maxLength={12}
              value={this.props.senha}
              onChange={e => this.props.modificaSenhaLogin(e.target.value)}
            />

            <span style={{ fontSize: 11, color: "#909090", position: "absolute", display: "block", marginTop: -10 }}>(Password with a minimum of 6 and a maximum of 12 characters)</span>

            <ControlaVisibilidadeSenha />

          </div>

          { this.exibeBotao() }

        </div>

      </form>
    );
  }
}

const mapStateToProps = state => (
	{
		temErro: state.auxiliaresReducer.temErro,
		msgErro: state.auxiliaresReducer.msgErro,
    ip: state.auxiliaresReducer.ip,
    email: state.authReducer.emailLogin,
		senha: state.authReducer.senhaLogin,
		contagemErros: state.cadastroReducer.contagemErros,
    tipoCampoSenha: state.auxiliaresReducer.tipoCampoSenha,
    idRecrutador: state.cadastroReducer.idRecrutadorQueIndicou
	}
)

export default connect(mapStateToProps, {
  modificaEmailLogin,
  modificaSenhaLogin,
  criarConta,
  resetaUsuarioSemCadastro,
  modificaIp,
  modificaTipoCampoSenha })(FormCadastroUsuario)
