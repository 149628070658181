const initState = {
	loading: false,
	temErro: false,
	msgErro: '',
	loadingCep: false,
	cientePoliticaPrivacidade: false,
	dadosCienciaPoliticaPrivacidade: {
		chave: '',
		data: ''
	},
	tamanhoMenuDashboard: 300,
	deslocamentoMenuDashboard: 'tranlateX(-320px)',
	modalVisivel: false,
	menuDashboardAtivo: "visao-geral",
	abaAreaAtiva: '',
	nomeAbaAreaAtiva: '',
	sucessoAcao: false,
	msgSucessoAcao: "",
	dropDownAberto: false,
	idDropDown: '',
	houveErroInicializacao: false,
	msgErroInicializcao: '',
	tipoCampoSenha: "password",
	ip: ""
}

const auxiliaresReducer = (state = initState, action) => {
	switch(action.type) {

		case "modifica_loading":
			return { ...state, loading: action.payload }

		case "tem_erro":
			return { ...state, temErro: true, msgErro: action.payload, loading: false }

    	case "reseta_erro":
        	return { ...state, temErro: false, msgErro: "" }

		case "modifica_loading_cep":
			return { ...state, loadingCep: action.payload }

		case "modifica_menu_dashboard_ativo":
			return { ...state, menuDashboardAtivo: action.payload }

		case "modifica_tamanho_menu_dashboard":
			return { ...state, tamanhoMenuDashboard: action.payload }

		case "modifica_ciente_politica_privacidade":
			return { ...state, cientePoliticaPrivacidade: action.payload }

		case "modifica_dados_ciencia_politica_privacidade":
			return { ...state, dadosCienciaPoliticaPrivacidade: action.payload }

		case "modifica_modal_visivel":
			return { ...state, modalVisivel: action.payload }

		case "modifica_deslocamento_menu_dashboard":
			return { ...state, deslocamentoMenuDashboard: action.payload }

		case "modifica_aba_area_ativa":
			return {
				...state,
				abaAreaAtiva: action.payload["aba"],
				nomeAbaAreaAtiva: action.payload["nome"]
			}

		case "modifica_sucesso_acao":
			return {
				...state,
				sucessoAcao: action.payload["status"],
				msgSucessoAcao: action.payload["msg"],
				loading: false,
				temErro: false,
				msgErro: ""
			}

		case "modifica_status_dropdown":
			return {
				...state,
				dropDownAberto: action.payload["status"],
				idDropDown: action.payload["id"]
			}

		case "modifica_houve_erro_inicializacao":
        	return { 
        		...state, 
        		houveErroInicializacao: action.payload["status"], 
        		msgErroInicializcao: action.payload["msg"] 
        	}	

        case "modifica_tipo_campo_senha":
        	return { ...state, tipoCampoSenha: action.payload }	

        case "modifica_ip":
      		return { ...state, ip: action.payload }	

		default:
			return state
	}
}

export default auxiliaresReducer;
