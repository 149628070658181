import { database } from '../../config/VariaveisFirebase';

// atualiza a disponibilidade de atendimentos dos especialistas conforme eles vão preenchendo
export const modificaOpcoesAtendimento = ({ id, tipo, valor }) => {
	return dispatch => {

		let baseEspecialista = database.ref('especialistas/' + id);

		baseEspecialista.once('value', data => {

			let { opcoesAtendimento } = data.val();

			if(opcoesAtendimento) {

				if(valor.length < 1) valor = "";

				opcoesAtendimento[tipo] = valor;

				baseEspecialista.update({ opcoesAtendimento });

				let alvo = "modifica_dias_semana";

				if(tipo === "horarios")
					alvo = "modifica_horarios";

				if(tipo === "idiomas")
					alvo = "modifica_idiomas";

				dispatch({ type: alvo, payload: valor })
			}
		})
	}
}

// confirma se a disponibilidade de dias da semana e de horários foram selecionadas
export const verificaOpcoesAtendimento = ({ id, diasSemana, horarios, idiomas }) => {
	return dispatch => {

		dispatch({ type: "modifica_loading", payload: true });

		let textoErro;

		if(diasSemana.length < 1)
			textoErro = "Por favor, selecione ao menos um dia da semana";

		if(!textoErro && horarios.length < 1)
			textoErro = "Por favor, selecione ao menos um horário";

		if(!textoErro) {

			let opcoes = { diasSemana, horarios, idiomas };

			database.ref(`especialistas/${id}/opcoesAtendimento`)
			.update(opcoes, erro => {

				if(erro) {

					dispatch({ type: "tem_erro", payload: "Erro ao salvar a sua disponibilidade. Por favor, tente novamente" });

				} else {

					dispatch({ 
						type: "modifica_opcoes_atendimentos_especialista", 
						payload: opcoes
					});

					dispatch({ type: "modifica_passo", payload: 4 });
				}

				setTimeout(() => {
		      		dispatch({ type: "modifica_loading", payload: false });
		    	}, 300);
			});

		} else {
			dispatch({ type: "tem_erro", payload: textoErro });
		}
	}
}