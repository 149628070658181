import { database } from '../config/VariaveisFirebase';
import moment from "moment";
import publicIp from "public-ip";

import { MENSAGEM_ERRO, FORMATA_NOME_MINUSCULA } from "./FuncoesGerais";

export const modificaTemErro = msg => {
	return {
		type: "tem_erro",
		payload: msg
	}
}

export const resetaErro = () => {
	return { type: "reseta_erro" }
}

export const modificaLoading = valor => {
	return {
		type: "modifica_loading",
		payload: valor
	}
}

export const sucessoAcao = msg => {
	return {
		type: "modifica_sucesso_acao",
    payload: { status: true, msg }
	}
}

export const resetaSucessoAcao = () => {
	return {
		type: "modifica_sucesso_acao",
		payload: { status: false, msg: "" }
	}
}

export const modificaTipoCampoSenha = valor => {
	return {
		type: "modifica_tipo_campo_senha",
		payload: valor
	}
}

// obtem o IP dos usuários, utilizado principalmente em casos de erros
export const modificaIp = () => {
	return dispatch => {

		async function verificaIp() {

			const ip = await publicIp.v4();

			if(ip)
				dispatch({ type: "modifica_ip", payload: ip });
		}

		verificaIp();
	}
}

// modifica no container as variáveis de acordo com o tipo de campo
export const modificaCampo = (tipo, texto) => {
	return {
		type: tipo,
		payload: texto
	}
}

export const modificaCientePoliticaPrivacidade = (chave, data) => {
	return dispatch => {

		dispatch({ type: "modifica_ciente_politica_privacidade", payload: true });

		fetch(`https://us-central1-omm-be.cloudfunctions.net/salvaCientePolitica`,
		{
			method: 'POST',
			headers: {
				'Accept': '*/*',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ "chave": chave })
		})
		.then(resultado => {

			resultado.json()
			.then(resposta => {

				let { status } = resposta.data,
						dados = { chave, data }

				if(status === 200)
					dispatch({ 
						type: "modifica_dados_ciencia_politica_privacidade", 
						payload: dados 
					});
			})
		})
	}
}

export const enviaMsgFaleConosco = (nome, email, assunto, msg) => {
	return dispatch => {

		dispatch({ type: "modifica_loading", payload: true });

		let emailDestino;

		switch(assunto) {

			case "Suporte Técnico":
				emailDestino = "suporte@ommbe.com";
				break;

			case "Parcerias":
				emailDestino = "parcerias@ommbe.com";
				break;

			case "Financeiro":
				emailDestino = "financeiro@ommbe.com";
				break;

			default:
				emailDestino = "comercial@ommbe.com";
		}

		fetch("https://us-central1-omm-be.cloudfunctions.net/enviaMsgFaleConosco", {
			method: 'POST',
			headers: {
				'Accept': '*/*',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"assunto": assunto,
				"emailDestino": emailDestino,
				"msg": msg,
				"nome": nome,
				"email": email,
				"tel": ""
			})
		})
		.then(resposta => resposta.json())
		.then(resultado => {

			let { status, message } = resultado.data;

			if(status === 200)
				dispatch({
					type: "modifica_sucesso_acao",
					payload: { status: true, msg: "Mensagem enviada!" }
				});
			else
				dispatch({ type: 'tem_erro', payload: message })
		})
		.catch(erro => {

			let textoErro = "Ocorreu um erro na tentativa de enviar sua mensagem. Por favor, tente novamente";

			if(erro.message.match(/network/g))
			 textoErro = "Erro ao tentar enviar sua mensagem. Por favor, Verifique sua conexão";

			dispatch({ type: 'tem_erro', payload: textoErro })
		});
	}
}

export const marcaNotificacoesComoVisualizadas = (idUsuario, notificacoes) => {
	return dispatch => {

		notificacoes.forEach(item => {

			if(!item.visualizada) {

				item.visualizada = true;

				database.ref(`notificacoes/${idUsuario}/${item.id}`)
				.update({ visualizada: true });
			}
		});

		dispatch({ type: 'modifica_notificacoes_recebidas', payload: notificacoes });
	}
}

export const apagaNotificacao = (idUsuario, idNotificacao) => {
	return dispatch => database.ref(`notificacoes/${idUsuario}/${idNotificacao}`).remove()
}

export const houveErroInicializacao = erro => {
	return dispatch => {

    dispatch({ 
      type: "modifica_houve_erro_inicializacao", 
      payload: { status: true, msg: MENSAGEM_ERRO(erro) } 
    });
	}
}

export const modificaHouveErroInicializacao = (status, msg) => {
	return {
		type: "modifica_houve_erro_inicializacao",
		payload: { status, msg }
	}
}

export const geraChaveNo = (data, nome) => moment(data).format("YYYYMMDDHHmmss") + FORMATA_NOME_MINUSCULA(nome);

export const salvaErroApp = ({ idUsuario, erro, info }) => {
	return dispatch => {

		const data = moment().format();

		const chave = `${moment(data).format("YYYYMMDDHHmmss")}${idUsuario}`;

		database.ref(`admin/erros/${chave}`)
		.set({ idUsuario, data, erro, info })
	}
}
