const initState = {
  verificandoStatusLogin: true,
  emailLogin: '',
	senhaLogin: '',
  idUsuario: '',
  usuarioSemCadastro: false,
  tipoUsuario: '',
  usuarioLogado: false,
  verificandoCodigo: false,
  codigoRecuperacaoSenhaValido: false,
  senhaAtualizada: false,
  statusRecuperacaoSenha: false,
  dataUltimoLogin: '',
  notificacoesRecebidas: [],
  notificacoesVerificadas: false,
  dadosPessoaisEditar: {
    nome: '',
    tel: '',
    tipoUsuario: ''
  },
  prefixoPagina: ''
}

const authReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_verificando_status_login":
      return { ...state, verificandoStatusLogin: action.payload }

		case "modifica_email_login":
			return { ...state, emailLogin: action.payload }

		case "modifica_senha_login":
			return { ...state, senhaLogin: action.payload }

    case "modifica_id_usuario":
      return { ...state, idUsuario: action.payload }

    case "modifica_usuario_sem_conta":
      return { ...state, usuarioSemCadastro: action.payload }

    case "modifica_tipo_usuario":
      return { ...state, tipoUsuario: action.payload }

    case "modifica_usuario_logado":
      return {
        ...state,
        usuarioLogado: action.payload['estaLogado'],
        tipoUsuario: action.payload['tipoUsuario'],
        dataUltimoLogin: action.payload['dataUltimoLogin']
      }

    case "modifica_verificando_codigo":
      return { ...state, verificandoCodigo: action.payload }

    case "modifica_validade_codigo_recuperacao_senha":
      return { ...state, codigoRecuperacaoSenhaValido: action.payload }

    case "modifica_senha_atualizada":
      return { ...state, senhaAtualizada: action.payload }

    case 'modifica_status_recuperacao_senha':
      return { ...state, statusRecuperacaoSenha: action.payload }

    case 'modifica_notificacoes_recebidas':
      return { 
        ...state, 
        notificacoesRecebidas: action.payload,
        notificacoesVerificadas: true 
      }  

    case 'modifica_notificacoes_verificadas':
      return { ...state, notificacoesVerificadas: action.payload }  

    case "altera_dados_pessoais_editar":
      return { ...state, dadosPessoaisEditar: action.payload }  

    case "altera_prefixo_pagina":
      return { ...state, prefixoPagina: action.payload }  

		default:
			return state
	}
}

export default authReducer;
