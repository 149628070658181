import { auth, database } from '../config/VariaveisFirebase';
import moment from 'moment';
import publicIp from "public-ip";

import { MENSAGEM_ERRO } from "./FuncoesGerais";

// verificação inicial se há algum registro de acesso para que o login ocorra automaticamente
export const modificaVerificandoStatusLogin = valor => {
	return {
		type: "modifica_verificando_status_login",
		payload: valor
	}
}

// modifica as informações para acesso no container
export const modificaEmailLogin = texto => {
	return {
		type: "modifica_email_login", 
		payload: texto.trim()
	}
}

export const modificaSenhaLogin = texto => {
	return {
		type: "modifica_senha_login",
		payload: texto
	}
}

// para os casos em que os usários ainda não criaram conta
export const resetaUsuarioSemCadastro = () => {
	return {
		type: "modifica_usuario_sem_conta",
		payload: false
	}
}

// usuários são classificados em 3 tipos: colaborador, rh e especialista
export const modificaTipoUsuario = valor => {
	return {
		type: "modifica_tipo_usuario",
		payload: valor
	}
}

export const autenticaConta = ({ email, senha }) => {
	return dispatch => {

		if(senha.length >= 6 && senha.length <= 10) {

			dispatch({ type: "modifica_loading", payload: true });

			// verifica se o(a) usuário(a) criou conta na plataforma 
  		auth.fetchSignInMethodsForEmail(email)
      .then(providers => {

      	if(providers.length < 1)	{

					dispatch({ type: "modifica_usuario_sem_conta", payload: true });
					dispatch({ type: "modifica_loading", payload: false });

        } else {

        	auth.signInWithEmailAndPassword(email, senha)
					.then(value => {

						const valores = Object.values(value),
									idUsuario = valores[0].uid;

						// verifica se é uma conta de especialista ou de RH
						database.ref(`especialistas/${idUsuario}`)
						.once("value").then(snapshot => {	
						
							let dados = snapshot.val();

							if(dados) {

								let { coordenadasAtendimento, dadosBancarios, documentos, enderecoAtendimento, especialidades, favoritadoPor, infosBasicas, infosProfissionais, mediaAvaliacoes, opcoesAtendimento } = dados;

								async function verificaIp() {

									let ip = await publicIp.v4();

									if(ip)
										database.ref(`especialistas/${idUsuario}`).update({ 
											dataUltimoLogin: moment().format(),
											ipUltimoAcesso: ip 
										});
								}

								verificaIp();

								dispatch({ type: "modifica_id_usuario", payload: idUsuario });

								dispatch({ 
									type: "modifica_infos_basicas_especialista", 
									payload: infosBasicas 
								});

								dispatch({ 
									type: "modifica_especialidades_cadastradas_especialista", 
									payload: especialidades 
								});

								dispatch({ 
									type: "modifica_opcoes_atendimentos_especialista", 
									payload: opcoesAtendimento 
								});

								dispatch({ 
									type: "modifica_coordenadas_atendimento_especialista", 
									payload: coordenadasAtendimento 
								});

								dispatch({ 
									type: "modifica_endereco_atendimento_especialista", 
									payload: enderecoAtendimento 
								});

								dispatch({ 
									type: "modifica_infos_profissionais_especialista", 
									payload: infosProfissionais 
								});

								dispatch({ 
									type: "modifica_dados_bancarios_especialista", 
									payload: dadosBancarios 
								});

								dispatch({ 
									type: "modifica_documentos_especialista", payload: documentos 
								});

								dispatch({ 
									type: "modifica_media_avaliacoes_especialista", 
									payload: mediaAvaliacoes 
								});

								dispatch({ 
									type: "modifica_qtd_vezes_favoritado", payload: favoritadoPor 
								});

								let passo, tipoUsuario = "especialista",
										{ cpf, dataNascimento, nome, tel } = infosBasicas,
										{ diasSemana, horarios } = opcoesAtendimento;

								if(cpf === "" || dataNascimento === "" || nome === "" || tel === "") 
									passo = 1;

								if(!passo) {
									
									if(especialidades === "" || diasSemana === "" || horarios === "") 
										passo = 2;
								}

								if(passo) {

									dispatch({ type: "modifica_passo", payload: passo });
									tipoUsuario = "não concluído";
								}

								dispatch({
									type: "modifica_usuario_logado",
									payload: {
										estaLogado: true,
										tipoUsuario,
										dataUltimoLogin: moment().format()
									}
								});

							} else {

								let baseUsuario = database.ref(`usuarios/${idUsuario}`);

								baseUsuario.once("value").then(snapshot => {

									let { nome, empresa, tel, urlFotoMini, urlFotoPerfil, configuracoes, cpf, dataNascimento } = snapshot.val();

									async function verificaIp() {

										let ip = await publicIp.v4();

										if(ip)
											baseUsuario.update({ 
												dataUltimoLogin: moment().format(),
												ipUltimoAcesso: ip 
											});
									}

									verificaIp();

									if(empresa !== "" && empresa.id !== "") {

										database.ref(`empresas/${empresa.id}`)
										.once("value").then(snapshot => {

											let dadosEmpresa = snapshot.val();

											if(dadosEmpresa) {

												let { habilitadosAcessoDashboard, filiais, planoContratado } = dadosEmpresa;

												if(planoContratado !== "Sem Plano") {

													let idsHabilitados = Object.keys(habilitadosAcessoDashboard);

													if(idsHabilitados.includes(idUsuario)) {

														dispatch({ type: "modifica_id_usuario", payload: idUsuario });

														if(filiais !== "");
															dispatch({ type: "modifica_filiais", payload: filiais });	

														dispatch({
															type: "modifica_dados_usuario_rh",
															payload: { 
																nome, 
																tel, 
																urlFotoMini, 
																urlFotoPerfil, 
																configuracoes, 
																cpf, 
																dataNascimento, 
																empresa, 
																filiais, 
																planoContratado 
															}
														})
														
														dispatch({
															type: "modifica_usuario_logado",
															payload: {
																estaLogado: true,
																tipoUsuario: "rh",
																dataUltimoLogin: moment().format()
															}
														});

														setTimeout(() => {
															dispatch({ type: "modifica_loading", payload: false });
														}, 1000);

													}	else {

														console.log("não habilitado", idUsuario)

														dispatch({ 
															type: "modifica_tipo_usuario", 
															payload: "colaborador" 
														})

														dispatch({ type: "modifica_loading", payload: false });
													} 	

												} else {

													dispatch({ 
														type: "tem_erro", 
														payload: "Empresa sem plano ativo. Por favor, entre em contato conosco caso deseje ativá-lo" 
													})
												}

											} else {

												dispatch({ type: "modifica_tipo_usuario", payload: "colaborador" })

												dispatch({ type: "modifica_loading", payload: false });
											}
										})
										.catch(erro => dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) }))

									} else {

										dispatch({ type: "modifica_tipo_usuario", payload: "colaborador" })

										dispatch({ type: "modifica_loading", payload: false });
									}
								})
								.catch(erro => dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) }))		
							}
						})
						.catch(erro => dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) }))		
					})
					.catch(erro => dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) }))
        }
			})
			.catch(erro => dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) }))
    	
		} else {
			dispatch({ type: "tem_erro", payload: "Por favor, verifique a sua senha" });
		} 
	}
}

export const sairConta = () => {
	return dispatch => {

		auth.signOut().then(() => {

			dispatch({ type: "modifica_id_usuario", payload: '' });
			dispatch({
				type: "modifica_usuario_logado",
				payload: { estaLogado: false, tipoUsuario: '', dataUltimoLogin: '' }
			});

			dispatch({ type: "modifica_email_login", payload: "" });
			dispatch({ type: "modifica_senha_login", payload: "" });
		})
		.catch(erro => {
			alert("Erro ao efetuar a saída da conta. Por favor, tente novamente")
		});
	}
}

// verifica se o código de recuperação de senha está válido
export const verificaValidadeCodigoRecuperacaoSenha = (idUsuario, codigo) => {
	return dispatch => {

		dispatch({ type: "modifica_verificando_codigo", payload: true })

		fetch(`https://us-central1-omm-be.cloudfunctions.net/verificaCodigoRecuperaSenha`,
			{
				method: 'POST',
				headers: {
					'Accept': '*/*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					"idUsuario": idUsuario,
					"codigo": codigo
				})
		})
		.then(resultado => {

			resultado.json()
			.then(resposta => {

				let { status, message } = resposta.data;

				if(status === 200) {

					dispatch({
						type: "modifica_validade_codigo_recuperacao_senha",
						payload: true
					});

				} else {
					dispatch({ type: "tem_erro", payload: message });
				}

				dispatch({ type: "modifica_verificando_codigo", payload: false })
			})
		})
		.catch(erro => {

			dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) });
			dispatch({ type: "modifica_verificando_codigo", payload: false })
		});
	}
}

// modifica a senha armazenada no container
export const modificaSenhaAtualizada = valor => {
	return {
		type: "modifica_senha_atualizada",
		payload: valor
	}
}

export const atualizaSenha = (senha, idUsuario, codigo) => {
	return dispatch => {

		dispatch({ type: "modifica_loading", payload: true })

		fetch(`https://us-central1-omm-be.cloudfunctions.net/atualizaSenha`,
			{
				method: 'POST',
				headers: {
					'Accept': '*/*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					"idUsuario": idUsuario,
					"codigo": codigo,
					"senha": senha
				})
		})
		.then(resultado => {

			resultado.json()
			.then(resposta => {

				let { status, message } = resposta.data;

				if(status === 200) {

					dispatch({ type: "modifica_senha_atualizada", payload: true });
					dispatch({ type: "modifica_loading", payload: false })

				} else {
					dispatch({ type: "tem_erro", payload: message });
				}
			})
		})
		.catch(erro => dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) }));
	}
}

export const modificaStatusRecuperacaoSenha = valor => {
	return {
		type: 'modifica_status_recuperacao_senha',
		payload: valor
	}
}

// comunica com o backend para enviar o link para resetar senha
export const solicitaNovaSenha = email => {
	return dispatch => {

    dispatch({ type: "modifica_loading", payload: true });

		auth.fetchSignInMethodsForEmail(email)
		.then(providers => {

			if(providers !== "")	{

				let textoErro = "Erro ao enviar o e-mail para resetar sua senha. Por favor, tente novamente" ;

				fetch("https://us-central1-omm-be.cloudfunctions.net/resetaSenha", {
					method: 'POST',
					headers: {
						'Accept': '*/*',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"email": email
					})
				})
				.then(resultado => {

					resultado.json()
					.then(resposta => {

						let { status } = resposta.data;

						if(status === 200) {

							dispatch({ 
								type: 'modifica_status_recuperacao_senha', payload: true 
							});

							setTimeout(() => {
								dispatch({ type: "modifica_loading", payload: false });
							}, 300);

						} else {
							dispatch({ type: "tem_erro", payload: textoErro });
						}
					})
				})
				.catch(erro => dispatch({ type: "tem_erro", payload: textoErro }))

			} else {
				
				dispatch({ 
					type: "tem_erro", payload: "Por favor, verifique o e-mail informado" 
				});
			}
		})
		.catch(erro => dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) }));
	}
}

// autenticação da conta para o dashboard administrador da OMM BE
export const autenticaContaAdmin = ({ email, senha }) => {
	return dispatch => {

    if(senha.length >= 6 && senha.length <= 10) {

    	dispatch({ type: "modifica_loading", payload: true });

      auth.fetchSignInMethodsForEmail(email)
      .then(providers => {

				if(providers.length < 1) {

					dispatch({ 
						type: "tem_erro", 
						payload: "Você não tem autorização para acessar essa área" 
					});

				} else {

					auth.signInWithEmailAndPassword(email, senha)
					.then(value => {

						const valores = Object.values(value),
								  idUsuario = valores[0].uid;

						database.ref('admin/habilitadosAcessoAdmin')
						.once("value").then(snapshot => {

							let autorizados = snapshot.val();

							if(autorizados.hasOwnProperty(idUsuario)) {

								dispatch({ type: "modifica_id_usuario", payload: idUsuario });

								let baseUsuario = database.ref(`usuarios/${idUsuario}`);

								baseUsuario.once("value", snapshot => {

									let { nome, tel, urlFotoMini, urlFotoPerfil, configuracoes, cpf, dataNascimento } = snapshot.val(),
											{ classe } = autorizados[idUsuario],
											acessos = [];

									if(classe === "comum")
										acessos = autorizados[idUsuario].acessos;		

									baseUsuario.update({ dataUltimoLogin: moment().format() });

									dispatch({
										type: "modifica_dados_admin",
										payload: { 
											nome, 
											tel, 
											urlFotoMini, 
											urlFotoPerfil, 
											configuracoes, 
											cpf, 
											dataNascimento,
											classe,
											acessos 
										}
									})

									setTimeout(() => {

										dispatch({
											type: "modifica_usuario_logado",
											payload: {
												estaLogado: true,
												tipoUsuario: "admin",
												dataUltimoLogin: moment().format()
											}
										});

									}, 500);
								})	

							} else {
								dispatch({ type: "tem_erro", payload: "Você não tem autorização para acessar essa área" });
							}
						})		  
					})
					.catch(erro => dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) }));
				}
      })
      .catch(erro => dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) }));

    } else {
      dispatch({ type: "tem_erro", payload: "Por favor, verifique a sua senha" });
    }
	}
}
