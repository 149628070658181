import React, { Component } from "react";
import { Spinner } from "react-activity";
import { connect } from "react-redux";

import { modificaTemErro, resetaErro, resetaSucessoAcao } from "../acoes/AuxiliaresAcoes";

class Botao extends Component {

  componentDidMount() {
    this.props.resetaSucessoAcao();
  }

  render() {
    
    if(this.props.loading)
      return(
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 5 }}>
          <Spinner color={"#46189a"} size={22} />
        </div>
      )

    if(this.props.temErro)
      return(
        <div className="msg-retorno msg-erro" onClick={() => this.props.resetaErro()}>
          <p>{this.props.msgErro}</p>
        </div>
      )

    if(this.props.sucessoAcao)
      return(
        <div className="msg-retorno msg-sucesso" onClick={() => this.props.resetaSucessoAcao()}>
          <p>{this.props.msgSucessoAcao}</p>
        </div>
      )

    return(<button className={this.props.classeBotao}>{this.props.textoBotao}</button>)
  }
}

const mapStateToProps = state => ({
  loading: state.auxiliaresReducer.loading,
  temErro: state.auxiliaresReducer.temErro,
  msgErro: state.auxiliaresReducer.msgErro,
  sucessoAcao: state.auxiliaresReducer.sucessoAcao,
  msgSucessoAcao: state.auxiliaresReducer.msgSucessoAcao
});

export default connect(mapStateToProps, { modificaTemErro, resetaErro, resetaSucessoAcao })(Botao)
