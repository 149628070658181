import React from "react";
import { connect } from "react-redux";

import setaRetorno from "../../../imagens/cadastro/seta-retorno.png";

import { modificaPasso } from "../../../acoes/cadastroEspecialistas/CriarConta";

const RetornoFluxoCadastro = props => {

	return(
		<div className="topo-form" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

          <button
            className="seta-retorno"
            onClick={() => props.modificaPasso(props.passo)}
          >
            <img src={setaRetorno} alt="" />
          </button>

          <p className="texto-area">{ props.titulo }</p>

        </div>
	)
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { modificaPasso })(RetornoFluxoCadastro)