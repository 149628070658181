import React, { Component } from 'react';
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import { Spinner } from "react-activity";

import Botao from "../../../global/BotaoAcao";
import RetornoFluxoCadastro from "../elementos/RetornoFluxoCadastro";

import { atualizaInfosProfissionais, verificaInfosProfissionais } from "../../../acoes/cadastroEspecialistas/GerenciaInfosProfissionais";
import { modificaCampo } from "../../../acoes/AuxiliaresAcoes";
import { modificaModalAjustaFotosAberto, modificaFotoACortar } from "../../../acoes/DashboardAcoes";

import semFoto from "../../../imagens/cadastro/sem-foto.png";
import iconeInfo from "../../../imagens/cadastro/info-branco.png";

class FormInfosProfissionais extends Component {

  constructor() {

    super();

    this.state = { temCnpj: false }
  }

  componentDidMount() {
    document.querySelector("#campo-descricao").focus();
  }

  exibeCampoCnpj = () => {

    if(this.state.temCnpj)
      return(
        <div className="input-group" style={{ marginBottom: "10px" }}>

          <label>CNPJ</label>

          <input
            type="tel"
            className="input-field"
            required
            maxLength={18}
            placeholder={"Informe seu CNPJ"}
            value={this.props.cnpj}
            onChange={e => this.props.modificaCampo("modifica_cnpj", e.target.value)}
            onBlur={() => this.props.atualizaInfosProfissionais("cnpj", this.props.cnpj, this.props.id)}
          />

        </div>
      )

    return null;
  }

  exibeFotoPerfil() {

    if(this.props.loadingUpFoto)
      return(<Spinner color={"#46189a"} size={15} />)

    if(this.props.urlFotoPerfil !== "") 
      return(
        <img className="foto-perfil" src={this.props.urlFotoPerfil} alt="" />
      )

    return(
      <img style={{ width: "30px", height: "30px", opacity: .5 }} src={semFoto} alt="" />
    )
  }

  exibeTextoFoto = () => {

    if(this.props.loadingUpFoto)
      return "Carregando...";

    if(this.props.urlFotoPerfil !== "") 
      return "Imagem enviada!"

    return "Nenhuma imagem enviada";
  }

  exibeBotaoUp() {

    if(!this.props.loadingUpFoto)
      return(
        <div
          className="botao-upload"
          onClick={() => document.querySelector("#campo-foto-perfil").click()}
        >

          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#171718" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"><path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7-1.6V21"/><path d="M16 16l-4-4-4 4"/></svg>

        </div>
      )

    return null;
  }

  verificaInfosProfissionais = e => {

    e.preventDefault();

    let { id, nome, email, cnpj, descricao, urlFotoPerfil, urlFotoMini, videoApresentacao, conselhoEntidade, conselhoNumero } = this.props;

    if(!this.state.temCnpj)
      cnpj = "";

    this.props.verificaInfosProfissionais({ id, nome, email, cnpjInformado: cnpj, descricao, urlFotoPerfil, urlFotoMini, videoApresentacao, conselhoEntidade, conselhoNumero });
  }

  render() {

    return (
      <div className="form-dados" id="form-info-profissionais">

        <RetornoFluxoCadastro 
          passo={4} 
          titulo="Alguns dados profissionais" 
        />

        <form className="corpo-form" onSubmit={this.verificaInfosProfissionais}>

          <div className="input-group">

            <label>Descrição</label>

            <div
              className="botao-info"
              onClick={() => {

                confirmAlert({
          				title: 'Aviso',
          				message: 'Para fortalecer nossa parceria, por favor não informe seu telefone, site ou redes sociais. Seu perfil irá para análise',
          				buttons: [
          					{
          						label: 'OK',
          						onClick: () => false
          					}
          				]
          			});
              }}
            >
              <img src={iconeInfo} alt="i" />
            </div>


            <textarea
              className="input-field"
              id="campo-descricao"
              required
              placeholder={"Nos conte sobre sua experiência profissional na área de saúde. Mínimo de 50 caracteres"}
              value={this.props.descricao}
              onChange={e => this.props.modificaCampo("modifica_descricao", e.target.value)}
              onBlur={() => this.props.atualizaInfosProfissionais("descricao", this.props.descricao, this.props.id)}
            ></textarea>

          </div>

          <div className="input-group">

            <label>Foto de perfil</label>

            <input
              type="file"
              id="campo-foto-perfil"
              accept="image/png,image/jpeg,image/webp,image/jpg"
              onChange={e => {

                let foto = e.target.files[0];

                this.setState({ nomeArquivo: foto.name });

                const src = window.URL.createObjectURL(foto);

                this.props.modificaFotoACortar(src, "cadastro")

                this.props.modificaModalAjustaFotosAberto(true)

                setTimeout(() => {

                  const campoFoto = document.querySelector("#campo-foto-perfil");

                  if(campoFoto)
                    campoFoto.value = "";

                }, 3000)
              }}
            />

            <div className="bloco-preview-arquivo">

              <div className="preview-arquivo" id="preview-foto">

                { this.exibeFotoPerfil() }

                { this.exibeBotaoUp() }

              </div>

              <p className="texto-area">{ this.exibeTextoFoto() }</p>

            </div>

          </div>

          <p className="texto-area" style={{ color: "#6e6e6e", marginBottom: "5px", width: "100%", marginLeft: "15px", textAlign: "left" }}>Conselho de classe</p>

          <div className="porta-inputs">

            <div className="input-group" >

              <label>Entidade</label>

              <input
                type="text"
                className="input-field"
                style={{ textTransform: "uppercase" }}
                value={this.props.conselhoEntidade}
                onChange={e => this.props.modificaCampo("modifica_conselho_entidade", e.target.value)}
                onBlur={() => this.props.atualizaInfosProfissionais("conselhoEntidade", this.props.conselhoEntidade, this.props.id)}
              />

            </div>

            <div className="input-group" >

              <label>Número</label>

              <input
                type="text"
                className="input-field"
                value={this.props.conselhoNumero}
                onChange={e => this.props.modificaCampo("modifica_conselho_numero", e.target.value)}
                onBlur={() => this.props.atualizaInfosProfissionais("conselhoNumero", this.props.conselhoNumero, this.props.id)}
              />

            </div>

          </div>

          <div className="input-group" style={{ width: "100%", textAlign: "left" }}>

            <p className="texto-area" style={{ color: "#6e6e6e", marginBottom: "-10px", marginLeft: "5px", }}>Você tem CNPJ?</p>

            <div className="porta-opcoes">

              <div className="opcao" onClick={() => {

                document.querySelector("#cnpj-sim").checked = true;
                this.setState({ temCnpj: true })
              }}>
                <input required id="cnpj-sim" type="radio" value="sim" name="tem_cnpj" /> <p className="texto-area">Sim</p>
              </div>

              <div className="opcao" onClick={() => {

                document.querySelector("#cnpj-nao").checked = true;
                this.setState({ temCnpj: false })
              }}>
                <input required id="cnpj-nao" type="radio" value="nao" name="tem_cnpj" /> <p className="texto-area">Não</p>
              </div>

            </div>

          </div>

          { this.exibeCampoCnpj() }

          <Botao textoBotao="Finalizar" classeBotao="botao-area" />

        </form>

      </div>
    );
  }
}

const mapStateToProps = state => (
	{
    id: state.authReducer.idUsuario,
    nome: state.cadastroReducer.nome,
    email: state.authReducer.emailLogin,
    descricao: state.cadastroReducer.descricao,
    cnpj: state.cadastroReducer.cnpj,
    urlFotoPerfil: state.cadastroReducer.urlFotoPerfil,
    urlFotoMini: state.cadastroReducer.urlFotoMini,
    loadingUpFoto: state.cadastroReducer.loadingUpFoto,
    videoApresentacao: state.cadastroReducer.videoApresentacao,
    conselhoEntidade: state.cadastroReducer.conselhoEntidade,
    conselhoNumero: state.cadastroReducer.conselhoNumero
	}
)

export default connect(mapStateToProps, {
  modificaCampo,
  atualizaInfosProfissionais,
  verificaInfosProfissionais,
  modificaModalAjustaFotosAberto, 
  modificaFotoACortar })(FormInfosProfissionais)
