const initState = {
  carregandoListaEmpresas: false,
  listaEmpresas: [],
  modalFilialAberto: false,
  deslocamentoFormFilial: 'tranlateX(320px)',
  filiaisAtualizadas: false,
  filiais: [],
  registrosAlteracoesFiliais: [],
  gerenciarFilial: {
    status: false,
    dados: {}
  },
  modalContatoAberto: false,
  deslocamentoFormContato: 'tranlateX(320px)',
  contatosAtualizados: false,
  contatos: [],
  registrosAlteracoesContatos: [],
  gerenciarContato: {
    status: false,
    dados: {}
  },
  empresaAdicionada: false,
  carregandoDadosEmpresa: false,
  dadosEmpresa: {},
  carregandoDadosColaboradores: false,
  dadosColaboradores: [],
  carregandoHistoricoAtendimentos: false,
  historicoAtendimentos: [],
  listaFinanceiroEmpresas: []
}

const empresasReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_lista_empresas":
      return { ...state, carregandoListaEmpresas: action.payload }

    case "modifica_lista_empresas":
      return { 
        ...state, 
        listaEmpresas: action.payload, 
        carregandoListaEmpresas: false 
      }

    case "modifica_modal_filial_aberto":
      return { ...state, modalFilialAberto: action.payload }  

    case "modifica_filiais_atualizadas":
      return { ...state, filiaisAtualizadas: action.payload } 

    case "modifica_filiais":
      return { ...state, filiais: action.payload }   

    case "modifica_gerenciar_filial":
      return { ...state, gerenciarFilial: action.payload }  

    case "modifica_deslocamento_form_filial":
      return { ...state, deslocamentoFormFilial: action.payload }  

    case "modifica_modal_contato_aberto":
      return { ...state, modalContatoAberto: action.payload }  

    case "modifica_contatos_atualizados":
      return { ...state, contatosAtualizados: action.payload } 

    case "modifica_contatos":
      return { ...state, contatos: action.payload }   

    case "modifica_gerenciar_contato":
      return {  ...state, gerenciarContato: action.payload }   

    case "modifica_deslocamento_form_contato":
      return { ...state, deslocamentoFormContato: action.payload }  

    case "modifica_empresa_adicionada":
      return { ...state, empresaAdicionada: action.payload }  

    case "modifica_carregando_dados_empresa":
      return { ...state, carregandoDadosEmpresa: action.payload }   

    case "modifica_dados_empresa":
      return { ...state, dadosEmpresa: action.payload, carregandoDadosEmpresa: false }  

    case "modifica_carregando_dados_colaboradores":
      return { ...state, carregandoDadosColaboradores: action.payload }

    case "modifica_dados_colaboradores":
      return { ...state, dadosColaboradores: action.payload, carregandoDadosColaboradores: false }

    case "modifica_carregando_historico_atendimentos":
      return { ...state, carregandoHistoricoAtendimentos: action.payload }

    case "modifica_historico_atendimentos":
      return { ...state, historicoAtendimentos: action.payload, carregandoHistoricoAtendimentos: false }   

    case "modifica_registros_alteracoes_filiais":
      return { ...state, registrosAlteracoesFiliais: action.payload }  

    case "modifica_registros_alteracoes_contatos":
      return { ...state, registrosAlteracoesContatos: action.payload }    

    case "modifica_lista_financeiro_empresas":
      return { 
        ...state, 
        listaFinanceiroEmpresas: action.payload, 
        carregandoListaEmpresas: false 
      }  

    default:
      return state
  }
}

export default empresasReducer;
