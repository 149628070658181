import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { Spinner } from "react-activity";
import '../../css/entrar.css';

import MenuMobile from '../../global/nav/menuMobile/MenuMobile';
import MenuTopo from '../../global/nav/menuTopo/MenuTopo';
import AvisoPoliticaPrivacidade from "../../global/AvisoPoliticaPrivacidade";
import Rodape from "../../global/rodape/Rodape";
import FormAcessoUsuario from "../../componentes/entrar/FormAcessoUsuario";

import { modificaVerificandoStatusLogin, autenticaConta, resetaUsuarioSemCadastro } from "../../acoes/Auth";
import { modificaLoading, resetaErro } from "../../acoes/AuxiliaresAcoes";
import { modificaPasso } from "../../acoes/cadastroEspecialistas/CriarConta";
import { modificaVerTutorial } from "../../acoes/DashboardAcoes";

import logoColorido from "../../imagens/logo-colorido.png";

class Entrar extends Component {

  constructor() {

    super();

    this.state = { 
      redirecionando: false, 
      liberarAcesso: false, 
      dataUltimoLogin: '' 
    }
  }

  componentDidMount() {

    window.scroll({ top: 0, behavior: 'auto' });

    if(this.props.loading)
      this.props.modificaLoading(false);

    if(this.props.verTutorial)
      this.props.modificaVerTutorial(false, 1)

    this.props.modificaVerificandoStatusLogin(true);
    this.props.modificaPasso(0);

    let { usuarioLogado, email, senha, dataUltimoLogin } = this.props;

    this.setState({ dataUltimoLogin });

    if(usuarioLogado && this.props.tipoUsuario !== "admin")
      this.props.autenticaConta({ email, senha });
    else
      setTimeout(() => {
        this.props.modificaVerificandoStatusLogin(false);
      }, 800);
  }

  componentDidUpdate() {

    let novoLogin = this.props.dataUltimoLogin !== this.state.dataUltimoLogin;

    if(this.props.usuarioLogado && novoLogin && !this.state.redirecionando) {

      this.setState({ redirecionando: true });

      // um pequeno delay no redirecionamento para dar tempo para todas as informações serem salvas no container
      setTimeout(() => {
        this.setState({ liberarAcesso: true });
      }, 500);
    }

    if(this.props.verificandoStatusLogin) {

      if(this.props.usuarioSemCadastro || this.props.temErro) {

        if(this.props.usuarioSemCadastro)
          this.props.resetaUsuarioSemCadastro();

        if(this.props.temErro)
          this.props.resetaErro();

        setTimeout(() => {
          this.props.modificaVerificandoStatusLogin(false);
        }, 100);
      }
    }
  }

  redirecionando = () => {

    if(this.state.liberarAcesso) {

      switch(this.props.tipoUsuario) {

        case "rh":
          return <Redirect to="/empresas/visao-geral" />

        case "não concluído":
          return <Redirect to="/especialistas/cadastro" />  

        default: 
          return <Redirect to="/especialistas/visao-geral" />
      }
    }

    return null;
  }

  exibeConteudo = () => {

    if(this.props.verificandoStatusLogin)
      return(
        <div id="corpo-entrar" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

          <Spinner color={"#46189a"} size={22} />
          <p className="texto-area" style={{ fontSize: 16 }}>Checking last access...</p>

        </div>
      )

    return(
      <div id="corpo-entrar">

        <img id="logo-colorido" src={logoColorido} alt="OMM BE" />

        <div id="bloco-entrar" style={{ marginTop: 20, marginBottom: 25 }}>

          <h4 className="titulo-area" style={{ marginBottom: 5 }}>Are you a specialist and don't have a registration?</h4>

          <Link to="/especialistas/cadastro"><button className="botao-area">Register here</button></Link>

        </div>

        <FormAcessoUsuario />

      </div>
    )
  }

  render() {

    return (
        <div id="pagina-entrar">

          <MenuMobile />

          <MenuTopo />

          { this.redirecionando() }

          { this.exibeConteudo() }

          <Rodape />

          <AvisoPoliticaPrivacidade />

        </div>
    );
  }
}

const mapStateToProps = state => (
  {
    loading: state.auxiliaresReducer.loading,
    temErro: state.auxiliaresReducer.temErro,
    verificandoStatusLogin: state.authReducer.verificandoStatusLogin,
    usuarioLogado: state.authReducer.usuarioLogado,
    tipoUsuario: state.authReducer.tipoUsuario,
    usuarioSemCadastro: state.authReducer.usuarioSemCadastro,
    dataUltimoLogin: state.authReducer.dataUltimoLogin,
    email: state.authReducer.emailLogin,
    senha: state.authReducer.senhaLogin,
    verTutorial: state.especialistaReducer.verTutorial
  }
)

export default connect(mapStateToProps, { 
  modificaVerificandoStatusLogin, 
  modificaLoading, 
  resetaUsuarioSemCadastro, 
  autenticaConta, 
  resetaErro, 
  modificaPasso,
  modificaVerTutorial })(Entrar)
