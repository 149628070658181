import React from "react";
import { connect } from "react-redux";

const ListaEspecialidadesAdicionadas = props => {

	const botaoAbreBloco = id => {

		let idBotao = `botao-${id}`;	

		return(
			<span
				id={idBotao}
	            className="expande-bloco texto-area"
	            onClick={() => {

	            	let status = document.querySelector(`#bloco-${id}`).classList,
	            		novoSinal = "-";

	            	if(status.value.match(/aberto/))
						novoSinal = "+";		

	            	status.toggle("aberto")

	            	document.querySelector(`#${idBotao}`).innerText = novoSinal;
	            }}
	        >+</span>
		)
	}

	const exibeInfoEspecialidade = (titulo, conteudo) => {

		return(
			<div>
				
				<p className="texto-area" style={{ color: "#6e6e6e", marginBottom: "-17px" }}>{ titulo }</p>
	            <p className="texto-area">{ conteudo }</p>

			</div>
		)
	}

	const textoDuracao = duracao => {

        if(duracao === 60) return "1 hora";

        if(duracao === 90) return "1 hora e 30 minutos";

        if(duracao === 120) return "2 horas";

        if(duracao === 180) return "3 horas";

        return `${duracao} minutos`;
	}

	const textoExp = exp => {

		switch(exp) {

          case "+10":
            return "Mais de 10 anos";

          case "5-10":
            return "Entre 5 e 10 anos";

          case "3-5":
            return "Entre 3 e 5 anos";

          case "1-3":
            return "Entre 1 e 3 anos";

          default:
            return "Menos de um ano";
        }
	}

	const exibeLocais = locais => {

		let textoLocais = "";

		if(locais && locais.length > 0) {

			locais.forEach((item, i) => {

	          textoLocais += item;

	          if(i < locais.length - 1)
	            textoLocais += ", "
	        });
		}
		
		return textoLocais;
	}

	let lista = props.especialidadesAdicionadas;

	if(Object.keys(lista).length > 0) {

		let bloco = [];

		for(let id in lista) {

			let idBloco = `bloco-${id}`;

            bloco.push(
            	<div key={id} className="bloco-especialidade" id={idBloco}>

	                <div className="input-group" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>

	                  <p className="texto-area">{lista[id].nome}</p>

	                  { botaoAbreBloco(id) }

	                </div>

	                <div className="corpo-bloco-especialidade">

	                  <div className="input-group" >

	                  	{ exibeInfoEspecialidade("Duração do atendimento:", textoDuracao(lista[id].duracao)) }

	                    { exibeInfoEspecialidade("Tempo de experiência:", textoExp(lista[id].exp)) }

	                    { exibeInfoEspecialidade("Locais de atendimento:", exibeLocais(lista[id].locais)) }
	                    
	                    { exibeInfoEspecialidade("Valor atendimento individual:", `R$ ${lista[id].valorIndividual},00`) }

	                  </div>

	                </div>

              	</div>	
            )    
		}

		return bloco;
	}

	return(<p className="texto-area" style={{ marginLeft: "5px" }}>Você ainda não adicionou uma especialidade</p>)
}

const mapStateToProps = state => ({
	especialidadesAdicionadas: state.cadastroReducer.especialidadesAdicionadas
})

export default connect(mapStateToProps, {})(ListaEspecialidadesAdicionadas)
