import React, { Component } from 'react';
import { connect } from "react-redux";
import { Spinner } from "react-activity";

import Botao from "../../../global/BotaoAcao";
import RetornoFluxoCadastro from "../elementos/RetornoFluxoCadastro";

import { modificaCampo } from "../../../acoes/AuxiliaresAcoes";
import { atualizaEnderecoAtendimento, verificaEnderecoAtendimento,  } from "../../../acoes/cadastroEspecialistas/GerenciaEnderecoAtendimento";
import { verificaEndereco } from "../../../acoes/DashboardAcoes";

import erroCampo from "../../../imagens/cadastro/erro-campo.png";

class FormEnderecoAtendimento extends Component {

  constructor() {

    super();

    this.state = { cepInvalido: false }
  }

  componentDidMount() {
    document.querySelector("#campo-endereco-cep").focus();
  }

  verificaEnderecoAtendimento = e => {

    e.preventDefault();

    let { id, cep, numero, complemento } = this.props,
        { endereco, bairro, cidade, uf, pais } = this.props.enderecoVerificado;

    if(!pais)
      pais = "Brasil";       

    this.props.verificaEnderecoAtendimento({ id, cep, endereco, numero, complemento, bairro, cidade, uf, pais });
  }

  exibeErroCep = () => {

    if(this.state.cepInvalido)
      return(<img className="erro-campo" src={erroCampo} alt="" />)

    if(this.props.obtendoEndereco)
      return(
        <div style={{ position: "absolute", right: "22px", top: "32px" }}>
          <Spinner color={"#46189a"} size={11} />
        </div>
      )

    return null;
  }

  modificaCampoEndereco = e => {

    let { name, value } = e.target,
        { enderecoVerificado } = this.props;

    enderecoVerificado[name] = value;
    
    this.props.modificaCampo("modifica_endereco_verificado", enderecoVerificado);
  }

  render() {

    return (
      <div className="form-dados" id="form-endereco-atendimento">

        <RetornoFluxoCadastro 
          passo={3} 
          titulo="Informe seu endereço de atendimento" 
        />

        <form className="corpo-form" onSubmit={this.verificaEnderecoAtendimento}>

          <div className="input-group">

            <label>CEP</label>

            <input
              type="tel"
              name="cep"
              id="campo-endereco-cep"
              className="input-field"
              required
              maxLength={9}
              value={this.props.cep}
              onChange={e => {

                let cep = e.target.value,
                    cepLimpo = cep.replace("-", ""),
                    padraoCep = /\d{8}/,
                    cepValido = padraoCep.test(cepLimpo);

                this.props.modificaCampo("modifica_cep", cep);    

                if(cepValido) 
                  this.props.verificaEndereco(cepLimpo);

                this.setState({ cepInvalido: !cepValido })
              }}
              onBlur={() => this.props.atualizaEnderecoAtendimento("cep", this.props.cep, this.props.id)}
            />

            {this.exibeErroCep()}

          </div>

          <div className="input-group">

            <label>Endereço</label>

            <input
              type="text"
              name="endereco"
              className="input-field"
              id="campo-endereco"
              required
              value={this.props.enderecoVerificado.endereco}
              onChange={this.modificaCampoEndereco}
              onBlur={() => this.props.atualizaEnderecoAtendimento("endereco", this.props.endereco, this.props.id)}
            />

          </div>

          <div className="porta-inputs">

            <div className="input-group" id="campo-numero">

              <label>Número</label>

              <input
                type="number"
                name="numero"
                className="input-field"
                id="campo-endereco-numero"
                min={1}
                value={this.props.numero}
                onChange={e => this.props.modificaCampo("modifica_numero", e.target.value)}
                onBlur={() => this.props.atualizaEnderecoAtendimento("numero", this.props.numero, this.props.id)}
              />

            </div>

            <div className="input-group" id="campo-complemento">

              <label>Complemento</label>

              <input
                type="text"
                name="complemento"
                className="input-field"
                value={this.props.complemento}
                onChange={e => this.props.modificaCampo("modifica_complemento", e.target.value)}
                onBlur={() => this.props.atualizaEnderecoAtendimento("complemento", this.props.complemento, this.props.id)}
              />

            </div>

          </div>

          <div className="input-group">

            <label>Bairro</label>

            <input
              type="text"
              name="bairro"
              className="input-field"
              required
              value={this.props.enderecoVerificado.bairro}
              onChange={this.modificaCampoEndereco}
              onBlur={() => this.props.atualizaEnderecoAtendimento("bairro", this.props.bairro, this.props.id)}
            />

          </div>

          <div className="porta-inputs">

            <div className="input-group" id="campo-cidade">

              <label>Cidade</label>

              <input
                type="text"
                name="cidade"
                className="input-field"
                required
                value={this.props.enderecoVerificado.cidade}
                onChange={this.modificaCampoEndereco}
                onBlur={() => this.props.atualizaEnderecoAtendimento("cidade", this.props.cidade, this.props.id)}
              />

            </div>

            <div className="input-group" id="campo-uf">

              <label>UF</label>

              <input
                style={{ textTransform: "uppercase" }}
                type="text"
                name="uf"
                className="input-field"
                required
                value={this.props.enderecoVerificado.uf}
                onChange={this.modificaCampoEndereco}
                onBlur={() => this.props.atualizaEnderecoAtendimento("uf", this.props.uf, this.props.id)}
              />

            </div>

          </div>

          <Botao textoBotao="avançar" classeBotao="botao-area" />

        </form>

      </div>
    );
  }
}

const mapStateToProps = state => (
	{
    id: state.authReducer.idUsuario,
    cep: state.cadastroReducer.cep,
    numero: state.cadastroReducer.numero,
    complemento: state.cadastroReducer.complemento,
    obtendoEndereco: state.dashboardReducer.obtendoEndereco,
    enderecoVerificado: state.dashboardReducer.enderecoVerificado
	}
)

export default connect(mapStateToProps, {
  modificaCampo,
  atualizaEnderecoAtendimento,
  verificaEndereco,
  verificaEnderecoAtendimento })(FormEnderecoAtendimento)
