const opcoesSelect = {
  option: () => ({
    borderBottom: '1px solid #e5e5e5',
    color: '#46189a',
    margin: "0 12px",
    cursor: "pointer",
    height: 50,
    display: 'flex',
    alignItems: 'center'
  }),
  control: () => ({
    width: 'calc(100% - 4px)',
    margin: '15px 0',
    height: 46,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: 6,
    textIndent: 4,
    fontSize: 15
  }),
  input: () => ({
    color: '#46189a'
  }),
  menu: () => ({
    position: "absolute",
    background: "#fff",
    zIndex: 50,
    boxShadow: "0 2px 9px 0 rgba(0,0,0,.1)",
    borderTop: "1px solid #f5f5f5",
    width: 'calc(100% - 4px)'
  }),
  placeholder: () => ({
    color: "#b6b6b6",
    position: 'absolute',
    left: 12
  }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#46189a' })
}

export default opcoesSelect;
