import { database } from '../../config/VariaveisFirebase';
import { cpf } from 'cpf-cnpj-validator';
import moment from "moment";

import { MENSAGEM_ERRO } from "../FuncoesGerais";

// atualiza dos dados básicos conforme os especialistas vão preenchendo 
export const atualizaInfosBasicas = (tipo, valor, id) => {
	return dispatch => {

		let baseEspecialista = database.ref('especialistas/' + id);

		baseEspecialista.once('value', data => {

			let { infosBasicas } = data.val(),
					salvaDados = true;

			valor = valor.trim();

			if(tipo === "cpf" && !cpf.isValid(valor)) 
				salvaDados = false;

			if(tipo === "tel") {

				let telLimpo = valor.replace(/\s-/g, "");

				if(telLimpo.length === 10 || telLimpo.length === 11) {

					let pattern = /\d{11}/g;

				  if(telLimpo.length === 10)
				  	pattern = /\d{10}/g;

				  if(!pattern.test(telLimpo))
						salvaDados = false;

				} else {
					salvaDados = false;
				}
			}

			if(tipo === "dataNascimento") {

				let blocoData = valor.split("/");

				valor = `${blocoData[2]}-${blocoData[1]}-${blocoData[0]}`;
			}

			if(infosBasicas) {

				infosBasicas[tipo] = valor;

				if(salvaDados)
					baseEspecialista.update({ infosBasicas });
			}
		})
	}
}

// confirma se os campos foram todos preenchidos de forma correta e atualiza as informações básicas
export const verificaInfosBasicas = ({ id, email, nome, cpfInformado, dataNascimento, tel }) => {
  return dispatch => {

		if(nome.length > 1) {

			if(cpf.isValid(cpfInformado)) {

				dispatch({ type: "modifica_loading", payload: true });

				database.ref('especialistas')
				.once("value")
				.then(snapshot => {

					let especialistas = snapshot.val(),
							cpfJaCadastrado = false;

					delete especialistas[id];

					for(let idEspecialista in especialistas) {

						let cpfComparado = especialistas[idEspecialista].infosBasicas.cpf;

						if(cpfComparado !== "" && cpfComparado === cpfInformado) 
							cpfJaCadastrado = true;
					}

					if(!cpfJaCadastrado) {

						let data = new Date(),
					    	blocoNascimento = dataNascimento.split("/"),
								diferenca = data.getFullYear() - blocoNascimento[2],
					    	ehMenor = false;

						if(diferenca < 18)
							ehMenor = true;

						if(diferenca === 18) {

							let mes = data.getMonth() + 1;

						  if(mes < Number(blocoNascimento[1]))
						  	ehMenor = true;
						}

						if(!ehMenor) {

							let telLimpo = tel.replace(/[\s-()]/g, ""),
									telValido = false;

							if(telLimpo.length === 10 || telLimpo.length === 11) {

								let pattern = /\d{11}/g;

							  if(telLimpo.length === 10)
							  	pattern = /\d{10}/g;

							  telValido = pattern.test(telLimpo);
							}

							if(telValido) {

								let infosBasicas = {
									nome: nome.trim(),
									email,
									cpf: cpfInformado,
									dataNascimento: `${blocoNascimento[2]}-${blocoNascimento[1]}-${blocoNascimento[0]}`,
									tel: tel.trim()
								}

								database.ref(`especialistas/${id}/infosBasicas`)
								.update(infosBasicas, erro => {

									if(!erro) {

										dispatch({ 
											type: "modifica_infos_basicas_especialista", 
											payload: infosBasicas 
										});

										let data = moment().format();

                    database.ref(`admin/notificacoesAdministrativas/${id}/dadosBasicos`)
                    .set({ titulo: "Adição dos dados básicos", data });

										dispatch({ type: "modifica_passo", payload: 2 });

									} else {

										dispatch({ 
											type: "tem_erro", 
											payload: "Erro ao salvar os seus dados. Por favor, tente novamente" 
										});
									}

									setTimeout(() => {
										dispatch({ type: "modifica_loading", payload: false });
									}, 300);
								})		

							} else {

								dispatch({ 
									type: "tem_erro", 
									payload: "Por favor, forneça seu número de telefone com DDD" 
								});
							}

						} else {

							dispatch({ 
								type: "tem_erro", 
								payload: "Desculpe-nos, mas é necessário ter ao menos 18 anos para se cadastrar como especialista" 
							});
						}

					} else {

						dispatch({ 
							type: "tem_erro", 
							payload: "Já há um(a) especialista cadastrado(a) com esse CPF" 
						});
					}
				})
				.catch(erro => dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) }));

			} else {
				dispatch({ type: "tem_erro", payload: "CPF com número inválido" });
			}

		} else {

			dispatch({ 
				type: "tem_erro", 
				payload: "Por favor, preencha o seu nome completo" 
			});
		}
  }
}
