import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./menu-mobile.css";

export default class MenuMobile extends Component {

  constructor() {

    super();

    let blocoUrl = window.location.href.split("/");

    this.state = { pagina: blocoUrl[blocoUrl.length - 1] }
  }

  componentDidMount() {
    this.removeClasse("body", "sem-rolagem");
  }

  removeClasse = (elem, classe) => {
    document.querySelector(elem).classList.remove(classe);
  }

  geraLink = (url, texto) => {

    let { pagina } = this.state;

    if(pagina === url)
      return(<li className="ativo">{texto}</li>)

    let destino = `/${url}`;

    if((url === "rh") || (url === "saude-e-bem-estar"))
      destino = `/blog/${url}`;

    return(<li><Link to={destino}>{texto}</Link></li>)
  }

  fechaModal = () => {

    this.removeClasse("#fundo-menu-mobile", "expandido");
    this.removeClasse("#menu-mobile-links", "expandido");
    this.removeClasse("body", "sem-rolagem");
  }

  render() {

    return (
      <div id="menu-mobile">

        <div id="fundo-menu-mobile"></div>

        <div id="menu-mobile-links">

            <div
              className="fecha-menu"
              onClick={() => this.fechaModal()}
            >
              <span>X</span>
            </div>

            <ul id="mobile-links">

              {this.geraLink("", "Initial")}
              {this.geraLink("quem-somos", "Who we are")}
              {this.geraLink("servicos", "Solutions")}
              {this.geraLink("lista-de-especialidades", "Specialties")}
              {this.geraLink("especialistas", "Specialists")}
              {/* {this.geraLink("rh", "Conteúdos para RH")}
              {this.geraLink("saude-e-bem-estar", "Dicas de Saúde e Bem-Estar")} */}

              {this.geraLink("entrar", "Login")}
              {this.geraLink("especialistas/cadastro", "Register of Specialists")}

            </ul>

        </div>

      </div>
    )
  }
}
