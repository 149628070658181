import React, { Component } from 'react';
import { connect } from "react-redux";
import { cpf } from 'cpf-cnpj-validator';

import Botao from "../../../global/BotaoAcao";

import { modificaCampo } from "../../../acoes/AuxiliaresAcoes";
import { atualizaInfosBasicas, verificaInfosBasicas } from "../../../acoes/cadastroEspecialistas/GerenciaInfosBasicas";

import erroCampo from "../../../imagens/cadastro/erro-campo.png";

class FormInfosBasicas extends Component {

  constructor() {

    super();

    this.state = { cpfInvalido: false }
  }

  componentDidMount() {
    document.querySelector("#campo-nome-completo").focus();
  }

  verificaInfosBasicas = e => {

    e.preventDefault();

    let { id, email, nome, cpf, dataNascimento, tel } = this.props;

    this.props.verificaInfosBasicas({ id, nome, email, cpfInformado: cpf, dataNascimento, tel });
  }

  exibeErroCpf = () => {

    if(this.state.cpfInvalido)
      return(<img className="erro-campo" src={erroCampo} alt="" />)

    return null;
  }

  render() {

    return (
      <div className="form-dados" id="form-info-basicas" onSubmit={this.verificaInfosBasicas}>

        <div className="topo-form" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <p className="texto-area">Basic Information</p>
        </div>

        <form className="corpo-form">

          <div className="input-group">

            <label>Full Name</label>

            <input
              type="text"
              id="campo-nome-completo"
              className="input-field"
              required
              value={this.props.nome}
              onChange={e => this.props.modificaCampo("modifica_nome", e.target.value)}
              onBlur={() => this.props.atualizaInfosBasicas("nome", this.props.nome, this.props.id)}
            />

          </div>

          <div className="input-group">

            <label>CPF</label>

            <input
              type="tel"
              className="input-field"
              required
              maxLength={14}
              value={this.props.cpf}
              onChange={e => {

                let cpfInformado = e.target.value,
                    cpfInvalido = !cpf.isValid(cpfInformado);

                if(!cpfInvalido)
                  cpfInformado = cpf.format(cpfInformado)    

                this.setState({ cpfInvalido })

                this.props.modificaCampo("modifica_cpf", cpfInformado)
              }}
              onBlur={() => this.props.atualizaInfosBasicas("cpf", this.props.cpf, this.props.id)}
            />

            { this.exibeErroCpf() }

          </div>

          <div className="input-group">

            <label>Birth Date</label>

            <input
              type="tel"
              className="input-field"
              placeholder="dd/mm/yyyy"
              maxLength={10}
              required
              value={this.props.dataNascimento}
              onChange={e => {

                let data = e.target.value;

                if(data.length === 2 || data.length === 5) {

                  if(this.props.dataNascimento.length < data.length)
                    data += "/";
                  else
                    data = data.substr(0, data.length - 1);
                }

                this.props.modificaCampo("modifica_data_nascimento", data)
              }}
              onBlur={() => this.props.atualizaInfosBasicas("dataNascimento", this.props.dataNascimento, this.props.id)}
            />

          </div>

          <div className="input-group">

            <label>Phone</label>

            <input
              type="tel"
              className="input-field"
              required
              value={this.props.tel}
              onChange={e => this.props.modificaCampo("modifica_tel", e.target.value)}
              onBlur={() => this.props.atualizaInfosBasicas("tel", this.props.tel, this.props.id)}
            />

          </div>

          <Botao textoBotao="proceed" classeBotao="botao-area" />

        </form>

      </div>
    );
  }
}

const mapStateToProps = state => (
	{
    id: state.authReducer.idUsuario,
    email: state.authReducer.emailLogin,
    nome: state.cadastroReducer.nome,
    cpf: state.cadastroReducer.cpf,
    dataNascimento: state.cadastroReducer.dataNascimento,
    tel: state.cadastroReducer.tel
	}
)

export default connect(mapStateToProps, { modificaCampo, atualizaInfosBasicas, verificaInfosBasicas})(FormInfosBasicas)
