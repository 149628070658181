const initState = {
  atendimentoAtualizado: false,
  msgAtualizacao: '',
  carregandoDadosAtendimento: false,
  pessoaAtendida: '',
  local: '',
  diasSemana: [],
  horariosAtendimento: [],
  datasIndisponiveis: [],
  horariosEspecialistaAtualizados: false,
  chavesPreAgendamento: []
}

const atendimentoReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_atendimento_atualizado":
      return {
        ...state,
        atendimentoAtualizado: action.payload['atualizado'],
        msgAtualizacao: action.payload['msg']
      }

    case "modifica_horarios_especialista":
      return {
        ...state,
        pessoaAtendida: action.payload['pessoaAtendida'],
        local: action.payload['local'],
        diasSemana: action.payload['diasSemana'],
        horariosAtendimento: action.payload['horariosAtendimento'],
        datasIndisponiveis: action.payload['datasIndisponiveis'],
        horariosEspecialistaAtualizados: true
      }

    case "altera_horarios_especialista_atualizados": 
      return { ...state, horariosEspecialistaAtualizados: action.payload }  

    case "modifica_carregando_dados_atendimento":
      return { ...state, carregandoDadosAtendimento: action.payload }

    case "modifica_chaves_pre_agendamento":
      return { ...state, chavesPreAgendamento: action.payload }

    default:
      return state
  }
}

export default atendimentoReducer;
