const initState = {
  carregandoAgenda: false,
  datasAgendadas: [],
  calendarioAtualizado: false,
  agendaAtualizada: false,
  pessoaAtendidaSugestaoDatas: '',
  duracaoSugestaoDatas: '',
  localSugestaoDatas: '',
  chaveAgendamentoSugestaoDatas: '',
  carregandoHistorico: false,
  historicoEspecialista: [],
  dadosGraficosEspecialista: {
    especialidades: {},
    locais: {}
  },
  carregandoAnotacoes: false,
  listaAnotacoesClientes: [],
  editarAnotacoes: {},
  listaPessoasAtendidas: {}
}

const atendimentosEspecialistasReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_agenda":
      return { ...state, carregandoAgenda: action.payload }

    case "modifica_datas_agendadas":
      return { ...state, datasAgendadas: action.payload, carregandoAgenda: false }

    case "modifica_calendario_atualizado":
      return { ...state, calendarioAtualizado: action.payload }  

    case "modifica_agenda_atualizada":
      return { ...state, agendaAtualizada: action.payload }  

    case "modifica_dados_sugestao_datas":
      return {
        ...state,
        pessoaAtendidaSugestaoDatas: action.payload["nome"],
        duracaoSugestaoDatas: action.payload["duracao"],
        localSugestaoDatas: action.payload["local"],
        chaveAgendamentoSugestaoDatas: action.payload["chaveAgendamento"]
      }

    case "modifica_carregando_historico":
      return { ...state, carregandoHistorico: action.payload }

    case "modifica_historico_especialista":
      return { ...state, historicoEspecialista: action.payload, carregandoHistorico: false }

    case "modifica_dados_graficos_especialista":
      return {
        ...state,
        dadosGraficosEspecialista: action.payload,
        carregandoHistorico: false
      }

    case "modifica_carregando_anotacoes":
      return { ...state, carregandoAnotacoes: action.payload }

    case "modifica_lista_anotacoes_clientes":
      return { 
        ...state, 
        listaAnotacoesClientes: action.payload, 
        carregandoAnotacoes: false
      }

    case "modifica_lista_pessoas_atendidas":
      return { ...state, listaPessoasAtendidas: action.payload, carregandoAnotacoes: false }

    case "editar_dados_anotacao":
      return { ...state, editarAnotacoes: action.payload }  

    default:
      return state
  }
}

export default atendimentosEspecialistasReducer;
