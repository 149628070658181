import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import moment from "moment";

import { modificaCientePoliticaPrivacidade } from "../acoes/AuxiliaresAcoes";

class AvisoPoliticaPrivacidade extends Component {

  cientePolitica = () => {

    let fator = Math.floor(Math.random(0, 1) * 1000),
        data = moment().format(),
        chave = `${moment(data).format("YYYYMMDDHHmmss")}${fator}`;

    this.props.modificaCientePoliticaPrivacidade(chave, data);    
  }

  render() {

    if(!this.props.ciente)
      return (
        <div id="bloco-aviso">

          <div id="porta-aviso">

            <p className="texto-area" style={{ flex: 1, paddingRight: 15 }}>We use cookies to improve your experience. By browsing our site, you agree to our <Link to="/politica-de-privacidade" target="_blank">Privacy Policy</Link>.</p>

            <button className="botao-acao" onClick={this.cientePolitica} style={{ width: 120, marginTop: 0 }}>Got it</button>

          </div>

        </div>
      )

    return null;
  }
}

const mapStateToProps = state => ({
  ciente: state.auxiliaresReducer.cientePoliticaPrivacidade
})

export default connect(mapStateToProps, { modificaCientePoliticaPrivacidade })(AvisoPoliticaPrivacidade)
