import React, { useState, useEffect } from "react";
import Cropper from "react-cropper";
import { useSelector, useDispatch } from "react-redux";
import "cropperjs/dist/cropper.css";
import "../../../css/ajusta-fotos.css";

import { modificaModalAjustaFotosAberto } from "../../../acoes/DashboardAcoes";
import { uploadFotoUsuario } from "../../../acoes/AtualizaDadosUsuario";

const AjustaFotos = () => {

	const alturaTela = window.innerHeight - 140;

  	const [cropper, setCropper] = useState();
  	const [alturaFoto, setAlturaFoto] = useState(alturaTela);

  	// altura da barra de endereço do navegador atrapalhar a exibição do rodapé, por isso é necessário este ajuste
  	useEffect(() => {
  		setAlturaFoto(alturaTela)
  	}, [window.innerHeight])

	const dispatch = useDispatch();

	const { modalAjustaFotosAberto, fotoACortar, tipoUsuarioFotoACortar } = useSelector(state => state.dashboardReducer);

	const { idUsuario } = useSelector(state => state.authReducer);

	const fechaAjustaFoto = () => dispatch(modificaModalAjustaFotosAberto(false))

	const cortaFoto = () => {

		if(cropper) 
     		dispatch(uploadFotoUsuario({
     			id: idUsuario,
     			foto: cropper.getCroppedCanvas().toDataURL(),
     			tipoUsuario: tipoUsuarioFotoACortar
     		}));
	}

	if(modalAjustaFotosAberto)
		return(
			<div id="modal-cropper">

				<div id="fundo-modal-cropper" onClick={() => fechaAjustaFoto()}></div>

				<div id="topo-modal-cropper">

					<div id="fecha-modal-cropper" onClick={() => fechaAjustaFoto()}>
						<span>X</span>
					</div>

				</div>

				<div id="porta-foto-cropper" style={{ height: alturaFoto }}>
					
					<Cropper
			          style={{ height: '100%', width: "100%" }}
			          zoomTo={0.5}
			          aspectRatio={1}
			          src={fotoACortar}
			          viewMode={1}
			          minCropBoxHeight={10}
			          minCropBoxWidth={10}
			          background={false}
			          responsive={true}
			          autoCropArea={1}
			          checkOrientation={false} 
			          onInitialized={instance => setCropper(instance)}
			          guides={true}
			        />

				</div>

				<div id="rodape-modal-cropper">
					<button className="botao-acao" onClick={() => cortaFoto()}>Cortar Foto</button> 
				</div>

			</div>
		)

	return null;
}

export default AjustaFotos;