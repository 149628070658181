import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";
import '../../../css/paginas-erros.css';
import { connect } from "react-redux";
import moment from 'moment';

import lotus from "../../../imagens/breathing-lotus.json";

import MenuMobile from '../../../global/nav/menuMobile/MenuMobile';
import MenuTopo from '../../../global/nav/menuTopo/MenuTopo';

class NaoEncontrada extends Component {

	exibeLinks = () => {

		if(this.props.usuarioLogado && moment().diff(this.props.dataUltimoLogin, 'minute') <= 30)
			return(
				<div className="porta-links">

					<p className="texto-area">Agora é só utilizar o link para retornar a página em que você estava</p>

					<Link to="#" onClick={e => {

						e.preventDefault();

						window.history.back();

					}}>Retornar à página anterior</Link>

				</div>	
			)

		return(
			<div className="porta-links">

				<h4 className="titulo-area">Outras páginas que podem te interessar:</h4>

				<div id="porta-menus-erro">

					<ul className="menu-erro">

						<li><Link to="/">Inicial</Link></li>
						<li><Link to="/quem-somos">Quem Somos</Link></li>
						<li><Link to="/servicos">Serviços</Link></li>
						<li><Link to="/lista-de-especialidades">Especialidades</Link></li>

					</ul>

					<ul className="menu-erro">

						<li><Link to="/especialistas">Especialistas</Link></li>
						<li><Link to="/entrar">Entrar</Link></li>
						<li><Link to="/blog/rh">Conteúdo para RH</Link></li>
		          		<li><Link to="/blog/saude-e-bem-estar">Saúde e Bem Estar</Link></li>

					</ul>

				</div>

			</div>
		)
	}

	render() {

		return(
			<div id="pagina-erro">
				
				<MenuMobile />

          		<MenuTopo />

          		<div id="bloco-nao-encontrada">

					<div id="conteudo-erro">
						
						<h2 className="subtitulo-area">Página não encontrada!</h2>

						<p className="texto-area" id="apresenta-animacao">Aproveite esse instante para fechar os olhos e respirar fundo</p>

						<Lottie 
							animationData={lotus}
							loop={true}
							style={{ width: '100%', maxWidth: 400 }}
						/>

						{ this.exibeLinks() }

					</div>

				</div>

			</div>
		)
	}
}

const mapStateToProps = state => ({
	usuarioLogado: state.authReducer.usuarioLogado,
	dataUltimoLogin: state.authReducer.dataUltimoLogin,
})

export default connect(mapStateToProps, {})(NaoEncontrada)