import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import './App.css';
import rotasConfig from './rotasConfig';
import { routesConfig } from './routes';

// importa os estilos vinculados às dependências e pacotes
import 'react-activity/dist/Spinner.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-awesome-lightbox/build/style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'react-toastify/dist/ReactToastify.css';

import {
  verificaRotasPostagens,
  modificaRotasPostagensAtualizadas,
} from './acoes/LeituraDadosBlog';

import Artigo from './paginas/blog/artigo';
import NaoEncontrada from './paginas/erros/naoEncontrada';
import ErroApp from './paginas/erros/erroApp';

class App extends Component {
  constructor() {
    super();

    this.state = { rotasPostagens: [] };
  }

  componentDidMount() {
    this.props.verificaRotasPostagens();

    let { rotasPostagens } = this.props;

    if (rotasPostagens) this.setState({ rotasPostagens });
  }

  componentDidUpdate() {
    if (this.props.rotasAtualizadas) {
      this.props.modificaRotasPostagensAtualizadas(false);

      this.setState({ rotasPostagens: this.props.rotasPostagens });
    }
  }

  postagensBlog = () => {
    let rotas = this.state.rotasPostagens;

    if (rotas.length > 0) {
      let artigos = rotas.map((item) => {
        let uri = `/blog/${item.tipoArtigo}/${item.caminho}`;

        return <Route key={uri} path={uri} component={Artigo} exact={true} />;
      });

      return artigos;
    }

    return null;
  };

  render() {
    return (
      <Router>
        <ErroApp>
          <Switch>
            {rotasConfig.map((value, key) => {
              return (
                <Route
                  key={key}
                  path={value.path}
                  component={value.component}
                  exact={value.exact}
                />
              );
            })}

            {this.postagensBlog()}

            <Route path="*" exact={false} component={NaoEncontrada} />
          </Switch>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </ErroApp>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  rotasAtualizadas: state.blogReducer.rotasPostagensAtualizadas,
  rotasPostagens: state.blogReducer.rotasPostagens,
});

export default connect(mapStateToProps, {
  verificaRotasPostagens,
  modificaRotasPostagensAtualizadas,
})(App);
