import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyBocqMcbi7vk09OV5NcjPgUDX4jP0OE1TA",
  authDomain: "omm-be.firebaseapp.com",
  databaseURL: "https://omm-be-default-rtdb.firebaseio.com",
  projectId: "omm-be",
  storageBucket: "omm-be.appspot.com",
  messagingSenderId: "1097541566136",
  appId: "1:1097541566136:web:70c81456dafcc5c9b4fe4d",
  measurementId: "G-K43Z1PN04M"
};

if(!firebase.apps.length)
  firebase.initializeApp(config);

const auth = firebase.auth(),
      database = firebase.database(),
      storage = firebase.storage(),  
      firestore = firebase.firestore(),
      functions = firebase.functions(),
      analytics = firebase.analytics();  

export { auth, database, storage, firestore, functions, analytics };