import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';

import { obtemDadosEspecialidades } from "../../acoes/especialidades/LeituraDadosEspecialidades";
import { selecionaEspecialidade, atualizaListaEspecialidades } from "../../acoes/especialidades/GerenciaDadosEspecialidades";

const SelectEspecialidades = props => {

	const [listaEspecialidades, setListaEspecialidades] = useState([]);

  const dispatch = useDispatch();      

	useEffect(() => {
		dispatch(obtemDadosEspecialidades())
	}, [])	  	

  const { dadosEspecialidades, especialidadeSelecionada, selectDesabilitado, listaEspecialidadesAtualizada } = useSelector(state => state.especialidadesReducer)

	useEffect(() => {

		dispatch(atualizaListaEspecialidades(false));

		let opcoesBemEstar = [],
        opcoesTelemedicina = [];

    dadosEspecialidades.forEach(item => {

      let dados = { value: item.id, label: item.nome, group: item.tipo };

      if(item.tipo === "Bem estar") 
        opcoesBemEstar.push(dados);
      else
        opcoesTelemedicina.push(dados);
    })

    setListaEspecialidades([
      { label: "Bem estar", options: opcoesBemEstar },
      { label: "Telemedicina", options: opcoesTelemedicina }
    ])

	}, [listaEspecialidadesAtualizada])

	return(
		<div className="input-group">

          <label style={{ top: -6 }}>Especialidade</label>

          <Select
            classNamePrefix="select"
            styles={props.opcoesSelect}
            placeholder={props.placeHolder}
            value={especialidadeSelecionada}
            isDisabled={selectDesabilitado}
            isClearable={true}
            isSearchable={true}
            noOptionsMessage={()=> {
              return(<p className="texto-cartao">Sem opções</p>)
            }}
            options={listaEspecialidades}
            onChange={e => dispatch(selecionaEspecialidade(e))}
          />

          <input
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, height: 0, position: "absolute", zIndex: -1 }}
            defaultValue={especialidadeSelecionada}
            required={props.required}
          />

        </div>
	)
}

export default SelectEspecialidades