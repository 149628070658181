import { database } from '../../config/VariaveisFirebase';
import { cnpj } from 'cpf-cnpj-validator';

// atualiza os dados profissionais dos especialistas
export const atualizaInfosProfissionais = (tipo, valor, id) => {
	return dispatch => {

		let baseEspecialista = database.ref('especialistas/' + id);

		baseEspecialista.once('value', data => {

			let { infosProfissionais } = data.val(),
				salvaDados = true;

			valor = valor.trim();

			if(tipo === "cnpj") {

				if(cnpj.isValid(valor)) {

					valor = cnpj.format(valor);
					dispatch({ type: "modifica_cnpj", payload: valor })

				} else {
					salvaDados = false;
				}
			}

			if(infosProfissionais) {

				infosProfissionais[tipo] = valor;

				if(salvaDados)
					baseEspecialista.update({ infosProfissionais });
			}
		})
	}
}

// confirma se os dados obrigatórios dos profissionais foram preenchidos
export const verificaInfosProfissionais = ({ id, nome, email, cnpjInformado, descricao, urlFotoPerfil, urlFotoMini, videoApresentacao, conselhoEntidade, conselhoNumero }) => {
	return dispatch => {

		if(descricao.length >= 50) {

			dispatch({ type: "modifica_loading", payload: true });

			if(cnpjInformado !== "" && cnpj.isValid(cnpjInformado)) 
				cnpjInformado = cnpj.format(cnpjInformado);

			let infosProfissionais = {
				cnpj: cnpjInformado,
				descricao,
				urlFotoPerfil,
				urlFotoMini,
				videoApresentacao: "",
				conselhoEntidade,
				conselhoNumero,
				dadosAprovados: ""
			}

			database.ref(`especialistas/${id}/infosProfissionais`)
			.update(infosProfissionais, erro => {

				if(!erro) {

					dispatch({ 
						type: "modifica_infos_profissionais_especialista", 
						payload: infosProfissionais 
					});

					// envia um e-mail parabenizando o(a) especialista pela finalizaçAo do cadastro
					fetch(`https://us-central1-omm-be.cloudfunctions.net/parabenizaEspecialista`,
				      {
				        method: 'POST',
				        headers: {
				          'Accept': '*/*',
				          'Content-Type': 'application/json'
				        },
				        body: JSON.stringify({
				          "nome": nome,
				          "email": email
				        })
				    })

					dispatch({ type: "modifica_passo", payload: 6 });

				} else {
					dispatch({ type: "tem_erro", payload: "Erro ao salvar os seus dados. Por favor, tente novamente" });
				}
			})

		} else {
			dispatch({ type: "tem_erro", payload: "Por favor, descreva um pouco mais sobre você e suas experiências" });
		}
  }
}