export const selecionaEspecialidade = especialidade => {
	return {
		type: "seleciona_especialidade",
		payload: especialidade
	}
}

export const atualizaStatusSelectEspecialidades = status => {
	return {
		type: "atualiza_status_select_especialidades",
		payload: status
	}
}

export const selecionaLocaisEspecialidade = locais => {
	return {
		type: "seleciona_locais_especialidade",
		payload: locais
	}
}

export const atualizaListaEspecialidades = valor => {
	return {
		type: "atualiza_lista_especialidades",
		payload: valor
	}
}