const initState = {
  carregandoDadosAtendimentosEmpresas: false,
  atendimentosAgendadosEmpresas: [],
  historicoAtendimentosEmpresas: [],
  dadosHistoricoEmpresa: [],
  dadosHistoricoEmpresaCarregado: false,
  dadosGraficosEmpresas: {
    atendimentosTitularesBeneficiarios: {
      titulares: 0,
      beneficiarios: 0
    },
    unidades: { 'Matriz': { volume: 0 } },
    especialidades: {},
    faixasEtarias: {},
    cidades: {},
    intencaoTratamento: {},
    volumesPeriodo: {},
    creditosPeriodo: {},
    total: 0
  },
  dadosRankingsEmpresas: {
    colaboradores: {},
    especialidades: {},
    faixasEtarias: {
      "Mais de 60 anos": { volume: 0, creditos: 0 },
      "De 50 a 59 anos": { volume: 0, creditos: 0 },
      "De 40 a 49 anos": { volume: 0, creditos: 0 },
      "De 30 a 39 anos": { volume: 0, creditos: 0 },
      "Abaixo de 30 anos": { volume: 0, creditos: 0 },
    },
    cidades: {},
    unidades: {}
  },
  dataInicialRanking: '',
  dataFinalRanking: '',
  unidadeRanking: ''
}

const atendimentosEmpresasReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_dados_atendimentos_empresas":
      return { ...state, carregandoDadosAtendimentosEmpresas: action.payload }

    case "modifica_atendimentos_agendados_empresas":
      return {
        ...state,
        atendimentosAgendadosEmpresas: action.payload,
        carregandoDadosAtendimentosEmpresas: false
      }

    case "modifica_historico_atendimentos_empresas":
      return {
        ...state,
        historicoAtendimentosEmpresas: action.payload,
        carregandoDadosAtendimentosEmpresas: false
      }

    case "modifica_dados_historico_empresa_carregado":
      return { ...state, dadosHistoricoEmpresaCarregado: action.payload }    

    case "atualiza_dados_historico_empresa":
      return { 
      ...state, 
      dadosHistoricoEmpresa: action.payload, 
      dadosHistoricoEmpresaCarregado: true 
    }  

    case "modifica_dados_graficos_empresas":
      return {
        ...state,
        dadosGraficosEmpresas: action.payload,
        carregandoDadosAtendimentosEmpresas: false
      }

    case "modifica_dados_rankings_empresas":
      return {
        ...state,
        dadosRankingsEmpresas: action.payload,
        carregandoDadosAtendimentosEmpresas: false
      }  

    case "altera_periodo_rankings_empresa":
      return {
        ...state,
        dataInicialRanking: action.payload["dataInicial"],
        dataFinalRanking: action.payload["dataFinal"],
        unidadeRanking: action.payload["unidade"]
      }  

    default:
      return state
  }
}

export default atendimentosEmpresasReducer;
