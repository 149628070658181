import { database } from '../../config/VariaveisFirebase';
import Geocode from "react-geocode";

// atualiza os dados do endereço conforme os especialistas vão preenchendo
export const atualizaEnderecoAtendimento = (tipo, valor, id) => {
	return dispatch => {

		let baseEspecialista = database.ref('especialistas/' + id);

		baseEspecialista.once('value', data => {

			let { enderecoAtendimento } = data.val(),
					salvaDados = true;

			if(valor) {

				valor =  valor.trim();

				if(tipo === "cep") {

					let cepLimpo = valor.replace("-", "");

					if(cepLimpo.length !== 8 )
						salvaDados = false;
				}

				if(enderecoAtendimento) {

					enderecoAtendimento[tipo] = valor;

					if(salvaDados)
						baseEspecialista.update({ enderecoAtendimento });
				}
			}		
		})
	}
}

// confirma se todos os dados obrigatórios relacionados ao endereço foram fornecidos
export const verificaEnderecoAtendimento = ({ id, cep, endereco, numero, complemento, bairro, cidade, uf, pais }) => {
  return dispatch => {

    dispatch({ type: "modifica_loading", payload: true });

		let cepLimpo = cep.replace("-", ""),
			pattern = /\d{8}/g;

		if(pattern.test(cepLimpo)) {

			let dadosEndereco = {
				cep: cep.trim(),
				endereco: endereco.trim(),
				numero: numero.trim(),
				complemento: complemento.trim(),
				bairro: bairro.trim(),
				cidade: cidade.trim(),
				uf,
				pais
			}

			database.ref(`especialistas/${id}/enderecoAtendimento`)
			.update(dadosEndereco, erro => {

				if(erro) {

					dispatch({ type: "tem_erro", payload: "Erro ao salvar seu endereço de atendimento. Por favor, tente novamente" });

				} else {

					dispatch({ 
						type: "modifica_endereco_atendimento_especialista", 
						payload: dadosEndereco 
					});

					dispatch({ type: "modifica_passo", payload: 5 });

					let enderecoCompleto = `${endereco}, ${numero}, ${bairro}, ${cidade}, ${uf}`;

					Geocode.setApiKey("AIzaSyBX9gHcideN7y3jH4gylb0n_5iaNl1eawY");

					Geocode.fromAddress(enderecoCompleto).then(
					  response => {
					    const { lat, lng } = response.results[0].geometry.location;

					    let coordenadas = { latitude: lat, longitude: lng };

					    database.ref(`especialistas/${id}/coordenadasAtendimento`)
					    .update(coordenadas);

					    dispatch({ 
								type: "modifica_coordenadas_atendimento_especialista", 
								payload: coordenadas
							});
					  }
					);
				}

				setTimeout(() => {
		      		dispatch({ type: "modifica_loading", payload: false });
		    	}, 300);
			})

		} else {
			
			dispatch({ 
				type: "tem_erro", 
				payload: "Por favor, informe o seu CEP" 
			});
		}
  }
}