import { auth, database } from '../../config/VariaveisFirebase';
import moment from "moment";

import { MENSAGEM_ERRO } from "../FuncoesGerais";

// registar uma conta para o(a) especialista
export const criarConta = ({ email, senha, contagemErros, ip, idRecrutador }) => {
	return dispatch => {

		// caso ocorra algum erro no processo de cadastro para ter uma noção do erro que ocorreu e poder corrigí-lo
		/*function salvaErro(email, erro, ip) {

			fetch("https://us-central1-omm-be.cloudfunctions.net/salvaErros",
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"email": email,
						"ip": ip,
						"msgErro": erro,
						"data": moment().format()
					})
			})
			.then(() => false)
		}*/

    if(senha.length >= 6 && senha.length <= 10) {

    	dispatch({ type: "modifica_loading", payload: true });

			email = email.trim();

      auth.fetchSignInMethodsForEmail(email)
      .then(providers => {

        if(providers.length > 0)	{

					dispatch({ type: "tem_erro", payload: "Usuário já cadastrado" });

        } else {

          auth.createUserWithEmailAndPassword(email, senha)
    			.then(value => {

						auth.signInWithEmailAndPassword(email, senha)
			      .then(value => {

			        let valores = Object.values(value),
									idUsuario = valores[0].uid;

							database.ref('avaliacoesAtendimentos/' + idUsuario)
							.child("inicial").set({ nota: 5 })

							let infosBasicas = {
										nome: "",
										email,
										cpf: "",
										dataNascimento: "",
										tel: "",
										dadosAprovados: ""
									},
									dadosBancarios = {
										cpfCnpj: "",
										banco: "",
										tipoConta: "",
										numConta: "",
										agencia: "",
										nomeResponsavel: ""
									},
									documentos = {
										urlFotoIdentidadeFrente: "",
								    urlFotoIdentidadeVerso: "",
								    urlFotoSegurandoIdentidade: "",
								    urlFotoCarteiraConselho: "",
								    urlComprovanteResidencia: "",
								    urlRegistroMEICNPJ: "",
								    urlComprovanteBancario: "",
								    urlCertidaoNegativaCriminalEstadual: "",
								    urlCertidaoNegativaCriminalFederal: "",
										urlSeguroResponsabilidadeCivil: ""
									}

							const dataCadastro = moment().format("YYYY-MM-DD");

							database.ref('especialistas/' + idUsuario)
							.set({
								aprovado: false,
								estaSuspenso: false,
								mediaAvaliacoes: 5,
								favoritadoPor: 0,
								idDispositivo: "",
								infosBasicas,
								coordenadasAtendimento: {
									latitude: 0,
									longitude: 0
								},
								dataCadastro,
								enderecoAtendimento: {
									cep: "",
									endereco: "",
									numero: "",
									complemento: "",
									bairro: "",
									cidade: "",
									uf: ""
								},
								infosProfissionais: {
									descricao: "",
									videoApresentacao: "",
									cnpj: "",
									urlFotoPerfil: "",
									urlFotoMini: "",
									conselhoEntidade: "",
									conselhoNumero: "",
									dadosAprovados: ""
								},
								opcoesAtendimento: {
									diasSemana: "",
									horarios: "",
									idiomas: ["Português"]
								},
								especialidades: "",
								dadosBancarios,
								documentos,
								notificacoes: {
									email: true,
									mobile: true
								},
								treinamento: {
									completo: false,
									etapa: ""
								}
							})
							.then(value => {

								// reseta qualquer valor que estiver previamente salvo no container devido a uma tentativa de cadastro não finalizado
								dispatch({ type: "modifica_nome", payload: '' })
								dispatch({ type: "modifica_cpf", payload: '' })
								dispatch({ type: "modifica_data_nascimento", payload: '' })
								dispatch({ type: "modifica_tel", payload: '' })

								dispatch({ 
									type: "modifica_especialidades_adicionadas", 
									payload: {}
								});

								dispatch({ type: "modifica_dias_semana", payload: [] })
								dispatch({ type: "modifica_horarios", payload: [] })
								dispatch({ type: "modifica_idiomas", payload: ["Português"] })

								dispatch({ type: "modifica_cep", payload: '' })
								dispatch({ type: "modifica_numero", payload: '' })
								dispatch({ type: "modifica_complemento", payload: '' })
								dispatch({ 
									type: "modifica_endereco_verificado", 
									payload: {
										endereco: '',
										bairro: '',
										cidade: '',
										uf: '',
										pais: ""
									}
								})

								dispatch({ type: "modifica_descricao", payload: '' })
								dispatch({ type: "modifica_url_foto_perfil", payload: '' })
								dispatch({ type: "modifica_url_foto_mini", payload: '' })
								dispatch({ type: "modifica_conselho_entidade", payload: '' })
								dispatch({ type: "modifica_conselho_numero", payload: '' })
								dispatch({ type: "modifica_cnpj", payload: '' })

								dispatch({ 
									type: "modifica_dados_bancarios_especialista", 
									payload: dadosBancarios 
								});

								dispatch({ 
									type: "modifica_documentos_especialista", 
									payload: documentos 
								});

								dispatch({ 
									type: "modifica_notificacoes_especialista", 
									payload: {
										email: true,
										mobile: true
									} 
								});

								dispatch({ 
									type: "modifica_media_avaliacoes_especialista", 
									payload: 5
								});

								dispatch({ 
									type: "modifica_qtd_vezes_favoritado", 
									payload: 0
								});

								dispatch({ type: "modifica_id_usuario", payload: idUsuario });
								dispatch({ type: "modifica_passo", payload: 1 });
				        dispatch({ type: "modifica_loading", payload: false });
								dispatch({ type: "modifica_usuario_cadastrado", payload: true });
								dispatch({ type: "modifica_usuario_sem_conta", payload: false });

								dispatch({
									type: "modifica_usuario_logado",
									payload: {
										estaLogado: true,
										tipoUsuario: "especialista",
										dataUltimoLogin: moment().format()
									}
								});

								if(idRecrutador && typeof idRecrutador === "number") {

									database.ref("admin/recrutamento/recrutadores").orderByChild("codigo").equalTo(idRecrutador)
									.once("value", snapshot => {

										const dados = snapshot.val();

										if(dados) {

											const id = Object.keys(dados);

											database.ref(`admin/recrutamento/historico/${id[0]}/${idUsuario}`)
											.set({ dataCadastro });
											
											let { totalIndicacoes } = dados[id[0]];

											totalIndicacoes++;

											database.ref(`admin/recrutamento/recrutadores/${id[0]}`).update({ totalIndicacoes });
										}
									})
								}
							})
			      })
			      .catch(erro => {

							dispatch({ type: "modifica_contagem_erros", paylod: 2 });

							dispatch({ 
								type: "modifica_registro_msg_erro", 
								payload: MENSAGEM_ERRO(erro.message) 
							});

							//salvaErro(email, erro.message, ip);
			      });
    			})
        }
      })
      .catch(erro => {

				contagemErros++;

				dispatch({ type: "modifica_contagem_erros", payload: contagemErros });

				if(contagemErros < 2) {

					//salvaErro(email, erro.message, ip);
					dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) });

				} else {
					dispatch({ type: "modifica_registro_msg_erro", payload: erro.message });
				}
      });

    } else {
      dispatch({ type: "tem_erro", payload: "Please check the number of characters in your password" });
    }
	}
}

// altera a exibição do formulário correspondente àquele passo
export const modificaPasso = valor => {
	return {
		type: "modifica_passo",
		payload: valor
	}
}

export const modificaIdRecrutadorQueIndicou = id => {
	return {
		type: "modifica_id_recrutador_que_indicou",
		payload: id
	}
}