const initState = {
  carregandoTotais: false,
  totaisAdmin: {
    usuariosAtivos: 0,
    empresas: 0,
    especialistasAprovados: 0,
    totalAgendados: 0,
    atendimentosRealizados: 0,
    totalGanhos: 0,
    cidadesAtendidas: 0,
    engajamento: 0
  },
  carregandoVolumesEmpresas: false,
  volumesEmpresas: [],
  carregandoVolumesEspecialistas: false,
  volumeAtendimentosEspecialistas: []
}

const visaoGeralAdminReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_totais_admin":
      return { 
        ...state, 
        totaisAdmin: {
          usuariosAtivos: 0,
          empresas: 0,
          especialistas: 0,
          totalAgendados: 0,
          atendimentosRealizados: 0,
          totalGanhos: 0,
          cidadesAtendidas: 0,
          engajamento: 0
        }, 
        carregandoTotais: action.payload
      }

    case "atuliza_totais_admin":
      return { ...state, totaisAdmin: action.payload, carregandoTotais: false }

    case "modifica_carregando_volumes_empresas":
      return { ...state, carregandoVolumesEmpresas: action.payload }

    case "atualiza_volumes_empresas":
      return { ...state, volumesEmpresas: action.payload, carregandoVolumesEmpresas: false }  

    case "modifica_carregando_volumes_especialistas":
      return { ...state, carregandoVolumesEspecialistas: action.payload }

    case "atualiza_volumes_especialistas":
      return { 
        ...state, 
        volumeAtendimentosEspecialistas: action.payload, 
        carregandoVolumesEspecialistas: false 
      }    

    default:
      return state
  }
}

export default visaoGeralAdminReducer;
