import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const ExibeFluxoCadastro = () => {

	const [passoAtual, setPassoAtual] = useState(0);

	const adicionaClasse = elem => document.querySelector(elem).classList.add("ativo")

	const removeClasse = elem => document.querySelector(elem).classList.remove("ativo")

  let { passo } = useSelector(state => state.cadastroReducer);

	const mudaPasso = passo < 6 && (passoAtual !== passo)

	useEffect(() => {

		if(passo > 1 && passo > passoAtual) {

      window.scroll({ top: 0, behavior: 'smooth' });

      adicionaClasse(`#barra${passo}`);

      setTimeout(() => {
        adicionaClasse(`#passo${passo}`);
      }, 50);
    }

    if(passoAtual > passo) {

        removeClasse(`#passo${passoAtual}`);

        setTimeout(() => {
          removeClasse(`#barra${passoAtual}`);
        }, 50);
    }

		setPassoAtual(passo);

	}, [mudaPasso])

	const exibeBarra = num => {

		let id = `barra${num}`;

		return <div className="barra-passo" id={id}></div>
	}

	const exibePasso = num => {

		let id = `passo${num}`;

		return <div className="passo" id={id}></div>
	}

	if(passo > 0 && passo < 6) {

      return(
        <div id="fluxo-cadastro">

          <div className="passo ativo"></div>

          { exibeBarra(2) }

          { exibePasso(2) }

          { exibeBarra(3) }

          { exibePasso(3) }

          { exibeBarra(4) }

          { exibePasso(4) }

          { exibeBarra(5) }

          { exibePasso(5) }

        </div>
      )
    }

    return null;
}

export default ExibeFluxoCadastro