import React, { Component } from "react";
import moment from "moment"; 

import calendario from "../../../imagens/calendario-artigo.png";
import relogio from "../../../imagens/relogio-artigo.png";

export default class DetalhesArtigo extends Component { 

	render() {

		let { imgPerfil, nome, data, tempoLeitura } = this.props.dados;

		return(
			<div className="bloco-info-artigo">
                
        <div className="porta-info-artigo">
          
          <div className="porta-foto-autor">
            <img src={imgPerfil} alt="" />
          </div>

          <p id="nome-autor">{ nome }</p> 

        </div>
         
        <div className="porta-detalhes-artigo">
          
          <div className="porta-info-artigo" style={{ marginRight: 10 }}>
            
            <img src={calendario} alt="" />
            <span>{ moment(data).format("MMM DD, YYYY") }</span>

          </div>
          
          <div className="porta-info-artigo">
            
            <img src={relogio} alt="" />
            <span>{ tempoLeitura } min</span>

          </div>

        </div> 

      </div>
		)
	}
}