import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import '../../css/cadastro-especialistas.css';

import MenuMobile from '../../global/nav/menuMobile/MenuMobile';
import MenuTopo from '../../global/nav/menuTopo/MenuTopo';
import AvisoPoliticaPrivacidade from "../../global/AvisoPoliticaPrivacidade";
import Rodape from "../../global/rodape/Rodape";

import FormCadastroUsuario from "../../componentes/cadastroEspecialistas/forms/FormCadastroUsuario";
import FormInfosBasicas from "../../componentes/cadastroEspecialistas/forms/FormInfosBasicas";
import FormEspecialidades from "../../componentes/cadastroEspecialistas/forms/FormEspecialidades";
import FormOpcoesAtendimentos from "../../componentes/cadastroEspecialistas/forms/FormOpcoesAtendimentos";
import FormEnderecoAtendimento from "../../componentes/cadastroEspecialistas/forms/FormEnderecoAtendimento";
import FormInfosProfissionais from "../../componentes/cadastroEspecialistas/forms/FormInfosProfissionais";
import ExibeFluxoCadastro from "../../componentes/cadastroEspecialistas/elementos/ExibeFluxoCadastro";
import AjustaFotos from "../../global/dashboards/modais/AjustaFotos";

import { autenticaConta } from "../../acoes/Auth";
import { modificaVerTutorial } from "../../acoes/DashboardAcoes";

class CadastroEspecialistas extends Component {

  componentDidMount() {
    window.scroll({ top: 0, behavior: 'auto' });
  }

  exibeForm = () => {

    switch (this.props.passo) {

      case 1:
        return(<FormInfosBasicas />)

      case 2:
        return(<FormEspecialidades />)

      case 3:
        return(<FormOpcoesAtendimentos />)

      case 4:
        return(<FormEnderecoAtendimento />)

      case 5:
        return(<FormInfosProfissionais />)

      case 6:
        return(
          <div className="form-dados" style={{ margin: "40px auto", width: "95%", maxWidth: 350 }}>

            <h2 className="subtitulo-area" style={{ color: "#8100B7" }}>Congratulations!</h2>

            <p className="texto-area" style={{ fontSize: 20, marginTop: -30 }}>First stage of registration completed!</p>

            <p className="texto-area">Now just access your platform and upload your documents</p>

            <Link 
              to="/especialistas/visao-geral"
              onClick={() => this.props.modificaVerTutorial(true, 1)}
            >
              <button className="botao-area">Access your platform here</button>
            </Link>

          </div>
        )

      default:
        return(<FormCadastroUsuario />)
    }
  }

  render() {

    return (
      <div>

        <AjustaFotos />

        <div id="pagina-cadastro-especialista">

            <MenuMobile />

            <MenuTopo />

            <div id="cadastro-especialistas">

              <h2 className="subtitulo-area" style={{ textAlign: "center" }}>Specialists Registration</h2>

              <div id="cadastro-dados-especialista">

                <ExibeFluxoCadastro />

                <div id="porta-forms">
                  {this.exibeForm()}
                </div>

              </div>

            </div>

            <Rodape />

            <AvisoPoliticaPrivacidade />

        </div>

      </div>  
    );
  }
}

const mapStateToProps = state => (
	{
		passo: state.cadastroReducer.passo
	}
)

export default connect(mapStateToProps, { autenticaConta, modificaVerTutorial })(CadastroEspecialistas)
