const initState = {
  carregandoDadosColaboradores: false,
  dadosColaboradores: [],
  dadosPerfilColaborador: {},
  carregandoPerfilColaborador: false,
  carregandoBeneficiariosPerfilColaborador: false,
  beneficiariosPerfilColaborador: [],
  carregandoHistoricoAtendimentosColaborador: false,
  historicoAtendimentosColaborador: [],
  dadosGraficosPerfilColaborador: {},
  carregandoDadosBeneficiarios: false,
  dadosBeneficiarios: [],
  modalUpListaVisivel: false,
  atualizaListaColaboradores: false
}

const colaboradoresReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_dados_colaboradores":
      return { ...state, carregandoDadosColaboradores: action.payload }

    case "modifica_dados_colaboradores":
      return { 
        ...state, 
        dadosColaboradores: action.payload, 
        carregandoDadosColaboradores: false
      }

    case "modifica_carregando_perfil_colaborador":
      return { ...state, carregandoPerfilColaborador: action.payload }

    case "modifica_dados_perfil_colaborador":
      return { ...state, dadosPerfilColaborador: action.payload }

    case "modifica_carregando_beneficiarios_perfil_colaborador":
      return { ...state, carregandoBeneficiariosPerfilColaborador: action.payload }

    case "modifica_beneficiarios_perfil_colaborador":
      return { 
        ...state,
         beneficiariosPerfilColaborador: action.payload, 
         carregandoBeneficiariosPerfilColaborador: false 
       }

    case "modifica_carregando_historico_atendimentos_colaborador":
      return { ...state, carregandoHistoricoAtendimentosColaborador: action.payload }

    case "modifica_historico_atendimentos_colaborador":
      return { 
        ...state, 
        historicoAtendimentosColaborador: action.payload["historico"],
        dadosGraficosPerfilColaborador: action.payload["graficos"],
        carregandoHistoricoAtendimentosColaborador: false 
      }

    case "modifica_carregando_dados_beneficiarios":
      return { ...state, carregandoDadosBeneficiarios: action.payload }

    case "modifica_dados_beneficiarios":
      return { ...state, dadosBeneficiarios: action.payload, carregandoDadosBeneficiarios: false }

    case "modifica_modal_up_lista_visivel":
      return { ...state, modalUpListaVisivel: action.payload }

    case "modifica_atualiza_lista_colaboradores":
      return { ...state, atualizaListaColaboradores: action.payload }  

    default:
      return state
  }
}

export default colaboradoresReducer;
