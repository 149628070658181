import authReducer from './AuthReducer';
import auxiliaresReducer from './AuxiliaresReducer';
import cadastroReducer from './CadastroReducer';
import atendimentoReducer from './AtendimentoReducer';
import especialidadesReducer from './EspecialidadesReducer';
import campanhasReducer from './CampanhasReducer';
import mensagensReducer from './MensagensReducer';
import dashboardReducer from './DashboardReducer';
import filtrosReducer from './FiltrosReducer';

import especialistaReducer from './EspecialistaReducer';
import visaoGeralEspecialistasReducer from './dashEspecialistas/VisaoGeralEspecialistasReducer';
import atendimentosEspecialistasReducer from './dashEspecialistas/AtendimentosEspecialistasReducer';

import rhEmpresaReducer from './RHEmpresaReducer';
import visaoGeralEmpresasReducer from './dashEmpresas/VisaoGeralEmpresasReducer';
import colaboradoresReducer from './dashEmpresas/ColaboradoresReducer';
import atendimentosEmpresasReducer from './dashEmpresas/AtendimentosEmpresasReducer';

import adminReducer from './AdminReducer';
import especialistasAdminReducer from './dashAdmin/EspecialistasReducer';
import usuariosReducer from './dashAdmin/UsuariosReducer';
import atendimentosAdminReducer from './dashAdmin/AtendimentosReducer';
import visaoGeralAdminReducer from './dashAdmin/VisaoGeralAdminReducer';
import financeiroReducer from './dashAdmin/FinanceiroReducer';
import cuponsReducer from './dashAdmin/CuponsReducer';
import recrutadoresReducer from './dashAdmin/RecrutadoresReducer';

import empresasReducer from './EmpresasReducer';

import blogReducer from './BlogReducer';

import { combineReducers } from 'redux';

import { userReducer } from '@store/user';

export default combineReducers({
  authReducer,
  atendimentoReducer,
  auxiliaresReducer,
  cadastroReducer,
  especialidadesReducer,
  especialistaReducer,
  visaoGeralEspecialistasReducer,
  atendimentosEspecialistasReducer,
  rhEmpresaReducer,
  visaoGeralEmpresasReducer,
  colaboradoresReducer,
  atendimentosEmpresasReducer,
  campanhasReducer,
  mensagensReducer,
  adminReducer,
  especialistasAdminReducer,
  usuariosReducer,
  atendimentosAdminReducer,
  visaoGeralAdminReducer,
  financeiroReducer,
  cuponsReducer,
  empresasReducer,
  dashboardReducer,
  blogReducer,
  filtrosReducer,
  recrutadoresReducer,
  userReducer,
});
