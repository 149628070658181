import React, { useState } from "react";
import { connect } from "react-redux";
import { functions } from '../../../../config/VariaveisFirebase';

import Botao from "../../../../global/BotaoAcao";

import { modificaLoading, modificaTemErro, sucessoAcao } from "../../../../acoes/AuxiliaresAcoes";
import { MENSAGEM_ERRO } from "../../../../acoes/FuncoesGerais";

const enviaWhats = functions.httpsCallable('msgContato');

const FormErroCadastroEspecialista = props => {

	const [whatsapp, setWhatsapp] = useState('');

	const acaoForm = e => {

	    e.preventDefault();

	    if(whatsapp.length > 9) {

	    	props.modificaLoading(true);

	    	let { email, registroMsgErro, ip } = props;

      		enviaWhats({ email, registroMsgErro, ip, whatsapp, tipoMsg: "erro-cadastro" })
      		.then(() => props.sucessoAcao("Número enviado! Em breve retornaremos"))
      		.catch(erro => props.modificaTemErro(MENSAGEM_ERRO(erro)))

	    } else {
	    	props.modificaTemErro("Por favor, forneça um número válido")
	    }
	}

	return(
		<form id="form-cadastro-usuario" onSubmit={acaoForm}>

	        <div className="topo-form" style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: 0, marginBottom: "-15px" }}>

	          <ul style={{ listStyle: "none", paddingLeft: "10px" }}>

	            <li className="texto-area" style={{ marginBottom: "10px", textAlign: "center" }}>Tem algo interessante acontecendo no seu cadastro...</li>

	            <li className="texto-area" style={{ textAlign: "center" }}>Por favor, informe o número do seu WhatsApp com o DDD para entrarmos em contato</li>

	          </ul>

	        </div>

	        <div className="corpo-form">

	          <div className="input-group" style={{ marginBottom: "5px" }}>

	            <input
	              type="tel"
	              className="input-field"
	              required
	              maxLength={15}
	              value={whatsapp}
	              onChange={e => setWhatsapp(e.target.value)}
	            />

	          </div>

	          <Botao textoBotao="Enviar" classeBotao="botao-acao" />

	        </div>

	    </form>
	)
}

const mapStateToProps = state => ({
    email: state.authReducer.emailLogin,
    registroMsgErro: state.cadastroReducer.registroMsgErro,
    ip: state.cadastroReducer.ip,
})

export default connect(mapStateToProps, { modificaLoading, modificaTemErro, sucessoAcao })(FormErroCadastroEspecialista)