export const showMsgError = (error) => {
  const { code, message } = error;

  const { language } = navigator;

  // if the language is english, return the message
  if (language === 'en') return message;

  // adapt the message if it's portuguese
  if (message.match(/network/g))
    return 'Erro ao acessar o servidor. Por favor, Verifique sua conexão';

  if (message.match(/This operation is sensitive/g))
    return 'Erro ao atualizar sua senha. Por favor, saia da conta e tente novamente';

  if (message.match(/The email address is already in use/g))
    return 'Já há uma conta cadastrada com esse e-mail';

  if (code === 'auth/wrong-password')
    return 'Erro no acesso! Por favor, verifique sua senha';

  return 'Erro ao processar sua solicitação! Por favor, tente novamente';
};
