import { auth, database, storage } from '../config/VariaveisFirebase';
import Compress from "react-image-file-resizer";
import moment from "moment";

import { MENSAGEM_ERRO } from "./FuncoesGerais";

// atualiza os dados do usuário
export const atualizaDadosPessoais = ({ idUsuario, nome, tel, detalhes, tipoUsuario }) => {
  return dispatch => {

  	dispatch({ type: "modifica_loading", payload: true });

	  database.ref(`usuarios/${idUsuario}`)
	  .update({ nome, tel }, erro => {

	  	if(!erro) {

	  		let tipo = "atualiza_dados_pessoais_rh";

	  		if(tipoUsuario === "admin")
	  			tipo = "atualiza_dados_pessoais_admin";

		  	dispatch({
		      type: tipo,
		      payload: { nome, tel }
		    })

		  	let data = moment().format(),
		  			chave = `${moment(data).format("YYYYMMDDHHmmss")}`;

		  	database.ref(`historico/registros/atividadesUsuarios/${idUsuario}/${chave}`)
				.set({
					atividade: `Você atualizou seus dados pessoais`,
					detalhes,
					data
				})

	      dispatch({
	        type: "modifica_sucesso_acao",
	        payload: { status: true, msg: "Dados atualizados!" }
	      });

	  	} else {
	  		dispatch({ type: "tem_erro", payload: MENSAGEM_ERRO(erro) });
	  	}
	  });
  }
}

// atualiza as fotos do usuário tanto no storage, quanto atualiza com a nova url no nó vinculado ao usuário na base de dados
export const uploadFotoUsuario = ({ id, foto, tipoUsuario }) => {
	return dispatch => {

		dispatch({ type: "modifica_modal_ajusta_fotos_aberto", payload: false })

		if(tipoUsuario === "cadastro")
			dispatch({ type: "modifica_loading_up_foto", payload: true });	
		else 
			dispatch({ type: "modifica_loading", payload: true });

    function comprimeFoto(img, tamanho, urlAlvo) {

    	const altura = tamanho;

    	Compress.imageFileResizer(
	      img,
	      tamanho,
	      altura,
	      "JPEG",
	      90,
	      0,
	      uri => { fotos[urlAlvo] = uri },
	      "blob"
	    );
    }

    function erroUpload(erro) {

    	alert(MENSAGEM_ERRO(erro))

    	if(tipoUsuario === "cadastro")
				dispatch({ type: "modifica_loading_up_foto", payload: false });	
			else 
				dispatch({ type: "modifica_loading", payload: false });
    }

    let fotos = { urlPerfil: "", urlMini: "" };

    let pasta = "especialistas";
    			
    if(tipoUsuario === "rh" || tipoUsuario === "admin")
    	pasta = "usuarios";

    const data = moment().format();

    fetch(foto)
		.then(res => res.blob())
		.then(fotoBlob => {

				comprimeFoto(fotoBlob, 600, "urlPerfil");
				comprimeFoto(fotoBlob, 100, "urlMini");

				let uploadFotoMini = false;

				let houveErro = false;

				// feita as atualizações com uma etapa para cada foto, pois ficou dando erro ao salvar as urls válidas ao mesmo tempo na base de dados 
				let timerFotoPerfil = setInterval(() => {

					if(fotos.urlPerfil !== "") {

						clearInterval(timerFotoPerfil);

    				const fotoPerfil = storage.ref(`${pasta}/${id}/fotoPerfil/perfil.jpg`);

    				fotoPerfil.put(fotos.urlPerfil)
			    	.then(() => {

			    		fotoPerfil.getDownloadURL()
			    		.then(uri => { 

			    			if(tipoUsuario === "rh" || tipoUsuario === "admin") {

			    				database.ref(`usuarios/${id}`)
					    		.update({ urlFotoPerfil: uri }, erro => {

					    			if(!erro) {

					    				let tipo = "modifica_url_foto_perfil_usuario";

							    		if(tipoUsuario === "admin")
							    			tipo = "modifica_url_foto_perfil_admin";

											dispatch({ type: tipo, payload: uri });

											uploadFotoMini = true;

					    			} else {

					    				erroUpload(erro)

					    				houveErro = true;
					    			}
					    		});

			    			} else {

			    				database.ref(`especialistas/${id}/infosProfissionais`)
			    				.update({ urlFotoPerfil: uri, dadosAprovados: "" }, erro => {

			    					if(!erro) {

			    						if(tipoUsuario === "cadastro") {

				    						dispatch({ type: "modifica_url_foto_perfil", payload: uri })

			    						} else {

			                	database.ref(`admin/notificacoesAdministrativas/${id}/dadosProfissionais`)
			                	.set({ titulo: "Atualização dos dados profissionais", data });
			    						}

			    						uploadFotoMini = true;

			    					} else {

			    						erroUpload(erro);

			    						houveErro = true;
			    					}
			    				});
			    			}
							})
							.catch(erro => erroUpload(erro))
			    	})
			    	.catch(erro => erroUpload(erro))	
					}
				}, 300);

				let timerFotoMini = setInterval(() => {

					if(uploadFotoMini) {

						clearInterval(timerFotoMini);

    				const fotoMini = storage.ref(`${pasta}/${id}/fotoPerfil/perfil-mini.jpg`);

    				fotoMini.put(fotos.urlMini)
			    	.then(() => {

			    		fotoMini.getDownloadURL()
			    		.then(uri => { 

			    			if(tipoUsuario === "rh" || tipoUsuario === "admin") {

			    				database.ref(`usuarios/${id}`)
					    		.update({ urlFotoMini: uri }, erro => {

					    			if(!erro) {

					    				let tipo = "modifica_url_foto_mini_usuario";

						    			if(tipoUsuario === "admin")
						    				tipo = "modifica_url_foto_mini_admin";

											dispatch({ type: tipo, payload: uri });

											const chave = `${moment(data).format("YYYYMMDDHHmmss")}`;

									  	database.ref(`historico/registros/atividadesUsuarios/${id}/${chave}`)
											.set({
												atividade: `Você atualizou sua foto de perfil`,
												data
											})

					    			} else {
					    				erroUpload(erro)
					    			}
					    		});

			    			} else {

			    				database.ref(`especialistas/${id}/infosProfissionais`)
			    				.update({ urlFotoMini: uri }, erro => {

			    					if(!erro) {

			    						if(tipoUsuario === "cadastro") {

				    						dispatch({ type: "modifica_url_foto_mini", payload: uri });

			    							dispatch({ 
			    								type: "modifica_loading_up_foto", payload: false 
			    							});	

			    						} else {

			    							database.ref(`especialistas/${id}/infosProfissionais`)
				    						.once("value").then(snapshot => {

													dispatch({ 
														type: "modifica_infos_profissionais_especialista", 
														payload: snapshot.val()
													});

													setTimeout(() => {
														dispatch({ type: "modifica_loading", payload: false });
													}, 1000)

													const chave = `${moment(data).format("YYYYMMDDHHmmss")}`;

				          				database.ref(`historico/registros/atividadesEspecialistas/${id}/${chave}`)
				          				.set({
				          					atividade: `Você atualizou sua foto de perfil`,
				          					data
				          				})
				    						})
				    						.catch(erro => erroUpload(erro))
			    						}

			    					} else {
			    						erroUpload(erro)
			    					}
			    				});
			    			}
							})
							.catch(erro => erroUpload(erro))
			    	})
			    	.catch(erro => erroUpload(erro))	
					}

					if(houveErro)
						clearInterval(timerFotoMini);

				}, 300);
		})
	}
}

// atualiza a senha do usuário
export const atualizaDadosAcesso = ({ idUsuario, emailLogin, senha, senhaLogin }) => {
  return dispatch => {

    dispatch({ type: "modifica_loading", payload: true });

    // realiza o login antes pois essa atualização requer um token recente
    auth.signInWithEmailAndPassword(emailLogin, senhaLogin)
		.then(value => {

	    auth.currentUser.updatePassword(senha)
  		.then(() => {

  			dispatch({ type: 'modifica_senha_login', payload: senha });

        dispatch({
          type: "modifica_sucesso_acao",
          payload: { status: true, msg: "Senha atualizada!" }
        });
  		})
  		.catch(erro => dispatch({ type: "tem_erro", payload:  MENSAGEM_ERRO(erro) }))
		})
		.catch(erro => dispatch({ type: "tem_erro", payload:  MENSAGEM_ERRO(erro) }))
  }
}

export const alteraDadosPessoaisEditar = dados => {
	return {
		type: "altera_dados_pessoais_editar",
		payload: dados
	}
}

export const alteraPrefixoPagina = prefixo => {
	return {
		type: "altera_prefixo_pagina",
		payload: prefixo
	}
}
