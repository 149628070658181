const initState = {
	listaFiltrada: [],
	filtroDataInicial: '',
	filtroDataFinal: '',
	filtroEspecialidade: '',
	filtroLocal: '',
	filtroNome: null,
	filtroId: null,
	filtroCargo: '',
	filtroModeloTrabalho: '',
    filtroUnidade: '',
    filtroStatus: '',
    filtroTipoCadastro: '',
    filtroEmpresa: null,
    filtroIntencao: '',
    filtroNota: '',
    filtroEspecialistas: {
    	especialidade: null,
    	preco: '',
    	precoMinimo: '',
    	precoMaximo: '',
    	estado: '',
    	cidade: null
    }
}

const filtrosReducer = (state = initState, action) => {

	switch(action.type) {

		case "atualiza_lista_filtrada":
			return { ...state, listaFiltrada: action.payload }

		case "atualiza_filtro_periodo":
			return { 
				...state, 
				filtroDataInicial: action.payload["dataInicial"], 
				filtroDataFinal: action.payload["dataFinal"] 
			}	
			
		case "atualiza_filtros_gerais":
			return { 
				...state, 
				[action.payload["tipoFiltro"]]: action.payload["valor"]
			}

		case "atualiza_filtros_especialistas":
			return {
				...state,
				filtroEspecialistas: {
					...state.filtroEspecialistas,
					[action.payload["tipoFiltro"]]: action.payload["valor"]
			    }
			}	

		case "atualiza_filtros_precos":
			return {
				...state,
				filtroEspecialistas: {
					...state.filtroEspecialistas,
					preco: action.payload["preco"],
    				precoMinimo: action.payload["minimo"],
    				precoMaximo: action.payload["maximo"]
			    }
			}	

		case "atualiza_filtro_cidade":
			return {
				...state,
				filtroEspecialistas: {
					...state.filtroEspecialistas,
					cidade: action.payload
			    }
			}	

		case "reseta_filtros":
			return {
				...state,
				filtroDataInicial: '',
				filtroDataFinal: '',
				filtroEspecialidade: '',
				filtroLocal: '',
				filtroNome: null,
				filtroId: null,
				filtroCargo: '',
				filtroModeloTrabalho: '',
			    filtroUnidade: '',
			    filtroStatus: '',
			    filtroTipoCadastro: '',
			    filtroEmpresa: null,
			    filtroIntencao: '',
			    filtroNota: '',
			    filtroEspecialistas: {
			    	especialidade: null,
			    	preco: '',
			    	precoMinimo: '',
			    	precoMaximo: '',
			    	estado: '',
    				cidade: null
			    }
			}	

		default:
			return state;
	}
}

export default filtrosReducer;