import { reduxConstants } from '../constants';

export const modifyConsentPrivacyPolicy = (key, date) => {
  return (dispatch) => {
    dispatch({
      type: reduxConstants.MODIFY_CONSENT_PRIVACY_POLICY,
      payload: true,
    });

    fetch(`https://us-central1-omm-be.cloudfunctions.net/salvaCientePolitica`, {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ chave: key }),
    }).then((result) => {
      result.json().then((response) => {
        const { status } = response.data;

        if (status === 200)
          dispatch({
            type: reduxConstants.MODIFY_PRIVACY_POLICY_CONSENT_DATA,
            payload: { key, date },
          });
      });
    });
  };
};
