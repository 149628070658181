import { auth } from '../config/VariaveisFirebase';

// obtem o endereço completo a partir do CEP fornecido
export const verificaEndereco = cep => {
	return dispatch => {

		dispatch({ type: "modifica_obtendo_endereco", payload: true });

		fetch(`https://viacep.com.br/ws/${cep}/json/`)
		.then(retorno => {

			retorno.json()
			.then(resposta => {

				let { logradouro, bairro, localidade, uf } = resposta,
					dadosEndereco = {
						endereco: logradouro,
						bairro,
						cidade: localidade,
						uf,
						pais: "Brasil"
					}

				dispatch({ type: "modifica_endereco_verificado", payload: dadosEndereco });
			});
		})
		.catch(error => {
			dispatch({ type: "modifica_obtendo_endereco", payload: false });
		})
	}
}

/*******************************************************
 * funções relacionadas aos menus laterais de navegação 
*******************************************************/
// modifica o tamanho do menu para ampliar a área de dados
export const modificaTamanhoMenuDashboard = valor => {
	return {
		type: "modifica_tamanho_menu_dashboard",
		payload: valor
	}
}

// aciona o item que ficará como ativo no menu
export const modificaMenuDashboardAtivo = item => {
	return {
		type: "modifica_menu_dashboard_ativo",
		payload: item
	}
}

// exibe ou não o logo ou o texto no menu lateral
export const modificaExibicaoElementosMenu = valor => {
	return {
		type: "modifica_exibicao_elementos_menu",
		payload: valor
	}
}

// aumenta ou diminui o menu do dashboard
export const modificaDeslocamentoMenuDashboard = valor => {
	return {
		type: "modifica_deslocamento_menu_dashboard",
		payload: valor
	}
}

export const modificaItensMenu = (prefixo, itensMenu) => {
	return {
		type: "modifica_itens_menu",
		payload: { prefixo, itensMenu }
	}
}

/*******************************************************
 * funções relacionadas a modais
*******************************************************/
// exibe ou esconde o fundo opaco do modal
export const modificaModalVisivel = valor => {
	return {
		type: "modifica_modal_visivel",
		payload: valor
	}
}

export const modificaVerTutorial = (status, passo) => {
	return dispatch => {

		dispatch({ type: 'modifica_ver_tutorial', payload: status })
		dispatch({ type: 'modifica_passo_tutorial', payload: passo })
	}
}

export const removeVisibilidadeModalTutorial = ({ modificaPasso, passo }) => {
	return dispatch => {

		dispatch({ type: 'remove_visibilidade_modal_tutorial' })

		if(modificaPasso)
			setTimeout(() => {
				dispatch({ type: 'modifica_passo_tutorial', payload: passo })
			}, 500)
	}
}

export const modificaPassoTutorial = etapa => {
	return {
		type: 'modifica_passo_tutorial',
		payload: etapa
	}
}

export const modificaModalAjustaFotosAberto = valor => {
	return {
		type: "modifica_modal_ajusta_fotos_aberto",
		payload: valor
	}
}

export const modificaFotoACortar = (img, tipoUsuario) => {
	return {
		type: "modifica_foto_a_cortar",
		payload: { img, tipoUsuario }
	}
}

/*******************************************************
 * funções relacionadas aos dropdowns
*******************************************************/
// verifica se há algum dropdown aberto para fechá-lo e na sequência, abrir o dropdown desejado
export const modificaStatusDropDown = (status, id) => {
	return {
		type: "modifica_status_dropdown",
		payload: { status, id }
	}
}

export const gerenciaDropdown = ({ novoDropdown, idDropDown, dropDownAberto }) => {
	return dispatch => {

		let id = `#dropdown-${novoDropdown}`;

	    if(dropDownAberto && idDropDown && idDropDown !== "" && id !== idDropDown) {

	      let elemento = document.querySelector(idDropDown);

	      if(elemento)
	        elemento.classList.remove("aberto");

	      dropDownAberto = false;
	    }

	    dispatch({ 
	    	type: "modifica_status_dropdown",
				payload: { status: !dropDownAberto, id } 
			});

	    document.querySelector(id).classList.toggle("aberto");
	}
}

export const escondeDropdown = ({ classe, idDropDown, dropDownAberto }) => {
	return dispatch => {

		let temControlaDropDown = false;

    // verifica a classe do elemento selecionado para ver se não é um elemento que abre/fecha o dropdowns    
    if(typeof(classe) === "string" && classe.match("controla-dropdown"))
      temControlaDropDown = true;

    // caso haja um dropdown aberto, ele o fecha
    if(!temControlaDropDown && dropDownAberto) {

    	dispatch({ 
	    	type: "modifica_status_dropdown",
				payload: { status: false, id: "" } 
			});

      let elemento = document.querySelector(idDropDown);

      if(elemento)
        elemento.classList.remove("aberto");
    }
	}
}

export const verificaPosicaoDropdown = (id, window) => {

	let posicao = document.getElementById(id).getBoundingClientRect(),
      top = posicao.top + window.scrollY + 20,
      left = posicao.left + window.scrollX - 120;

  return { top, left }    
}

/******************************************/

// navagação dentro das áreas que é realizada através de botões, abas ou o link retornar
export const modificaAbaAreaAtiva = (aba, nome) => {
	return {
		type: "modifica_aba_area_ativa",
		payload: { aba, nome }
	}
}

export const verificaAbaAtiva = paginas => {
	return dispatch => {

		const blocoUrl = window.location.href.split("/");

		let aba = "",
				nome = "";

		blocoUrl.every(item => {

			const resultado = paginas.find(pagina => pagina.aba === item);

			if(resultado) {

				aba = resultado.aba;
				nome = resultado.nome;

				return false;
			}

      return true;
    });		

		dispatch({
			type: "modifica_aba_area_ativa",
			payload: { aba, nome }
		})	
	}
}

// atualiza a senha de acesso dos usuários
export const atualizaSenha = senha => {
  return dispatch => {

  	if(senha.length > 5 && senha.length < 11) {

  		dispatch({ type: "modifica_loading", payload: true });

  		auth.currentUser.updatePassword(senha)
	    .then(() => {

	      dispatch({ type: 'modifica_senha_login', payload: senha });

	      dispatch({
	        type: "modifica_sucesso_acao",
	        payload: { status: true, msg: "Senha atualizada!" }
	      });
	    })
	    .catch(erro => {

	      let textoErro = "Erro na atualização da sua senha. Por favor, tente novamente";

	      if(erro.message.match(/network/g))
	        textoErro = "Erro no acesso ao servidor. Por favor, Verifique sua conexão";

	      dispatch({ type: "tem_erro", payload: textoErro });
	    })

  	} else {
  		dispatch({ type: "tem_erro", payload: "Senha com no mínimo 6 e no máximo 10 caracteres" });
  	}
  }
}

export const modificaReprocessarAcao = valor => {
	return {
		type: "modifica_reprocessar_acao",
		payload: valor
	}
}

/*******************************************************
 * funções relacionadas a imagens
*******************************************************/
// abre o lightbox, gerenciando a imagem e o título que serão exibidos
export const gerenciaLightbox = ({ status, img, titulo }) => {
	return {
		type: "gerencia_lightbox",
		payload: { status, img, titulo }
	}
}

// para atualizar uma tabela sempre que os dados da mesma forem alterados
export const modificaTabelaAtualizada = valor => {
	return {
		type: "modifica_tabela_atualizada",
		payload: valor
	}
}

// envio de mensagem para o nosso suporte dentro do dashboard
export const enviaMsgFaleConosco = ({ nome, email, tel, assunto, conteudoMsg }) => {
	return dispatch => {

		dispatch({ type: "modifica_loading", payload: true });

		let emailDestino;

		switch(assunto) {

			case "Suporte Técnico":
				emailDestino = "suporte@ommbe.com";
				break;

			case "Parcerias":
				emailDestino = "parcerias@ommbe.com";
				break;

			case "Financeiro":
				emailDestino = "financeiro@ommbe.com";
				break;

			default:
				emailDestino = "comercial@ommbe.com";
		}

		fetch("https://us-central1-omm-be.cloudfunctions.net/enviaMsgFaleConosco", {
			method: 'POST',
			headers: {
				'Accept': '*/*',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"assunto": assunto,
				"emailDestino": emailDestino,
				"msg": conteudoMsg,
				"nome": nome,
				"email": email,
				"tel": tel
			})
		})
		.then(resposta => resposta.json())
		.then(resultado => {

			let { status, message } = resultado.data;

			if(status === 200)
				dispatch({
					type: "modifica_sucesso_acao",
					payload: { status: true, msg: "Mensagem enviada!" }
				});
			else
				dispatch({ type: 'tem_erro', payload: message })
		})
		.catch(erro => {

			let textoErro = "Ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente";

			if(erro.message.match(/network/g))
			 textoErro = "Erro no envio. Por favor, Verifique sua conexão";

			dispatch({ type: 'tem_erro', payload: textoErro })
		});
	}
}
