const initState = {
  nomeUsuario: '',
	urlFotoMini: '',
	urlFotoPerfil: '',
	tel: '',
	cpf: '',
	dataNascimento: '',
  configuracoes: {
    promocoesPorNotificacoes: true,
    promocoesPorEmail: true
  },
  classeUsuario: "",
  acessosUsuario: [],
  habilitadosAcessoPainel: [],
  carregandoAcessoPainel: false,
  atualizarTabelaAcessoPainel: false,
  email: "",
  classe: "super",
  classeInicial: "super",
  textoBotao: "Adicionar",
  acessos: [],
  acessosInicial: "",
  idHabilitado: "",
  nomeHabilitado: "",
  campoDesabilitado: false,
  carregandoDadosAdmin: false,
  cadastrosDownload: [],
  logsAlteracoes: [],
  msgsContato: [],
  carregandoPosts: false,
  posts: [],
  nomeCupom: "",
  creditosCupom: "",
  limiteUsoCupom: "",
  cuponsCadastrados: [],
  modalNotificacoesAberto: false,
  deslocamentoModalNotificacoes: 'tranlateX(320px)',
  verificarNotificacoes: false,
  notificacoesAdminAtualizadas: false,
  notificacoesAdmin: []
}

const adminReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_dados_admin":
      return{
        ...state,
        nomeUsuario: action.payload["nome"],
        tel: action.payload["tel"],
			 	urlFotoMini: action.payload['urlFotoMini'],
			 	urlFotoPerfil: action.payload['urlFotoPerfil'],
			 	cpf: action.payload['cpf'],
			 	dataNascimento: action.payload['dataNascimento'],
			 	configuracoes: action.payload['configuracoes'],
        classeUsuario: action.payload['classe'],
        acessosUsuario: action.payload['acessos'],
      } 

    case "modifica_carregando_acesso_painel":
      return { ...state, carregandoAcessoPainel: action.payload }

    case "modifica_dados_acesso_painel":
      return {
        ...state,
        habilitadosAcessoPainel: action.payload,
        carregandoAcessoPainel: false,
        atualizarTabelaAcessoPainel: true 
      }  

    case "modifica_atualizar_tabela_acesso_painel":
      return { ...state, atualizarTabelaAcessoPainel: action.payload }   

    case "modifica_email_painel":
      return { ...state, email: action.payload }

    case "modifica_classe_painel":
      return { ...state, classe: action.payload }  
      
    case "modifica_classe_inicial_painel":
      return { ...state, classeInicial: action.payload }   
      
    case "modifica_texto_botao_painel":
      return { ...state, textoBotao: action.payload }        

    case "modifica_acessos_painel":
      return { ...state, acessos: action.payload }    

    case "modifica_acessos_inicial_painel":
      return { ...state, acessosInicial: action.payload }   

    case "modifica_id_habilitado_painel":
      return { ...state, idHabilitado: action.payload }    

    case "modifica_nome_habilitado_painel":
      return { ...state, nomeHabilitado: action.payload }    

    case "modifica_campo_desabilitado_painel":
      return { ...state, campoDesabilitado: action.payload }      

    case "reseta_campos_painel":
      return { 
        ...state, 
        email: "",
        classe: "super",
        textoBotao: "Adicionar",
        acessos: [],
        idHabilitado: "",
        nomeHabilitado: "",
        campoDesabilitado: false,
        nomeCupom: "",
        creditosCupom: "",
        limiteUsoCupom: ""
      }    

    case "modifica_carregando_dados_admin":
      return { ...state, carregandoDadosAdmin: action.payload }

    case "atualiza_cadastros_download":
      return { ...state, cadastrosDownload: action.payload, carregandoDadosAdmin: false }    
    case "atualiza_msgs_contato":
      return { ...state, msgsContato: action.payload, carregandoDadosAdmin: false }  

    case "atualiza_logs_alteracoes_admin":
      return { ...state, logsAlteracoes: action.payload, carregandoDadosAdmin: false }

    case "modifica_carregando_posts_admin":
      return { ...state, carregandoPosts: action.payload }

    case "atualiza_postagens_admin":
      return { ...state, posts: action.payload, carregandoPosts: false }    

    case "atualiza_nome_cupom":
      return { ...state, nomeCupom: action.payload } 

    case "atualiza_creditos_cupom":
      return { ...state, creditosCupom: action.payload }    

    case "atualiza_limite_uso_cupom":
      return { ...state, limiteUsoCupom: action.payload }    

    case "lista_cupons_cadastrados":
      return {
        ...state,
        cuponsCadastrados: action.payload,
        carregandoAcessoPainel: false,
        atualizarTabelaAcessoPainel: true 
      }    

    case "modifica_modal_notificacoes_aberto":
      return { ...state, modalNotificacoesAberto: action.payload }  
      
    case "modifica_deslocamento_modal_notificacoes":
      return { ...state, deslocamentoModalNotificacoes: action.payload }      

    case "modifica_notificacoes_admin":
      return {
        ...state,
        notificacoesAdmin: action.payload,
        notificacoesAdminAtualizadas: true 
      }     

    case "modifica_notificacoes_admin_atualizadas":
      return { ...state, notificacoesAdminAtualizadas: action.payload  }  

    case "altera_verificar_notificacoes":
      return { ...state, verificarNotificacoes: action.payload  }  

    case "atualiza_dados_pessoais_admin":
      return{
        ...state,
        nomeUsuario: action.payload["nome"],
        tel: action.payload["tel"]
      }   

    case "modifica_url_foto_perfil_admin":
      return { ...state, urlFotoPerfil: action.payload }

    case "modifica_url_foto_mini_admin":
      return { ...state, urlFotoMini: action.payload }  

    default:
      return state
  }
}

export default adminReducer;
