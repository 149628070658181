import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers/reducers';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Sentry.init({
  dsn: 'https://39e217c8a91145fcab30a449cacc6d83@o1068603.ingest.sentry.io/6112911',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.8,
});

Bugsnag.start({
  apiKey: 'cd85c4a946606c514f9b5d0bc194ad4d',
  plugins: [new BugsnagPluginReact()],
});

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
  },
  reducers
);

const store = createStore(persistedReducer, {}, applyMiddleware(thunk)),
  persistor = persistStore(store);

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root')
);
