import React, { Component } from 'react';
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';

import Botao from "../../../global/BotaoAcao";
import opcoesSelect from "../../../config/select/OpcoesSelect";
import SelectEspecialidades from "../../../global/forms/SelectEspecialidades";
import OpcoesLocaisAtendimento from "../../../global/forms/OpcoesLocaisAtendimento";
import RetornoFluxoCadastro from "../elementos/RetornoFluxoCadastro";
import ListaEspecialidadesAdicionadas from "./formEspecialidades/ListaEspecialidadesAdicionadas";

import { modificaTemErro } from "../../../acoes/AuxiliaresAcoes";
import { atualizaEspecialidadesAdicionadas, resetaAdicionarEspecialidade } from "../../../acoes/cadastroEspecialistas/GerenciaEspecialidades";
import { modificaPasso } from "../../../acoes/cadastroEspecialistas/CriarConta";
import { selecionaEspecialidade, atualizaStatusSelectEspecialidades, selecionaLocaisEspecialidade } from "../../../acoes/especialidades/GerenciaDadosEspecialidades";

class FormEspecialidades extends Component {

  constructor() {

    super();

    this.state = { 
      especialidadeSelecionada: '',
      duracao: '',
      exp: '',
      valorIndividual: '' 
    }
  }

  componentDidMount() {

    this.props.selecionaEspecialidade(''); 
    this.props.selecionaLocaisEspecialidade([]);
    this.props.atualizaStatusSelectEspecialidades(false);
  }

  componentDidUpdate() {

    if(this.props.adicionarNovaEspecialidade) {

      window.scroll({ top: 0, behavior: 'smooth' });
      this.props.resetaAdicionarEspecialidade();

      this.setState({ 
        especialidadeSelecionada: '',
        exp: '',
        duracao: '',
        valorIndividual: '' 
      });
    }

    if(this.state.especialidadeSelecionada !== this.props.especialidadeSelecionada) {

      this.setState({ 
        especialidadeSelecionada: this.props.especialidadeSelecionada,
        exp: '',
        duracao: '',
        valorIndividual: '' 
      });

      this.props.selecionaLocaisEspecialidade([]);
    }
  }

  salvaEspecialidades = e => {

    e.preventDefault();

    let adicionadas = this.props.especialidadesAdicionadas,
        especialidade = this.props.especialidadeSelecionada,
        { duracao, exp, valorIndividual } = this.state,
        locais = this.props.locaisSelecionados;

    if(especialidade) {

      let textoErro,
          idEspecialidade = especialidade.value;

      if(duracao === "")
        textoErro = "Por favor, selecione a duração do atendimento";

      if(!textoErro && exp === "")
        textoErro = "Por favor, selecione o tempo de experiência";

      // essas especialidades são atendidas em somente um local
      if((idEspecialidade === "cuidador-de-idosos") || (idEspecialidade === "enfermagem"))
        locais = ["Residência"];

      if(idEspecialidade === "odontologia")
        locais = ["Clínica própria"];

      if(!textoErro && locais.length < 1)
        textoErro = "Por favor, selecione ao menos um local de atendimento";

      if(!textoErro && valorIndividual === "")
        textoErro = "Por favor, informe o valor do atendimento";

      if(!textoErro) {

        adicionadas[idEspecialidade] = {
          nome: especialidade.label,
          duracao,
          exp,
          locais,
          valorIndividual: parseInt(valorIndividual),
          certificados: "",
          pacotes: ""
        }

        this.props.atualizaEspecialidadesAdicionadas({
          id: this.props.id, 
          especialidades: adicionadas, 
          idEspecialidade
        });

      } else {
        this.props.modificaTemErro(textoErro);
      }

    } else {

      if(Object.keys(adicionadas).length > 0) {

        confirmAlert({
          title: '',
          message: 'Deseja avançar sem adicionar uma nova especialidade?',
          buttons: [
            {
              label: 'Sim',
              onClick: () => this.props.modificaPasso(3)
            },
            {
              label: 'Não',
              onClick: () => false
            }
          ]
        });

      } else {
        this.props.modificaTemErro("Por favor, adicione ao menos uma especialidade");
      }
    }  
  }

  render() {

    return (
      <div className="form-dados" id="form-selecao-atendimentos">

        <RetornoFluxoCadastro passo={1} titulo="Informe as especialidades" />

        <form className="corpo-form" onSubmit={this.salvaEspecialidades}>

          <div className="bloco-especialidade">

            <SelectEspecialidades 
              opcoesSelect={opcoesSelect}
              placeHolder="Selecione a especialidade"
              required={false}
            />

            <div className="input-group" >

              <label>Duração do atendimento</label>

              <select
                className="input-field"
                value={this.state.duracao}
                onChange={e => this.setState({ duracao: e.target.value })}
              >
                <option value="">Selecione a duração</option>
                <option value="15">15 minutos</option>
                <option value="30">30 minutos</option>
                <option value="45">45 minutos</option>
                <option value="60">1 hora</option>
                <option value="90">1 hora e 30 minutos</option>
                <option value="120">2 horas</option>
                <option value="180">3 horas</option>
              </select>

            </div>

            <div className="input-group" >

              <label>Tempo de experiência</label>

              <select
                className="input-field"
                value={this.state.exp}
                onChange={e => this.setState({ exp: e.target.value })}
              >
                <option value="">Selecione o tempo de experiência</option>
                <option value="+10">Mais de 10 anos</option>
                <option value="5-10">Entre 5 e 10 anos</option>
                <option value="3-5">Entre 3 e 5 anos</option>
                <option value="1-3">Entre 1 e 3 anos</option>
                <option value="-1">Menos de um ano</option>
              </select>

            </div>

            <OpcoesLocaisAtendimento />

            <div className="porta-inputs">

              <div className="input-group" style={{ display: "flex", alignItems: "center" }}>

                <p className="texto-area" style={{ marginLeft: 5 }}>Atendimento Individual</p>

              </div>

              <div className="input-group" >

                <label>Valor</label>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                  <input
                    type="number"
                    min={1}
                    className="input-field"
                    value={this.state.valorIndividual}
                    onChange={e => this.setState({ valorIndividual: e.target.value })}
                  />

                  <p className="texto-area" style={{ marginLeft: 3 }}>,00</p>

                </div>

              </div>

            </div>

          </div>

          <Botao textoBotao="avançar" classeBotao="botao-area" />

        </form>

        <div className="input-group" style={{ width: "100%", textAlign: "left", marginTop: 35 }}>

          <p className="texto-area" style={{ color: "#6e6e6e", marginLeft: 5 }}>Lista de especialidades adicionadas</p>

          <ListaEspecialidadesAdicionadas />

        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
	{
    id: state.authReducer.idUsuario,
    especialidadesAdicionadas: state.cadastroReducer.especialidadesAdicionadas,
    adicionarNovaEspecialidade: state.cadastroReducer.adicionarNovaEspecialidade,
    especialidadeSelecionada: state.especialidadesReducer.especialidadeSelecionada,
    locaisSelecionados: state.especialidadesReducer.locaisSelecionadosEspecialidade
	}
)

export default connect(mapStateToProps, {
  modificaTemErro,
  atualizaEspecialidadesAdicionadas,
  modificaPasso,
  resetaAdicionarEspecialidade,
  selecionaEspecialidade, 
  atualizaStatusSelectEspecialidades,
  selecionaLocaisEspecialidade })(FormEspecialidades)
