const initState = {
  carregandoTotaisEmpresa: false,
  totaisEmpresa: {
    planoContratado: '',
    totalAgendados: 0,
    atendimentosRealizados: 0,
    colaboradoresAtivos: 0,
    creditosUtilizados: 0,
    cidadesAtendidas: 0,
    engajamento: 0
  },
  carregandoColaboradoresMaisUtilizam: false,
  colaboradoresMaisUtilizam: [],
  carregandoUltimasAtividades: false,
  ultimasAtividades: [],
  carregandoCreditosUltimosMeses: false,
  creditosUltimosMeses: [],
  carregandoUltimosAtendimentos: false,
  ultimosAtendimentos: []
}

const visaoGeralEmpresasReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_totais_empresa":
      return { 
        ...state, 
        totaisEmpresa: {
          planoContratado: '',
          totalAgendados: 0,
          atendimentosRealizados: 0,
          colaboradoresAtivos: 0,
          creditosUtilizados: 0,
          cidadesAtendidas: 0,
          engajamento: 0
        }, 
        carregandoTotaisEmpresa: action.payload
      }

    case "modifica_totais_empresa":
      return { ...state, totaisEmpresa: action.payload, carregandoTotaisEmpresa: false }

    case "modifica_carregando_colaboradores_mais_utilizam":
      return { ...state, carregandoColaboradoresMaisUtilizam: action.payload }

    case "modifica_colaboradores_mais_utilizam":
      return { ...state, colaboradoresMaisUtilizam: action.payload, carregandoColaboradoresMaisUtilizam: false }

    case "modifica_carregando_ultimas_atividades":
      return { ...state, carregandoUltimasAtividades: action.payload }

    case "modifica_ultimas_atividades":
      return { ...state, ultimasAtividades: action.payload, carregandoUltimasAtividades: false }

    case "modifica_carregando_creditos_ultimos_meses":
      return { ...state, carregandoCreditosUltimosMeses: action.payload }

    case "modifica_creditos_ultimos_meses":
      return { 
        ...state, 
        creditosUltimosMeses: action.payload, 
        carregandoCreditosUltimosMeses: false 
      }

    case "modifica_carregando_ultimos_atendimentos":
      return { ...state, carregandoUltimosAtendimentos: action.payload }

    case "modifica_ultimos_atendimentos":
      return { ...state, ultimosAtendimentos: action.payload, carregandoUltimosAtendimentos: false }  

    default:
      return state
  }
}

export default visaoGeralEmpresasReducer;
