const initState = {
	carregandoCupons: false,
	dadosCupons: [],
	cuponsAtualizados: false,
	editarCupom: {},
	editarCupomCadastro: false
}

const cuponsReducer = (state = initState, action) => {

	switch(action.type) {

		case "modifica_carregando_cupons":
			return { ...state, carregandoCupons: action.payload }

		case "atualiza_dados_cupons":
			return {
				...state,
				dadosCupons: action.payload,
				carregandoCupons: false,
				cuponsAtualizados: true
			}	

		case "modifica_cupons_atualizados":
			return { ...state, cuponsAtualizados: action.payload }	

		case "modifica_editar_cupom":
      		return { ...state, editarCupom: action.payload } 

      	case "modifica_editar_cupom_cadastro":
      		return { ...state, editarCupomCadastro: action.payload }	

		default:
			return state;
	}
}

export default cuponsReducer;