const initState = {
  carregandoListaUsuarios: false,
  listaUsuarios: [],
  listaEmpresasUsuarios: [],
  dadosPerfilUsuario: {},
  listaEmpresasCadastradas: [],
  listaEmpresasAtualizada: false,
  carregandoBeneficiariosPerfilUsuario: false,
  beneficiariosPerfilUsuario: [],
  carregandoHistoricoPerfilUsuario: false,
  historicoPerfilUsuario: [],
  dadosGraficosPerfilUsuario: {},
  carregandoAlteracoesPerfilUsuario: false,
  logsAlteracoesPerfilUsuario: [],
  listaBeneficiarios: [],
  dadosBeneficiarioEditar: {},
  tabelaUsuariosAtualizada: false
}

const usuariosReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_lista_usuarios":
      return { ...state, carregandoListaUsuarios: action.payload }

    case "atualiza_lista_usuarios":
      return { 
        ...state, 
        listaUsuarios: action.payload["listaUsuarios"], 
        listaEmpresasUsuarios: action.payload["listaEmpresas"], 
        carregandoListaUsuarios: false 
      }

    case "modifica_dados_perfil_usuario":
      return { ...state, dadosPerfilUsuario: action.payload }

    case "modifica_lista_empresas_atualizada":
      return { ...state, listaEmpresasAtualizada: action.payload } 

    case "atualiza_lista_empresas_cadastradas":
      return { 
        ...state, 
        listaEmpresasCadastradas: action.payload,  
        listaEmpresasAtualizada: true 
      }

    case "modifica_carregando_beneficiarios_perfil_usuario":
      return { ...state, carregandoBeneficiariosPerfilUsuario: action.payload }

    case "atualiza_beneficiarios_perfil_usuario":
      return { 
        ...state, 
        beneficiariosPerfilUsuario: action.payload, 
        carregandoBeneficiariosPerfilUsuario: false 
      }  

    case "modifica_carregando_historico_perfil_usuario":
      return { ...state, carregandoHistoricoPerfilUsuario: action.payload }

    case "atualiza_historico_perfil_usuario":
      return { 
        ...state, 
        historicoPerfilUsuario: action.payload["historico"], 
        dadosGraficosPerfilUsuario: action.payload["graficos"], 
        carregandoHistoricoPerfilUsuario: false 
      }    

    case "modifica_carregando_alteracoes_perfil_usuario":
      return { ...state, carregandoAlteracoesPerfilUsuario: action.payload }

    case "atualiza_alteracoes_perfil_usuario":
      return { 
        ...state,  
        logsAlteracoesPerfilUsuario: action.payload, 
        carregandoAlteracoesPerfilUsuario: false 
      }   

    case "atualiza_lista_beneficiarios":
      return { 
        ...state, 
        listaBeneficiarios: action.payload["listaBeneficiarios"], 
        listaEmpresasUsuarios: action.payload["listaEmpresas"], 
        carregandoListaUsuarios: false 
      }  

    case "modifica_dados_beneficiario_editar":
      return { ...state, dadosBeneficiarioEditar: action.payload }  

    case "modifica_tabela_usuarios_atualizada":
      return { ...state, tabelaUsuariosAtualizada: action.payload }  

    default:
      return state
  }
}

export default usuariosReducer;
