import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import erroProcesso from '../../../imagens/dashboard/erro-processo.png';
import erroConexao from '../../../imagens/dashboard/erro-conexao.png';
import recarregar from '../../../imagens/dashboard/icones/recarregar.png';

import { modificaReprocessarAcao } from "../../../acoes/DashboardAcoes";

class ErroProcessamento extends Component {

	exibeImg = () => {

		let estilo = { width: 100, height: "auto" }

		if(this.props.msgErro.match(/Verifique sua conexão/))
			return(<img style={estilo} src={erroConexao} alt="" />);

		return(<img style={estilo} src={erroProcesso} alt="" />);
	}

	render() {

		return(
			<div className="exibe-erro-processamento">

	          <div style={{ textAlign: "center" }}>
				
				{ this.exibeImg() }

				<p className="texto-cartao">{ this.props.msgErro }</p>

			  </div>

	          <div className="porta-link-visao-geral">

	            <Link className="link-visao-geral" to="#" onClick={e => {

	              e.preventDefault();

	              this.props.modificaReprocessarAcao(true);
	            }}>

	              <img src={recarregar} alt="" />
	              Recarregar

	            </Link>

	          </div>

	        </div>
		)
	}
}

const mapStateToProps = state => ({
    msgErro: state.auxiliaresReducer.msgErroInicializcao
});

export default connect(mapStateToProps, { modificaReprocessarAcao })(ErroProcessamento)