const initState = {
  carregandoListaEspecialistas: false,
  listaEspecialistas: [],
  idPerfilEspecialista: '',
  loadingPerfilEspecialista: false,
  dadosEspecialista: {},
  pendentesEspecialista: {},
  carregandoEspecialidadesPerfilEspecialista: false,
  especialidadesPerfilEspecialista: [],
  carregandoAtendimentosPerfilEspecialista: false,
  atendimentosAgendadosEspecialista: [],
  historicoAtendimentosEspecialista: [],
  graficosEspecialista: {},
  logsAlteracoes: [],
  canceladosEspecialista: [],
  processandoAprovacaoDadosBasicos: false,
  sucessoAprovacaoDadosBasicos: false,
  processandoAprovacaoDadosProfissionais: false,
  sucessoAprovacaoDadosProfissionais: false,
  loadingAprovacaoDocumentos: Array(10).fill(false),
  modalDadosBasicosAberto: false,
  deslocamentoModalDadosBasicos: 'tranlateX(320px)',
  modalDadosProfissionaisAberto: false,
  deslocamentoModalDadosProfissionais: 'tranlateX(320px)',
  modalEnderecoAtendimentoAberto: false,
  deslocamentoModalEnderecoAtendimento: 'tranlateX(320px)',
  atualizarDadosModalEspecialista: false,
  atualizarPerfilEspecialista: false,
  certificadosAtualizados: false,
  modalAjustaFotosEspecialistasAberto: false
}

const especialistasAdminReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_lista_especialistas":
      return { ...state, carregandoListaEspecialistas: action.payload }

    case "modifica_lista_especialistas":
      return { 
        ...state, 
        listaEspecialistas: action.payload, 
        carregandoListaEspecialistas: false 
      }

    case "modifica_id_perfil_especialista":
      return { 
        ...state, 
        idPerfilEspecialista: action.payload, 
        loadingPerfilEspecialista: true 
      }  

    case "modifica_loading_perfil_especialista":
      return { ...state, loadingPerfilEspecialista: action.payload }  

    case "modifica_pendentes_especialista":
      return { ...state, pendentesEspecialista: action.payload }  

    case "modifica_dados_especialista":
      return { ...state, dadosEspecialista: action.payload }      

    case "modifica_carregando_especialidades_perfil_especialista":
      return { ...state, carregandoEspecialidadesPerfilEspecialista: action.payload }

    case "modifica_especialidades_perfil_especialista":
      return { 
        ...state, 
        especialidadesPerfilEspecialista: action.payload, 
        carregandoEspecialidadesPerfilEspecialista: false 
      }  

    case "modifica_carregando_atendimentos_perfil_especialista":
      return { ...state, carregandoAtendimentosPerfilEspecialista: action.payload }  

    case "modifica_agendamentos_perfil_especialista":
      return { 
        ...state, 
        atendimentosAgendadosEspecialista: action.payload,
        carregandoAtendimentosPerfilEspecialista: false 
      }  

    case "modifica_historico_perfil_especialista":
      return { 
        ...state, 
        historicoAtendimentosEspecialista: action.payload, 
        carregandoAtendimentosPerfilEspecialista: false
      } 

    case "atualiza_graficos_especialista":
      return {
        ...state,
        graficosEspecialista: action.payload,
        carregandoAtendimentosPerfilEspecialista: false
      }  

    case "atualiza_logs_alteracoes_especialista":
      return {
        ...state,
        logsAlteracoes: action.payload,
        carregandoAtendimentosPerfilEspecialista: false
      }   

    case "modifica_atendimentos_cancelados_especialista":
      return { 
        ...state, 
        canceladosEspecialista: action.payload, 
        carregandoAtendimentosPerfilEspecialista: false
      }   

    case "modifica_processando_aprovacao_dados_basicos":
      return { ...state, processandoAprovacaoDadosBasicos: action.payload }  

    case "modifica_sucesso_aprovacao_dados_basicos":
      return { 
        ...state, 
        sucessoAprovacaoDadosBasicos: action.payload, 
        processandoAprovacaoDadosBasicos: false
      }  

    case "modifica_processando_aprovacao_dados_profissionais":
      return { ...state, processandoAprovacaoDadosProfissionais: action.payload }    

    case "modifica_sucesso_aprovacao_dados_profissionais":
      return { 
        ...state, 
        sucessoAprovacaoDadosProfissionais: action.payload, 
        processandoAprovacaoDadosProfissionais: false
      }   

    case "modifica_loading_aprovacao_documentos":

      let loadingAprovacaoDocumentos = [];

      loadingAprovacaoDocumentos[action.payload['index']] = action.payload['status'];

      return { ...state, loadingAprovacaoDocumentos }  

    case "reseta_loading_aprovacao_documentos": 
      return {
        ...state,
        loadingAprovacaoDocumentos: Array(10).fill(false)
      } 

    case "modifica_deslocamento_modal_dados_basicos":
      return { ...state, deslocamentoModalDadosBasicos: action.payload }  

    case "modifica_modal_dados_basicos_aberto":
      return { ...state, modalDadosBasicosAberto: action.payload }    

    case "modifica_deslocamento_modal_dados_profissionais":
      return { ...state, deslocamentoModalDadosProfissionais: action.payload }  

    case "modifica_modal_dados_profissionais_aberto":
      return { ...state, modalDadosProfissionaisAberto: action.payload }     

    case "modifica_deslocamento_modal_endereco_atendimento":
      return { ...state, deslocamentoModalEnderecoAtendimento: action.payload }  

    case "modifica_modal_endereco_atendimento_aberto":
      return { ...state, modalEnderecoAtendimentoAberto: action.payload }       

    case "modifica_atualizar_dados_modal_especialista":
      return { ...state, atualizarDadosModalEspecialista: action.payload }  

    case "atualiza_perfil_especialista":
      return { ...state, atualizarPerfilEspecialista: action.payload }  
    case "modifica_certificados_atualizados":
      return { ...state, certificadosAtualizados: action.payload }  

    case "modifica_modal_ajusta_fotos_especialistas_aberto":
      return { ...state, modalAjustaFotosEspecialistasAberto: action.payload }   

    default:
      return state
  }
}

export default especialistasAdminReducer;
