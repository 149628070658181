import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';

import { modificaOpcoesAtendimento } from "../../../../acoes/cadastroEspecialistas/GerenciaOpcoesAtendimento";

import iconeInfo from "../../../../imagens/cadastro/info-branco.png";

const SelecaoOpcoesAtendimento = props => {

	const exibeOpcoes = () => {

		let blocoOpcoes = props.opcoes.map(item => {

			let classe = "opcao-selecao",
				selecionados = props[props.tipo];

			if(selecionados !== "" && selecionados.length > 0 && selecionados.includes(item))	
				classe = "opcao-selecao ativo";	

			return(
			  <div
	            className={classe}
	            onClick={() => atualizaOpcao(item, props.tipo)}
	          >
	            <p className="texto-area">{ item }</p>
	          </div>
			)
		})

	    return blocoOpcoes;    		
	}

	const [atualizou, setAtualizou] = useState(false),
		  [opcoesExibidas, setOpcoesExibidas] = useState(exibeOpcoes())

	useEffect(() => {

		setAtualizou(false);

		setOpcoesExibidas(exibeOpcoes())
		
	}, [atualizou])	  

	const atualizaOpcao = (item, tipo) => {

	    let { id } = props,
	    	opcoes = props[tipo]; 

	    if(!opcoes || opcoes === "")
	      opcoes = [];

	    let index = opcoes.indexOf(item);

	    if(index < 0)
	      opcoes.push(item);
	    else
	      opcoes.splice(index, 1);

	    props.modificaOpcoesAtendimento({ id, tipo, valor: opcoes });

	    setAtualizou(true);
	}

	const exibeInfo = () => {

		if(props.tipo === "horarios")
			return(
				<div
	              className="botao-info"
	              style={{ top: 25 }}
	              onClick={() => {

	                confirmAlert({
	          				title: 'Aviso',
	          				message: 'Atendimentos antes das 7h e após às 21h podem ganhar bônus e receber até 100% a mais',
	          				buttons: [
	          					{
	          						label: 'OK',
	          						onClick: () => false
	          					}
	          				]
	          			});
	              }}
	            >
	              <img src={iconeInfo} alt="i" />
	            </div>
			)

		return null;
	}

	const obs = () => {

		if(props.tipo === "idiomas")
			return(<span className="obs" style={{ marginLeft: 5 }}>(Opcional e mais de um pode ser selecionado)</span>)

		return(<span className="obs" style={{ marginLeft: 5 }}>(Mais de um pode ser selecionado)</span>)
	}

	return(
		<div className="input-group" style={{ width: "100%", textAlign: "left", marginBottom: 20 }}>

	        <p className="texto-area" style={{ color: "#6e6e6e", marginBottom: -5, marginLeft: 5 }}>{ props.titulo }</p>

	        { exibeInfo() }

	        { obs() }

	        <div className="porta-opcoes" style={{ marginTop: 10 }}>
	        	{ opcoesExibidas }
	        </div>

	    </div>
	)
}

const mapStateToProps = state => ({
	id: state.authReducer.idUsuario,
	diasSemana: state.cadastroReducer.diasSemanaAtendimento,
	horarios: state.cadastroReducer.horariosAtendimento,
    idiomas: state.cadastroReducer.idiomasAtendimento
})

export default connect(mapStateToProps, { modificaOpcoesAtendimento })(SelecaoOpcoesAtendimento)
