const initState = {
	obtendoEndereco: false,
  enderecoVerificado: {
    endereco : "",
    bairro: "",
    cidade: "",
    uf: "",
    pais: ""
  },
  lightboxAberto: false,
  imgLightbox: '',
  tituloLightbox: '',
  tabelaAtualizada: false,
  notificacoesRecebidas: [],
  notificacoesVerificadas: false,
  exibeTextoLogoMenu: true,
  tamanhoMenuDashboard: 280,
	deslocamentoMenuDashboard: 'tranlateX(-320px)',
	prefixoUrlAlvo: '',
	itensMenu: [],
	modalVisivel: false,
	menuDashboardAtivo: "visao-geral",
	abaAreaAtiva: '',
	nomeAbaAreaAtiva: '',
	dropDownAberto: false,
	idDropDown: '',
	houveErroInicializacao: false,
	msgErroInicializcao: '',
	modalAjustaFotosAberto: false,
	fotoACortar: "",
	tipoUsuarioFotoACortar: "",
	reprocessarAcao: false
}

const dashboardReducer = (state = initState, action) => {
	switch(action.type) {

		case "modifica_obtendo_endereco":
		  return { ...state, obtendoEndereco: action.payload }

		case "modifica_endereco_verificado":
			return { 
				...state, 
				enderecoVerificado: action.payload, 
				obtendoEndereco: false 
			}  
			
		case "gerencia_lightbox": 
			return { 
				...state,
				lightboxAberto: action.payload['status'],
				imgLightbox: action.payload['img'],
				tituloLightbox: action.payload['titulo']
			}	

		case "modifica_tabela_atualizada":
			return { ...state, tabelaAtualizada: action.payload }	

		case 'modifica_notificacoes_verificadas':
			return { ...state, notificacoesVerificadas: action.payload }	

		case 'modifica_notificacoes_recebidas':
      return { ...state, notificacoesRecebidas: action.payload, notificacoesVerificadas: true }	

    case "modifica_menu_dashboard_ativo":
			return { ...state, menuDashboardAtivo: action.payload }

		case "modifica_tamanho_menu_dashboard":
			return { ...state, tamanhoMenuDashboard: action.payload }  

		case "modifica_exibicao_elementos_menu":
			return { ...state, exibeTextoLogoMenu: action.payload }  	

		case "modifica_modal_visivel":
			return { ...state, modalVisivel: action.payload }

		case "modifica_deslocamento_menu_dashboard":
			return { ...state, deslocamentoMenuDashboard: action.payload }

		case "modifica_aba_area_ativa":
			return {
				...state,
				abaAreaAtiva: action.payload["aba"],
				nomeAbaAreaAtiva: action.payload["nome"]
			}
			
		case "modifica_status_dropdown":
			return {
				...state,
				dropDownAberto: action.payload["status"],
				idDropDown: action.payload["id"]
			}

		case "modifica_houve_erro_inicializacao":
    	return { 
    		...state, 
    		houveErroInicializacao: action.payload["status"], 
    		msgErroInicializcao: action.payload["msg"] 
    	}			

    case "modifica_itens_menu":
    	return { 
    		...state, 
    		prefixoUrlAlvo: action.payload["prefixo"], 
				itensMenu: action.payload["itensMenu"]
    	}		

    case "modifica_modal_ajusta_fotos_aberto":
    	return { ...state, modalAjustaFotosAberto: action.payload }	

    case "modifica_foto_a_cortar":
    	return { 
    		...state, 
    		fotoACortar: action.payload["img"],
    		tipoUsuarioFotoACortar: action.payload["tipoUsuario"] 
    	}	

    case "modifica_reprocessar_acao":
    	return { ...state, reprocessarAcao: action.payload }	

		default:
			return state
	}
}

export default dashboardReducer;
