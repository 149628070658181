const initState = {
	carregandoListaEspecialistasRecrutadores: false,
	dadosListaEspecialistasRecrutadores: [],
	carregandoListaRecrutadores: false,
	dadosListaRecrutadores: []
}

const recrutadoresReducer = (state = initState, action) => {

	switch(action.type) {

		case "modifica_carregando_lista_especialistas_recrutadores":
			return { ...state, carregandoListaEspecialistasRecrutadores: action.payload }

		case "modifica_dados_lista_especialistas_recrutadores":
			return { 
				...state,  
				dadosListaEspecialistasRecrutadores: action.payload,
				carregandoListaEspecialistasRecrutadores: false
			}	

		case "modifica_carregando_lista_recrutadores":
			return { ...state, carregandoListaRecrutadores: action.payload }

		case "modifica_dados_lista_recrutadores":
			return { 
				...state,  
				dadosListaRecrutadores: action.payload,
				carregandoListaRecrutadores: false
			}		

		default:
			return state;
	}
}

export default recrutadoresReducer;