import { database } from '../../config/VariaveisFirebase';

import { MENSAGEM_ERRO } from "../FuncoesGerais";

// monta a lista com todas as especialidades cadastradas
export const obtemDadosEspecialidades = () => {
  return dispatch => {

    dispatch({ 
      type: "modifica_houve_erro_inicializacao", 
      payload: { status: false, msg: '' } 
    });

    dispatch({ type: "modifica_carregando_dados_especialidades", payload: true });

    let dadosEspecialidades = [];

    database.ref('especialidades/bemestar')
    .once("value").then(data => {

    	let bemEstar = data.val();

    	for(let id in bemEstar) {

        if(bemEstar[id].habilitada) {

          bemEstar[id].id = id;
          bemEstar[id].tipo = "Bem estar";

          dadosEspecialidades.push(bemEstar[id]);
        }
    	}

      database.ref('especialidades/telemedicina')
      .once("value").then(data => {

      	let telemedicina = data.val();

      	for(let id in telemedicina) {

          if(telemedicina[id].habilitada) {

        		telemedicina[id].id = id;
      		  telemedicina[id].tipo = "Telemedicina";

      		  dadosEspecialidades.push(telemedicina[id]);
          }  
      	}

        setTimeout(() => {

          dispatch({
            type: "modifica_dados_especialidades",
            payload: dadosEspecialidades
          });
        }, 100);
      })
      .catch(erro => {

        dispatch({ 
          type: "modifica_houve_erro_inicializacao", 
          payload: { status: true, msg: MENSAGEM_ERRO(erro) } 
        });
      })
    })
    .catch(erro => {

      dispatch({ 
        type: "modifica_houve_erro_inicializacao", 
        payload: { status: true, msg: MENSAGEM_ERRO(erro) } 
      });
    })
  }
}