import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./menu-topo.css";

import LinkBlog from "./secoes/LinkBlog";

import logo from "../../../imagens/logo-ommbe.png";
import iconeMenu from "../../../imagens/icone-menu.png";

export default class MenuTopo extends Component {

  constructor() {

    super();

    let blocoUrl = window.location.href.split("/");

    this.state = { pagina: blocoUrl[blocoUrl.length - 1] }
  }

  geraLink = (url, texto, ehLinkTopo) => {

    let idLink = `link-${url}`,
        classe = '';

    if(ehLinkTopo)
      classe = 'link-topo';

    // caso seja a mesma página, exibe apenas um texto ao invés do link
    if(this.state.pagina === url) {

      classe = `ativo ${classe}`;

      return(<li className={classe} id={idLink}>{texto}</li>)
    }

    let destino = `/${url}`;

    return(<li className={classe} id={idLink}><Link to={destino}>{texto}</Link></li>)
  }

  botaoEntrar = () => {

    if(this.state.pagina !== "entrar")
      return(<Link to="/entrar"><button className="botao-branco" id="botao-entrar">Login</button></Link>) 

    return null;
  }

  adicionaClasse = (elem, classe) => {
    document.querySelector(elem).classList.add(classe);
  }

  abreMenuMobile = () => {

    this.adicionaClasse("#fundo-menu-mobile", "expandido");
    this.adicionaClasse("#menu-mobile-links", "expandido");
    this.adicionaClasse("body", "sem-rolagem");
  }

  render() {

    return (
      <div className="menu-topo">

        <Link to="/"><img className="logo-menu" src={logo} alt="OMM BE" width="180" height="42" /></Link>

        <ul className="menu-links">

          {this.geraLink("quem-somos", "Who we are", true)}
          {this.geraLink("servicos", "Solutions", false)}
          {this.geraLink("lista-de-especialidades", "Specialties", true)}
          {this.geraLink("especialistas", "Specialists", true)}
          
          {/* <LinkBlog /> */}

        </ul>

        <Link to="/servicos"><button className="botao-branco" id="botao-servicos">Solutions</button></Link>

        { this.botaoEntrar() }

        <button
          className="botao-menu"
          onClick={this.abreMenuMobile}
        ><img src={iconeMenu} alt="Menu" width="28" height="28" /></button>

      </div>
    )
  }
}
