//import Teste from "./paginas/teste"

import loadable from '@loadable/component';
import fallback from './global/Fallback';
import Entrar from './paginas/entrar';
import CadastroEspecialistas from './paginas/cadastroEspecialistas';

const Inicial = loadable(() => import('./paginas/inicial'), fallback);
const QuemSomos = loadable(() => import('./paginas/quemSomos'), fallback);
const Servicos = loadable(() => import('./paginas/servicos'), fallback);
const GuiaEspecialidades = loadable(
  () => import('./paginas/guiaEspecialidades'),
  fallback
);
const Especialistas = loadable(
  () => import('./paginas/especialistas'),
  fallback
);
const Blog = loadable(() => import('./paginas/blog'), fallback);

const PoliticaPrivacidade = loadable(
  () => import('./paginas/politicaPrivacidade'),
  fallback
);
const TermosUso = loadable(() => import('./paginas/termosUso'), fallback);

const EntrarAdmin = loadable(() => import('./paginas/painel'), fallback);
const RecuperarSenha = loadable(
  () => import('./paginas/recuperarSenha'),
  fallback
);
const ResetarSenha = loadable(() => import('./paginas/resetarSenha'), fallback);

const AlternativaDatas = loadable(() =>
  import('./paginas/acoesEspecialistas/AlternativaDatas')
);
const ConfirmarAtendimento = loadable(() =>
  import('./paginas/acoesEspecialistas/ConfirmarAtendimento')
);
const CancelarAtendimento = loadable(() =>
  import('./paginas/acoesEspecialistas/CancelarAtendimento')
);

const DashEspecialistas = loadable(
  () => import('./paginas/dashEspecialistas/'),
  fallback
);
const DashEmpresas = loadable(
  () => import('./paginas/dashEmpresas/'),
  fallback
);
const DashAdmin = loadable(() => import('./paginas/dashAdmin/'), fallback);

const FotoEspecialista = loadable(() => import('./paginas/fotos'));

const rotasConfig = [
  {
    path: '/',
    component: Inicial,
    exact: true,
  },
  {
    path: '/quem-somos',
    component: QuemSomos,
    exact: true,
  },
  {
    path: '/servicos',
    component: Servicos,
    exact: true,
  },
  {
    path: ['/lista-de-especialidades', '/especialidades'],
    component: GuiaEspecialidades,
    exact: true,
  },
  {
    path: ['/blog', '/blog/rh', '/blog/saude-e-bem-estar'],
    component: Blog,
    exact: true,
  },
  {
    path: [
      '/politica-de-privacidade',
      '/politica-de-privacidade-ommbe-empresas',
    ],
    component: PoliticaPrivacidade,
    exact: true,
  },
  {
    path: ['/termos-de-uso', '/termos-de-uso-ommbe-empresas'],
    component: TermosUso,
    exact: true,
  },
  {
    path: ['/entrar', '/especialistas/entrar', '/empresas/entrar'],
    component: Entrar,
    exact: true,
  },
  {
    path: ['/cadastro-especialistas', '/especialistas/cadastro'],
    component: CadastroEspecialistas,
    exact: true,
  },
  {
    path: '/especialistas/confirmar-atendimento/:idEspecialista/:chave',
    component: ConfirmarAtendimento,
    exact: true,
  },
  {
    path: '/especialistas/cancelar-atendimento/:idEspecialista/:chave',
    component: CancelarAtendimento,
    exact: true,
  },
  {
    path: '/especialistas/alternativa-datas/:idEspecialista/:chave',
    component: AlternativaDatas,
    exact: true,
  },
  {
    path: [
      '/recuperar-senha',
      '/especialistas/recuperar-senha',
      '/empresas/recuperar-senha',
    ],
    component: RecuperarSenha,
    exact: true,
  },
  {
    path: '/resetar-senha/:id/:codigo',
    component: ResetarSenha,
    exact: true,
  },
  {
    path: [
      '/especialistas/visao-geral',
      '/especialistas/minha-agenda',
      '/especialistas/minha-agenda/:aba',
      '/especialistas/historico-atendimentos',
      '/especialistas/historico-atendimentos/:aba',
      '/especialistas/anotacoes-clientes',
      '/especialistas/anotacoes-clientes/:aba',
      '/especialistas/campanhas',
      '/especialistas/campanhas/:aba',
      '/especialistas/meus-artigos',
      '/especialistas/minhas-conversas',
      '/especialistas/minhas-conversas/:aba',
      '/especialistas/perfil',
      '/especialistas/configuracoes',
      '/especialistas/perfil/:aba',
      '/especialistas/especialidades-adicionadas',
      '/especialistas/especialidades-adicionadas/:aba',
      '/especialistas/ajuda',
      '/especialistas/notificacoes',
      '/especialistas/logs-alteracoes',
    ],
    component: DashEspecialistas,
    exact: true,
  },
  {
    path: [
      '/empresas/visao-geral',
      '/empresas/colaboradores',
      '/empresas/colaboradores/:aba',
      '/empresas/atendimentos-agendados',
      '/empresas/historico-atendimentos',
      '/empresas/graficos',
      '/empresas/rankings',
      '/empresas/rankings/:aba',
      '/empresas/administrador',
      '/empresas/administrador/:aba',
      '/empresas/perfil',
      '/empresas/perfil/:aba',
      '/empresas/configuracoes',
      '/empresas/notificacoes',
      '/empresas/ajuda',
    ],
    component: DashEmpresas,
    exact: true,
  },
  {
    path: ['/painel', '/painel/entrar'],
    component: EntrarAdmin,
    exact: true,
  },
  {
    path: [
      '/painel/visao-geral',
      '/painel/empresas',
      '/painel/empresas/:aba',
      '/painel/usuarios',
      '/painel/usuarios/:aba',
      '/painel/especialistas',
      '/painel/especialistas/:aba',
      '/painel/especialidades',
      '/painel/especialidades/:aba',
      '/painel/cuide-se-agora',
      '/painel/cuide-se-agora/:aba',
      '/painel/financeiro',
      '/painel/financeiro/extrato-empresa',
      '/painel/financeiro/usuarios',
      '/painel/financeiro/usuarios/:aba',
      '/painel/atendimentos-agendados',
      '/painel/historico-atendimentos',
      '/painel/historico-atendimentos/:aba',
      '/painel/graficos',
      '/painel/rankings',
      '/painel/rankings/:aba',
      '/painel/campanhas',
      '/painel/campanhas/:aba',
      '/painel/cupons',
      '/painel/cupons/:aba',
      '/painel/recrutadores',
      '/painel/recrutadores/:aba',
      '/painel/artigos',
      '/painel/mensagens',
      '/painel/administrador',
      '/painel/administrador/:aba',
      '/painel/perfil',
      '/painel/perfil/:aba',
    ],
    component: DashAdmin,
    exact: true,
  },
  {
    path: '/especialistas/fotos/:especialista',
    component: FotoEspecialista,
    exact: true,
  },
  {
    path: ['/especialistas', '/especialistas/:id'],
    component: Especialistas,
    exact: true,
  },
  /*{
        path: "/teste",
        component: Teste,
        exact: true
    }*/
];

export default rotasConfig;
