import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";

import { modificaEmailLogin, modificaSenhaLogin, autenticaConta, modificaTipoUsuario } from "../../acoes/Auth";
import { modificaTipoCampoSenha } from "../../acoes/AuxiliaresAcoes";

import Botao from "../../global/BotaoAcao";
import ControlaVisibilidadeSenha from "../../global/forms/ControlaVisibilidadeSenha";

class FormAcessoUsuario extends Component {

  componentDidMount() {

    this.props.modificaTipoCampoSenha("password");
    this.props.modificaTipoUsuario("");
  }

  avisoAcesso = () => {

    if(!this.props.loading) {

      if(this.props.usuarioSemCadastro)
        return(<p className="texto-area" style={{ fontSize: 16 }}>Is your account confirmed? Chat with us</p>)

      if(this.props.tipoUsuario === "colaborador")
        return(<p className="texto-area" style={{ fontSize: 16 }}>To access your account, you need to download the OMM BE Health app</p>)
    }
    
    return null;
  }

  exibeLinkRecuperarSenha = () => {

    if(!this.props.loading && !this.props.usuarioLogado)
      return(<Link to="/recuperar-senha">Forgot my password</Link>)

    return null;
  }

  acaoForm = e => {

    e.preventDefault();

    let { email, senha } = this.props;

    this.props.autenticaConta({ email, senha });
  }

  render() {

    return(
      <form className="form-dados" id="form-acesso-usuario" onSubmit={this.acaoForm}>

        <div className="topo-form" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>

          <h4 className="titulo-area" style={{ marginBottom: 5 }}>Or access your account</h4>
        </div>

        <div className="corpo-form" style={{ marginBottom: 35 }}>

          <div className="input-group">

            <label>Email</label>

            <input
              type="email"
              className="input-field"
              required
              value={this.props.email}
              onChange={e => this.props.modificaEmailLogin(e.target.value)}
            />

          </div>

          <div className="input-group" style={{ marginBottom: "5px" }}>

            <label>Password</label>

            <input
              type={this.props.tipoCampoSenha}
              className="input-field"
              required
              maxLength={12}
              value={this.props.senha}
              onChange={e => this.props.modificaSenhaLogin(e.target.value)}
            />

            <ControlaVisibilidadeSenha />

          </div>

          <Botao textoBotao="Log In" classeBotao="botao-acao" />

          { this.avisoAcesso() }

        </div>

        { this.exibeLinkRecuperarSenha() }

      </form>
    )
  }
}

const mapStateToProps = state => (
	{
		loading: state.auxiliaresReducer.loading,
    email: state.authReducer.emailLogin,
		senha: state.authReducer.senhaLogin,
		usuarioSemCadastro: state.authReducer.usuarioSemCadastro,
		tipoUsuario: state.authReducer.tipoUsuario,
    tipoCampoSenha: state.auxiliaresReducer.tipoCampoSenha
	}
)

export default connect(mapStateToProps, {
  modificaTipoCampoSenha,
  modificaEmailLogin,
  modificaSenhaLogin,
  autenticaConta,
  modificaTipoUsuario })(FormAcessoUsuario)
