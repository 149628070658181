import React, { Component } from "react";
import { 
  EmailShareButton, EmailIcon,
  FacebookShareButton, FacebookIcon, 
  LinkedinShareButton, LinkedinIcon, 
  TelegramShareButton, TelegramIcon, 
  TwitterShareButton, TwitterIcon, 
  WhatsappShareButton, WhatsappIcon 
} from "react-share";

export default class Compartilhar extends Component { 

	render() {

		const { url, titulo } = this.props;

		return(
			<div style={{ marginTop: 25 }}>
              
              <p className="texto-area" style={{ paddingBottom: 0, marginBottom: 12 }}>Gostou do artigo?<br />Compartilhe com seus amigos!</p>

              <EmailShareButton 
                style={{ margin: "0 10px" }}
                url={url}
                subject={titulo}
              >
                <EmailIcon size={48} round />
              </EmailShareButton>

              <FacebookShareButton 
                style={{ marginRight: 10 }}
                url={url}
                quote={titulo}
              >
                <FacebookIcon size={48} round />
              </FacebookShareButton>

              <LinkedinShareButton 
                style={{ marginRight: 10 }}
                url={url}
                title={titulo}
              >
                <LinkedinIcon size={48} round />
              </LinkedinShareButton>

              <TelegramShareButton 
                style={{ marginRight: 10 }}
                url={url}
                title={titulo}
              >
                <TelegramIcon size={48} round />
              </TelegramShareButton>

              <TwitterShareButton 
                style={{ marginRight: 10 }}
                url={url}
                title={titulo}
              >
                <TwitterIcon size={48} round />
              </TwitterShareButton>

              <WhatsappShareButton 
                style={{ marginRight: 10 }}
                url={url}
                title={titulo}
              >
                <WhatsappIcon size={48} round />
              </WhatsappShareButton>

            </div>
		)
	}
}