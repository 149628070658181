import { reduxConstants } from '../constants';

const userInitialState = {
  consentedPrivacyPolicy: false,
  privacyPolicyConsentData: {
    key: '',
    date: '',
  },
};

export const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case reduxConstants.MODIFY_CONSENT_PRIVACY_POLICY:
      return { ...state, consentedPrivacyPolicy: action.payload };

    case reduxConstants.MODIFY_PRIVACY_POLICY_CONSENT_DATA:
      return { ...state, privacyPolicyConsentData: action.payload };

    default:
      return state;
  }
};
