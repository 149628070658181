const initState = {
  carregandoTotaisEspecialista: false,
  totaisEspecialista: {
    totalAgendados: 0,
    totalRealizados: 0,
    totalClientes: 0,
    totalGanhos: 0
  },
  carregandoUltimasConversas: false,
  ultimasConversas: [],
  carregandoProximosConfirmados: false,
  proximosAtendimentosConfirmados: [],
  carregandoUltimasCampanhas: false,
  ultimasCampanhas: [],
  carregandoUltimasAnotacoes: false,
  ultimasAnotacoes: []
}

const visaoGeralEspecialistasReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_totais_especialista":
      return { ...state, carregandoTotaisEspecialista: action.payload }

    case "modifica_totais_especialista":
      return { 
        ...state, 
        totaisEspecialista: action.payload, 
        carregandoTotaisEspecialista: false 
      }

    case "modifica_carregando_ultimas_conversas":
      return { ...state, carregandoUltimasConversas: action.payload }

    case "modifica_ultimas_conversas":
      return { ...state, ultimasConversas: action.payload, carregandoUltimasConversas: false }

    case "modifica_carregando_proximos_confirmados":
      return { ...state, carregandoProximosConfirmados: action.payload }

    case "modifica_proximos_atendimentos_confirmados":
      return { 
        ...state, 
        proximosAtendimentosConfirmados: action.payload, 
        carregandoProximosConfirmados: false 
      }

    case "modifica_carregando_ultimas_campanhas":
      return { ...state, carregandoUltimasCampanhas: action.payload }  

    case "atualiza_ultimas_campanhas":
      return { 
        ...state, 
        ultimasCampanhas: action.payload,
        carregandoUltimasCampanhas: false 
      }  

    case "modifica_carregando_ultimas_anotacoes":
      return { ...state, carregandoUltimasAnotacoes: action.payload }  

    case "atualiza_ultimas_anotacoes":
      return { 
        ...state, 
        ultimasAnotacoes: action.payload,
        carregandoUltimasAnotacoes: false 
      }    

    default:
      return state
  }
}

export default visaoGeralEspecialistasReducer;
