import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import "../../css/rodape.css";

import logo from "../../imagens/logo-ommbe.png";
import iconeLinkedin from "../../imagens/icone-linkedin.png";
import iconeInsta from "../../imagens/icone-instagram.png";
import iconeFace from "../../imagens/icone-facebook.png";
import iconeYoutube from "../../imagens/icone-youtube.png";
import disponivelPlayStore from "../../imagens/disponivel-google-play.webp";
import disponivelPlayStorePng from "../../imagens/disponivel-google-play.png";
import disponivelPlayStoreSvg from "../../imagens/available-play-store.svg";
import disponivelAppStore from "../../imagens/disponivel-app-store.webp";
import disponivelAppStorePng from "../../imagens/disponivel-app-store.png";
import disponivelAppStoreSvg from "../../imagens/available-app-store.svg";

export default class Rodape extends Component {

  exibeLinkSocial = (uri, icone, nomeApp) => <li><Link to={{ pathname: uri }} target="_blank"><img className="icone-social" src={icone} alt={nomeApp} width="16" height="16" /></Link></li>

  render() {

    return (
      <div className="footer">

        <div className="conteudo-footer">

          <div>

            <Link to="/"><img id="logo-rodape" className="logo-menu" src={logo} alt="OMM BE" width="180" height="42" /></Link>

          </div>

          <ul className="menu-links">

            <li>
              <ul className="coluna-links">

                <li><Link to="/quem-somos">Who we are</Link></li>
                <li><Link to="/servicos">Services</Link></li>
                <li><Link to="/lista-de-especialidades">Specialties</Link></li>

              </ul>
            </li>

            <li>
              <ul className="coluna-links">

                <li><Link to="/especialistas">Specialists</Link></li>
                <li><Link to="/blog" >Blog</Link></li>
                <li><Link to="/entrar">Login</Link></li>

              </ul>
            </li>

          </ul>

          <div>

            <ul className="social-links">

              {this.exibeLinkSocial("https://www.linkedin.com/company/omm-be/", iconeLinkedin, "Linkedin")}
              {this.exibeLinkSocial("https://www.instagram.com/ommbesaude/", iconeInsta, "Instagram")}
              {this.exibeLinkSocial("https://www.facebook.com/ommbe.bemestar", iconeFace, "Facebook")}
              {this.exibeLinkSocial("https://www.youtube.com/channel/UCjCfLYkufqga0zJdEvFWLPg", iconeYoutube, "Youtube")}

            </ul>

            <div className="porta-botoes-download-app-rodape">

              <Link to={{ pathname: "https://play.google.com/store/apps/details?id=com.ommbe.saude" }} target="_blank" className="botao-download-app">

                <picture className="img-botao-download-app">
                  {/* <source srcSet={disponivelPlayStore} type="image/webp" /> */}
                  {/* <source srcSet={disponivelPlayStorePng} type="image/png" /> */}
                  {/* <source srcSet={disponivelPlayStoreSvg} type="image/svg" /> */}
                  <img src={disponivelPlayStoreSvg} alt="Button to download our app from the Play Store" width="200" height="59" />
                </picture>

              </Link>

              <Link to={{ pathname: "https://apps.apple.com/us/app/omm-be-sa%C3%BAde/id1629535580?platform=iphone" }} target="_blank" className="botao-download-app">

                <picture className="img-botao-download-app">
                  {/* <source srcSet={disponivelAppStore} type="image/webp" /> */}
                  {/* <source srcSet={disponivelAppStorePng} type="image/png" /> */}
                  {/* <source srcSet={disponivelAppStoreSvg} type="image/svg" /> */}
                  <img src={disponivelAppStoreSvg} alt="Button to download our app from the App Store" width="200" height="59" />
                </picture>

              </Link>

            </div>

          </div>

        </div>

        <ul id="linha-direitos">

          <li>{moment().format("YYYY")} OMM BE Saúde e Bem Estar - All rights reserved</li>

        </ul>

      </div>
    )
  }
}
