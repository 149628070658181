const initState = {
	modalFinanceiroEmpresasAberto: false,
  	deslocamentoModalFinanceiroEmpresas: 'tranlateX(320px)',
  	idEmpresaSelecionada: "",
  	modalFinanceiroUsuariosAberto: false,
  	deslocamentoModalFinanceiroUsuarios: 'tranlateX(320px)',
  	idUsuarioSelecionado: '',
  	atualizarTabelaFinanceiro: false,
  	extratoEmpresa: [],
  	extratoUsuario: [],
  	modalReduzCreditosUsuariosAberto: false,
  	deslocamentoModalReduzCreditosUsuarios: 'tranlateX(320px)'
}

const financeiroReducer = (state = initState, action) => {

	switch(action.type) {

		case "modifica_deslocamento_modal_financeiro_empresas":
	     	return { ...state, deslocamentoModalFinanceiroEmpresas: action.payload }  

	    case "modifica_modal_financeiro_empresas_aberto":
	     	return { ...state, modalFinanceiroEmpresasAberto: action.payload }    

	    case "atualiza_id_empresa_selecionada":
	    	return { ...state, idEmpresaSelecionada: action.payload }	  

	    case "modifica_deslocamento_modal_financeiro_usuarios":
	     	return { ...state, deslocamentoModalFinanceiroUsuarios: action.payload }  

	    case "modifica_modal_financeiro_usuarios_aberto":
	      	return { ...state, modalFinanceiroUsuariosAberto: action.payload }    

	    case "atualiza_id_usuario_selecionado":
	    	return { ...state, idUsuarioSelecionado: action.payload }  	

		case "atualiza_tabela_financeiro":
			return { ...state, atualizarTabelaFinanceiro: action.payload }	

		case "atualiza_extrato_empresa":
			return { ...state, extratoEmpresa: action.payload }	

		case "atualiza_extrato_usuario":
			return { ...state, extratoUsuario: action.payload }		

		case "modifica_deslocamento_modal_reduz_creditos_usuarios":
	     	return { ...state, deslocamentoModalReduzCreditosUsuarios: action.payload }  
	     	
	    case "modifica_modal_reduz_creditos_usuarios_aberto":
	      	return { ...state, modalReduzCreditosUsuariosAberto: action.payload }  	

		default:
			return state;
	}
}

export default financeiroReducer;