import React from "react";
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';

import iconeInfo from "../../imagens/cadastro/info-branco.png";

import { FORMATA_NOME_MINUSCULA } from "../../acoes/FuncoesGerais";
import { selecionaLocaisEspecialidade } from "../../acoes/especialidades/GerenciaDadosEspecialidades";

const OpcoesLocaisAtendimento = props => {

	const especialidadesNaoIncluidas = ["cuidador-de-idosos", "enfermagem", "odontologia"];

	const atualizaLocal = (idBotao, local) => {

	    document.querySelector(`#${idBotao}`).classList.toggle("ativo");

	    let locais = props.locaisSelecionados,
	        posicao = locais.indexOf(local);

	    if(posicao < 0)
	      locais.push(local);
	    else
	      locais.splice(posicao, 1);

	  	props.selecionaLocaisEspecialidade(locais);
	}

	if(props.especialidade) {

		let idEspecialidade = props.especialidade.value;

		if(!especialidadesNaoIncluidas.includes(idEspecialidade)) {

			let locais = ["Clínica própria", "Empresa", "Online", "Residência"];

			props.dadosEspecialidades.every(item => {

				if(item.tipo === "Bem estar" && item.id === idEspecialidade) {

					locais = item.localAtendimento

					return false;
				}	

				return true;
			})

			return(
	          <div className="input-group" style={{ width: "100%", textAlign: "left", marginBottom: 20 }}>

	            <p className="texto-area" style={{ color: "#6e6e6e", marginBottom: -5, marginLeft: 5 }}>Selecione os locais de atendimento</p>

	            <span className="obs" style={{ marginLeft: 5 }}>(Mais de um pode ser selecionado)</span>

	            <div
	              className="botao-info"
	              style={{ top: "8px" }}
	              onClick={() => {

	                confirmAlert({
          				title: 'Aviso',
          				message: 'Atendimentos realizados na residência do cliente terão um acréscimo de 20% no valor total',
          				buttons: [
          					{
          						label: 'OK',
          						onClick: () => false
          					}
          				]
          			});
	              }}
	            >
	              <img src={iconeInfo} alt="i" />
	            </div>

	            <div className="porta-opcoes" style={{ marginTop: 10 }}>

	              {
	              	locais.map(item => {

	              		let textoOpcao = item;

	              		if(item === "Empresa")
	              			textoOpcao = "Empresa do cliente";

	              		if(item === "Residência")
	              			textoOpcao = "Residência do cliente";

	              		let idBotao = `opcao-${FORMATA_NOME_MINUSCULA(item)}`;

	              		return(
					      <div
					        className="opcao-selecao opcao-tipo-local"
					        id={idBotao}
					        onClick={() => atualizaLocal(idBotao, item)}
					      >
					        <p className="texto-area">{textoOpcao}</p>
					      </div>
					    )
	              	})
	              }

	            </div>

	          </div>
	        )
		}
	}

	return null;
}

const mapStateToProps = state => ({
	dadosEspecialidades: state.especialidadesReducer.dadosEspecialidades,
	especialidade: state.especialidadesReducer.especialidadeSelecionada,
	locaisSelecionados: state.especialidadesReducer.locaisSelecionadosEspecialidade
})

export default connect(mapStateToProps, { selecionaLocaisEspecialidade })(OpcoesLocaisAtendimento)