import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import '../../css/blog.css';

import MenuMobile from '../../global/nav/menuMobile/MenuMobile';
import MenuTopo from '../../global/nav/menuTopo/MenuTopo';
import AvisoPoliticaPrivacidade from "../../global/AvisoPoliticaPrivacidade";
import Rodape from "../../global/rodape/Rodape";
import ErroProcessamento from '../erros/erroProcessamento';
import CarregandoArtigo from "../../componentes/blog/secoesArtigo/CarregandoArtigo";
import Compartilhar from "../../componentes/blog/secoesArtigo/Compartilhar";
import DetalhesArtigo from "../../componentes/blog/secoesArtigo/DetalhesArtigo";

import { obtemConteudoPostagem } from "../../acoes/LeituraDadosBlog";
import { modificaReprocessarAcao } from "../../acoes/DashboardAcoes";

class Artigo extends Component {

  constructor() {

    super();

    this.state = { url: "" }
  }

  componentDidMount() {

    window.scroll({ top: 0, behavior: 'auto' });

    let url = window.location.href;

    this.props.obtemConteudoPostagem(url); 

    this.setState({ url });  
  }

  componentDidUpdate() {

    if(this.props.reprocessarAcao) {

      this.props.modificaReprocessarAcao(false);

      this.props.obtemConteudoPostagem(this.state.url);
    }
  }

  exibeCapaArtigo = () => {

    let { imgDestaque } = this.props.postagem;

    if(imgDestaque) 
      return(
        <div className="capa-artigo">
          <img src={imgDestaque} alt="" />
        </div>
      )

    return null;
  }

  exibeArtigo = () => {

    if(this.props.carregandoPostagem)
      return(<CarregandoArtigo />)

    if(this.props.houveErro)
        return <ErroProcessamento />

    if(this.props.postagem) {

      let { url } = this.state,
          { titulo, autor, data, tempoLeitura, conteudo } = this.props.postagem,
          imgPerfil = "",
          nome = "Redação OMM BE";

      if(autor) {

        imgPerfil = autor.imgPerfil;
        nome = autor.nome;
      }    

      return(
        <div className="porta-artigo">

          { this.exibeCapaArtigo() }

          <div className="corpo-artigo">

            <div className="cabecalho-artigo">

              <h2 className="subtitulo-area">{ titulo }</h2>

              <DetalhesArtigo dados={{ imgPerfil, nome, data, tempoLeitura }} />

            </div>

            { ReactHtmlParser(conteudo) }

            <Compartilhar titulo={titulo} url={url} />

          </div>

        </div>
      )      
    }

    return null;
  }

  render() {

    return (
        <div className="artigo">

          <MenuMobile />

          <MenuTopo />

          { this.exibeArtigo() }

          <Rodape />

          <AvisoPoliticaPrivacidade />

        </div>
    );
  }
}

const mapStateToProps = state => (
	{
    carregandoPostagem: state.blogReducer.carregandoPostagem,
    postagem: state.blogReducer.postagem,
    houveErro: state.dashboardReducer.houveErroInicializacao,
    reprocessarAcao: state.dashboardReducer.reprocessarAcao
	}
)

export default connect(mapStateToProps, { obtemConteudoPostagem, modificaReprocessarAcao })(Artigo)
