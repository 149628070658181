import moment from "moment";

export const MENSAGEM_ERRO = erro => {

  let { code, message } = erro;

  if(message.match(/network/g))
    return "Erro ao acessar o servidor. Por favor, Verifique sua conexão"; 

  if(message.match(/This operation is sensitive/g))
    return "Erro ao atualizar sua senha. Por favor, saia da conta e tente novamente";

  if(message.match(/The email address is already in use/g))
    return "Já há uma conta cadastrada com esse e-mail";

  if(code === 'auth/wrong-password')
    return "Erro no acesso! Por favor, verifique sua senha"; 

  return "Erro ao acessar a base de dados. Por favor, tente novamente";
}

/*************************
ordernar matrizes
*************************/
// ordem alfabética
export const ORDENA_NOMES = lista => {

  lista.sort((a, b) => {

    let fa = alteraLetras(a.nome),
        fb = alteraLetras(b.nome);

    if(fa < fb) return -1;

    if(fa > fb) return 1;

    return 0;
  });
}

export const ORDENA_DATAS = (lista, tipoOrdenacao, tipoVariaveis) => {

  function defineHorarioInicial(horario) {

    let hora, minutos;

    if(horario.match(/Entre/g)) {

      let blocoHorario = horario.split(" e ");

      switch(blocoHorario[0]) {

        case "Entre 0h":
          hora = 0;
          minutos = "00";
          break;

        case "Entre 2:30": 
          hora = 2;
          minutos = 30;
          break;

        default:
          hora = Number(blocoHorario[0].replace("Entre ", ""));
          minutos = "00";   
      }   

    } else {

      let blocoHorario = horario.split(":");

      hora = Number(blocoHorario[0]);
      minutos = blocoHorario[1];
    }

    if(hora < 10)
      hora = "0" + hora;

    return `${hora}:${minutos}`;
  }

  lista.sort((a,b) => {

    let dataA, dataB;

		switch (tipoVariaveis) {

			case "dataAtendimento":

        a.hora = defineHorarioInicial(a.horarioAtendimento);
        b.hora = defineHorarioInicial(b.horarioAtendimento);

				dataA = `${a.dataAtendimento}T${a.hora}`;
				dataB = `${b.dataAtendimento}T${b.hora}`;
				break;

			case "hora":
				dataA = `${a.data}T${a.hora}`;
				dataB = `${b.data}T${b.hora}`;
				break;

			default:
				dataA = a[tipoVariaveis];
				dataB = b[tipoVariaveis];
		}

		dataA = new Date(dataA);
		dataB = new Date(dataB);

    if(tipoOrdenacao === 'crescente')  {

      if(dataA > dataB) return 1;
      if(dataA < dataB) return -1;

    } else {

      if(dataA > dataB) return -1;
      if(dataA < dataB) return 1;
    }

    return 0;
  })
};

// organiza da maior quantidade para a menor
export const ORDENA_VOLUMES = (lista, prop) => {

  lista.sort((a, b) => {

    let fa = a[prop],
        fb = b[prop];

    if(fa < fb) return 1;

    if(fa > fb) return -1;

    return 0;
  });
}

/***********************
formatações
************************/
const alteraLetras = nome => {

  if(nome && nome !== "") {

    nome = nome.toLowerCase();

    nome = nome.replace(/[àáâã]/g, "a");
    nome = nome.replace(/[éêë]/g, "e");
    nome = nome.replace(/[í]/g, "i");
    nome = nome.replace(/[óôõ]/g, "o");
    nome = nome.replace(/[úü]/g, "u");
    nome = nome.replace(/[ç]/g, "c");
  }

  return nome;
}

// adapta para o sistema decimal que usa vírgula ao invés de ponto
export const FORMATA_DECIMAL_PARA_VIRGULA = numero => {

	numero = numero.toFixed(1);
  return numero.replace(".", ",");
}

export const FORMATA_NOME_MINUSCULA = nome => {

  nome = nome.replace(/\s/g, "");

  nome = alteraLetras(nome);

  return nome.toLowerCase();
}

export const FORMATA_NUMERO_COM_PONTOS = numero => {

  if(numero) {

    let blocoValor = numero.toString().split("");

    blocoValor = blocoValor.reverse();

    let valorInvertido = "",
        qtdCaracteresIntervalo = 0;

    blocoValor.forEach((item, i) => {

      valorInvertido += item;

      qtdCaracteresIntervalo++;

      if(qtdCaracteresIntervalo === 3 && i < blocoValor.length - 1) {

        qtdCaracteresIntervalo = 0;
        valorInvertido += ".";
      }
    });

    let valorFormatado = valorInvertido.split("");

    return valorFormatado.reverse().join("");
  }
  
  return numero;
}

export const VALIDAR_EMAIL = email => {

  const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

/*************************
para gráficos
*************************/
// define qual será a posição na matriz FaixasEtarias conforme a data de nascimento
export const VERIFICA_IDADE = data => {

  let idade = moment().diff(data, 'years', false);

  if(idade >= 60) return "Mais de 60 anos";
  if(idade >= 50 && idade <= 59) return "De 50 a 59 anos";
  if(idade >= 40 && idade <= 49) return "De 40 a 49 anos";
  if(idade >= 30 && idade <= 39) return "De 30 a 39 anos";

  return "Abaixo de 30 anos";
}

export const MONTA_DADOS_GRAFICO_TORTA = (dados, total) => {

  // primeiro organiza os dados em uma matriz para ordenar as chaves de forma alfabética
  let dadosOrdenados = [];

  for(let item in dados) 
    dadosOrdenados.push({ nome: item, volume: dados[item].volume })
  
  if(dadosOrdenados.length > 6) {

    ORDENA_VOLUMES(dadosOrdenados, "volume");

    let outros = { nome: "Outros", volume: 0 }

    for(let i = 5; i < dadosOrdenados.length; i++) 
      outros.volume += dadosOrdenados[i].volume;

    dadosOrdenados.splice(5, dadosOrdenados.length - 5)

    ORDENA_NOMES(dadosOrdenados);

    dadosOrdenados.push(outros);

  } else {
    ORDENA_NOMES(dadosOrdenados);
  }

  let dadosGrafico = { volumes: [], legendas: [] };

  dadosOrdenados.forEach(item => {

    let percentual = (item.volume/total) * 100;

    dadosGrafico.volumes.push({
      x: item.nome,
      y: item.volume,
      label: `${FORMATA_DECIMAL_PARA_VIRGULA(percentual)}%`
    });

    dadosGrafico.legendas.push({ name: item.nome });
  });

  return dadosGrafico;
}
