const initState = {
  carregandoAtendimentos: false,
  agendados: [],
  atualizaTabelaAgendadosAdmin: false,
  historico: [],
  dadosHistorico: [],
  dadosHistoricoCarregado: false,
  dadosGraficos: {
    atendimentosTitularesBeneficiarios: {
      titulares: 0,
      beneficiarios: 0
    },
    empresas: {},
    unidades: {},
    especialidades: {},
    faixasEtarias: {},
    cidades: {},
    intencaoTratamento: {},
    volumesPeriodo: {},
    creditosPeriodo: {},
    total: 0
  },
  dadosRankings: {
    usuarios: {},
    especialidades: {},
    faixasEtarias: {
      "Mais de 60 anos": { volume: 0, creditos: 0 },
      "De 50 a 59 anos": { volume: 0, creditos: 0 },
      "De 40 a 49 anos": { volume: 0, creditos: 0 },
      "De 30 a 39 anos": { volume: 0, creditos: 0 },
      "Abaixo de 30 anos": { volume: 0, creditos: 0 },
    },
    empresas: {},
    cidades: {},
    locaisAtendimentos: {}
  },
  filtrosRankings: {
    inicio: "",
    final: ""
  },
  atendimentosCuideSeAgora: [],
  atualizaTabelaAtendimentosCuideSeAgora: false,
  modalAbertoAtendimentoCuideSeAgora: false,
  deslocamentoModalAtendimentoCuideSeAgora: 'tranlateX(320px)',
  atualizaModalAtendimentosCuideSeAgora: false,
  idClienteCuideSeAgora: '',
  chaveAtendimentoCuideSeAgora: '',
  listaEspecialistasAtendimentosCuideSeAgora: [],
  listaEspecialidadesCuideSeAgora: [],
  especialidadeCuideSeAgora: null,
  statusCampoEspecialidadeCuideSeAgora: false,
  textoBotaoFormCuideSeAgora: "Adicionar",
  whatsCuideSeAgora: "",
  valorAtendimentoCuideSeAgora: "",
  atualizarTabelaEspecialidadesCuideSeAgora: false,
  modalAbertoDetalhesAtendimento: false,
  deslocamentoModalDetalhesAtendimento: 'tranlateX(320px)',
  dadosDetalhesAtendimento: ''
}

const atendimentosAdminReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_atendimentos_admin":
      return { ...state, carregandoAtendimentos: action.payload }

    case "atualiza_agendados_admin":
      return { 
        ...state, 
        agendados: action.payload, 
        carregandoAtendimentos: false,
        atualizaTabelaAgendadosAdmin: true 
      }

    case "atualiza_tabela_agendados_admin":
      return { ...state, atualizaTabelaAgendadosAdmin: action.payload }

    case "atualiza_historico_atendimentos_admin":
      return { 
        ...state, 
        historico: action.payload, 
        carregandoAtendimentos: false
      }  

    case "atualiza_dados_historico_admin":
      return { ...state, dadosHistorico: action.payload, dadosHistoricoCarregado: true }

    case "modifica_dados_historico_carregado":
      return { ...state, dadosHistoricoCarregado: action.payload }  

    case "atualiza_graficos_admin": 
      return { ...state, dadosGraficos: action.payload, carregandoAtendimentos: false }

    case "atualiza_rankings_admin": 
      return { ...state, dadosRankings: action.payload, carregandoAtendimentos: false }  

    case "atualiza_filtros_rankings":
      return { ...state, filtrosRankings: action.payload }  

    case "atualiza_lista_atendimentos_cuide_se_agora":
      return { 
        ...state, 
        atendimentosCuideSeAgora: action.payload, 
        carregandoAtendimentos: false,
        atualizaTabelaAtendimentosCuideSeAgora: true 
      }  

    case "atualiza_tabela_atendimentos_cuide_se_agora":
      return { ...state, atualizaTabelaAtendimentosCuideSeAgora: action.payload } 

    case "modifica_deslocamento_modal_atendimentos_cuide_se_agora":
      return { ...state, deslocamentoModalAtendimentoCuideSeAgora: action.payload }  

    case "modifica_modal_aberto_atendimentos_cuide_se_agora":
      return { ...state, modalAbertoAtendimentoCuideSeAgora: action.payload }    

    case "atualiza_modal_atendimentos_cuide_se_agora":
      return { ...state, atualizaModalAtendimentosCuideSeAgora: action.payload }   
    case "modifica_dados_atendimento_cuide_se_agora":
      return { 
        ...state, 
        idClienteCuideSeAgora: action.payload["id"],
        chaveAtendimentoCuideSeAgora: action.payload["chave"],
        listaEspecialistasAtendimentosCuideSeAgora: action.payload["lista"],
        atualizaModalAtendimentosCuideSeAgora: true 
      }  

    case "atualiza_lista_especialidades_cuide_se_agora":
      return { 
        ...state, 
        listaEspecialidadesCuideSeAgora: action.payload, 
        carregandoAtendimentos: false,
        atualizarTabelaEspecialidadesCuideSeAgora: true 
      }    

    case "modifica_especialidade_cuide_se_agora":
      return { ...state, especialidadeCuideSeAgora: action.payload }   

    case "modifica_whats_cuide_se_agora":
      return { ...state, whatsCuideSeAgora: action.payload }    

    case "modifica_valor_atendimento_cuide_se_agora":
      return { ...state, valorAtendimentoCuideSeAgora: action.payload }    

    case "modifica_status_campo_especialidade_cuide_se_agora":
      return { 
        ...state, 
        statusCampoEspecialidadeCuideSeAgora: action.payload["status"],
        textoBotaoFormCuideSeAgora: action.payload["texto"] 
      }    

    case "atualizar_tabela_especialidades_cuide_se_agora":
      return { ...state, atualizarTabelaEspecialidadesCuideSeAgora: action.payload }  

    case "reseta_form_gerencia_especialidade":
      return { 
        ...state, 
        especialidadeCuideSeAgora: null,
        statusCampoEspecialidadeCuideSeAgora: false,
        textoBotaoFormCuideSeAgora: "Adicionar",
        whatsCuideSeAgora: "",
        valorAtendimentoCuideSeAgora: "",
      }
  
    case "modifica_deslocamento_detalhes_atendimentos":
      return { ...state, deslocamentoModalDetalhesAtendimento: action.payload }  

    case "modifica_modal_aberto_detalhes_atendimentos":
      return { ...state, modalAbertoDetalhesAtendimento: action.payload }    

    case "modifica_dados_detalhes_atendimento":
      return { ...state, dadosDetalhesAtendimento: action.payload }  

    default:
      return state
  }
}

export default atendimentosAdminReducer;
