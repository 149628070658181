const initState = {
  carregandoImgsEspecialidades: false,
  carregandoDadosEspecialidades: false,
  dadosEspecialidades: [],
  listaEspecialidadesAtualizada: false,
  intencoesCadastradas: [],
  editarEspecialidadeComId: "",
  carregandoDadosIntencoes: false,
  dadosIntencoes: [],
  especialidadeSelecionada: '',
  selectEspecialidadesDesabilitado: false,
  locaisSelecionadosEspecialidade: []
}

const especialidadesReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_imgs_especialidades":
      return { ...state, carregandoImgsEspecialidades: action.payload }

    case "modifica_carregando_dados_especialidades":
      return { ...state, carregandoDadosEspecialidades: action.payload }

    case "modifica_dados_especialidades":
      return { 
        ...state, 
        dadosEspecialidades: action.payload, 
        carregandoDadosEspecialidades: false,
        listaEspecialidadesAtualizada: true 
      }

    case "atualiza_lista_especialidades":
      return { ...state, listaEspecialidadesAtualizada: action.payload }  

    case "atualiza_intencoes_cadastradas": 
      return { ...state, intencoesCadastradas: action.payload }  

    case "modifica_editar_especialidade_com_id":
      return { ...state, editarEspecialidadeComId: action.payload }

    case "modifica_carregando_dados_intencoes":
      return { ...state, carregandoDadosIntencoes: action.payload }

    case "modifica_dados_intencoes":
      return { ...state, dadosIntencoes: action.payload, carregandoDadosIntencoes: false }

    case "seleciona_especialidade":
      return { ...state, especialidadeSelecionada: action.payload }

    case "atualiza_status_select_especialidades":
      return { ...state, selectEspecialidadesDesabilitado: action.payload }  

    case "seleciona_locais_especialidade":
      return { ...state, locaisSelecionadosEspecialidade: action.payload }  

    default:
      return state
  }
}

export default especialidadesReducer;
