import loadable from '@loadable/component';
import { Spinner } from 'react-activity';
import './styles.css';

export const screenLoader = (screen) =>
  loadable(() => import(`@screens/${screen}`), {
    fallback: (
      <div id="lazy-loading-screen">
        <Spinner color="#46189a" size={32} />
      </div>
    ),
  });
