import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Lottie from "lottie-react";
import '../../../css/paginas-erros.css';
import { connect } from "react-redux";

import lotus from "../../../imagens/breathing-lotus.json";
import logo from "../../../imagens/logo-ommbe.png";

import { salvaErroApp } from "../../../acoes/AuxiliaresAcoes";

class ErroApp extends Component {

	constructor() {

		super();

		this.state = { temErro: false }
	}

	componentDidCatch(error, errorInfo) {

		if(this.props.usuarioLogado) {

			const { idUsuario, nomeUsuario } = this.props;

			this.props.salvaErroApp({
				idUsuario,
				nomeUsuario,
				erro: error,
				info: errorInfo
			})
		}
  	}

	static getDerivedStateFromError(error) {
	    return { temErro: true };
	}

	render() {

		if(this.state.temErro) {

			return(
				<div id="pagina-erro">

					<div className="menu-topo">

						<img className="logo-menu" src={logo} alt="OMM BE" />

				    </div>

	          		<div id="bloco-nao-encontrada">

						<div id="conteudo-erro">

							<h2 className="subtitulo-area">Página indisponível</h2>

							<p className="texto-area" id="apresenta-animacao">Estamos atualizando a sua conexão.<br />Aproveite esse instante para fechar os olhos e respirar fundo</p>

							<Lottie 
								animationData={lotus}
								loop={true}
								style={{ width: '100%', maxWidth: 400 }}
							/>

							<div className="porta-links">

								<p className="texto-area">Agora clique no botão retornar do seu navegador</p>

								<Link href="#" onClick={e => {

									e.preventDefault();

									window.history.back();

									setTimeout(() => {
										window.location.reload(true);
									}, 50);

								}}>Clique aqui para retornar</Link>

							</div>

						</div>

					</div>

				</div>
			)
		}
	
		return this.props.children; 	
	}
}

const mapStateToProps = state => ({
	usuarioLogado: state.authReducer.usuarioLogado,
	idUsuario: state.authReducer.idUsuario
})

export default connect(mapStateToProps, { salvaErroApp })(ErroApp)
