const initState = {
  rotasPostagensAtualizadas: false,
  rotasPostagens: [],
  carregandoPostagensBlog: false,
  destaquesBlog: Array(3).fill(null),
  artigosSelecionados: Array(3).fill(null),
  postagensBlog: Array(4).fill(null),
  carregandoPostagem: false,
  postagem: {}
}

const blogReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_rotas_postagens_atualizadas":
      return { ...state, rotasPostagensAtualizadas: action.payload }

    case "modifica_rotas_postagens":
      return { ...state, rotasPostagens: action.payload, rotasPostagensAtualizadas: true }

    case "modifica_carregando_postagens_blog":
      return { ...state, carregandoPostagensBlog: action.payload }

    case "modifica_postagens_blog":
      return {
        ...state,
        destaquesBlog: action.payload["destaques"],
        artigosSelecionados: action.payload["artigosSelecionados"],
        postagensBlog: action.payload["postagens"],
        carregandoPostagensBlog: false
      }    

    case "modifica_carregando_postagem":
      return { ...state, carregandoPostagem: action.payload }

    case "modifica_postagem":
      return { ...state, postagem: action.payload, carregandoPostagem: false }    

    default:
      return state
  }
}

export default blogReducer;
