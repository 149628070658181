const initState = {
  carregandoMensagens: false,
  listaMensagensEspecialista: [],
  idClientesMensagens: '',
  nomeClienteMensagens: '',
  urlFotoClienteMensagens: '',
  mensagensCliente: [],
  listaMensagensAdmin: [],
  tabelaMsgsAdminAtualizada: false
}

const mensagensReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_mensagens":
      return { ...state, carregandoMensagens: action.payload }

    case "modifica_lista_mensagens_especialista":
      return { ...state, listaMensagensEspecialista: action.payload, carregandoMensagens: false }

    case "modifica_info_clientes_mensagens":
      return {
        ...state,
        idClienteMensagens: action.payload["id"],
        nomeClienteMensagens: action.payload["nome"],
        urlFotoClienteMensagens: action.payload["urlFoto"]
      }

    case "modifica_mensagens_cliente":
      return { ...state, mensagensCliente: action.payload, carregandoMensagens: false }

    case "atualiza_lista_mensagens_admin":
      return { ...state, listaMensagensAdmin: action.payload, carregandoMensagens: false }

    case "atualiza_tabela_msgs_admin":
      return { ...state, tabelaMsgsAdminAtualizada: action.payload }

    default:
      return state
  }
}

export default mensagensReducer;
