import React, { useState } from "react";
import { connect } from "react-redux";

import { modificaTipoCampoSenha } from "../../acoes/AuxiliaresAcoes";

import iconeSenhaVisivel from "../../imagens/cadastro/senha-visivel.png";
import iconeSenhaNaoVisivel from "../../imagens/cadastro/senha-nao-visivel.png";

const ControlaVisibilidadeSenha = props => {

	const [senhaVisivel, setSenhaVisivel] = useState(false);

	const icone = () => {

	    if(senhaVisivel)
	      return(<img src={iconeSenhaNaoVisivel} alt="" />)

	    return(<img src={iconeSenhaVisivel} alt="" />)
	}

	return(
		<span id="exibe-senha" onClick={() => {

          let tipoCampo = "text";

          if(senhaVisivel) 
            tipoCampo = "password";

          props.modificaTipoCampoSenha(tipoCampo)

          setSenhaVisivel(!senhaVisivel);

    }}>
    	{ icone() }
    </span>
	)
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { modificaTipoCampoSenha })(ControlaVisibilidadeSenha)