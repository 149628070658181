import React, { Component } from "react";
import ReactPlaceholder from 'react-placeholder';

export default class CarregandoArtigo extends Component {

	exibeCarregamentoLinha = (tamanho, altura) => {

		if(!tamanho)
			tamanho = "calc(100% - 20px)";

		if(!altura) 
			altura = 18;

	    return(
	      <ReactPlaceholder 
	        showLoadingAnimation={true} 
	        type='textRow' 
	        color='#E0E0E0' 
	        style={{ width: tamanho, height: altura, margin: '3px 10px', marginBottom: 0 }} 
	      />
	    )
	}

	exibeCarregamentoParagrafo = () => {

	    return(
	      <div style={{ marginTop: 40 }}>
	        
	        { this.exibeCarregamentoLinha() }
	        { this.exibeCarregamentoLinha() }
	        { this.exibeCarregamentoLinha() }
	        { this.exibeCarregamentoLinha() }
	        { this.exibeCarregamentoLinha() }
	        { this.exibeCarregamentoLinha("60%") }

	      </div>
	    )
	}

	render() {

		return(
			<div className="porta-artigo">
          
	          <div className="capa-artigo">
	            
	            <ReactPlaceholder 
	              showLoadingAnimation={true} 
	              type='textRow' 
	              color='#E0E0E0' 
	              style={{ width: '100%', height: '100%', borderRadius: 20, marginTop: 0 }} 
	            />

	          </div>

	          <div className="corpo-artigo">

	            <div className="cabecalho-artigo">

	              <ReactPlaceholder 
	                showLoadingAnimation={true} 
	                type='textRow' 
	                color='#E0E0E0' 
	                style={{ width: 'calc(100% - 20px)', height: 40, margin: 10, marginBottom: 0 }}
	              />

	              { this.exibeCarregamentoLinha("60%", 40) }

	              <div className="bloco-info-artigo">
	                { this.exibeCarregamentoLinha("30%") }
	                { this.exibeCarregamentoLinha("30%") }
	              </div>

	            </div>  

	            { this.exibeCarregamentoParagrafo() }
	            { this.exibeCarregamentoParagrafo() }
	            { this.exibeCarregamentoParagrafo() }
	            { this.exibeCarregamentoParagrafo() }  

	          </div>

	        </div>
		)
	}
}