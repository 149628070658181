const initState = {
  carregandoCampanhas: false,
  listaCampanhas: [],
  dadosCampanha: {},
  tabelaCampanhasAtualizada: false,
  carregandoEspecialistasCampanha: false,
  listaEspecialistasCampanha: [],
  atualizaTabelaCampanhas: false,
  idCampanha: "",
  dadosCampanhaEspecialista: {},
  historicoCampanhaAdmin: []
}

const campanhasReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_carregando_campanhas":
      return { ...state, carregandoCampanhas: action.payload }

    case "modifica_lista_campanhas":
      return { 
        ...state, 
        listaCampanhas: action.payload, 
        carregandoCampanhas: false,
        tabelaCampanhasAtualizada: true 
      }  

    case "atualizar_tabela_campanhas":
      return { ...state, tabelaCampanhasAtualizada: action.payload }  

    case "modifica_dados_campanha":
      return { ...state, dadosCampanha: action.payload }  

    case "modifica_carregando_especialistas_campanha":
      return { ...state, carregandoEspecialistasCampanha: action.payload }

    case "modifica_lista_especialistas_campanha":
      return { 
        ...state, 
        listaEspecialistasCampanha: action.payload, 
        carregandoEspecialistasCampanha: false,
        tabelaCampanhasAtualizada: true  
      }    

    case "modifica_atualiza_tabela_campanhas":
      return { ...state, atualizaTabelaCampanhas: action.payload }  

    case "modifica_id_campanha":
      return { ...state, idCampanha: action.payload }   

    case "modifica_dados_campanha_especialista":
      return { 
        ...state, 
        dadosCampanhaEspecialista: action.payload, 
        carregandoCampanhas: false 
      }   

    case "modifica_historico_campanha_admin":
      return { 
        ...state, 
        historicoCampanhaAdmin: action.payload, 
        carregandoCampanhas: false 
      }     

    default:
      return state
  }
}

export default campanhasReducer;
