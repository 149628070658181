import { firestore } from "../config/VariaveisFirebase";

import { ORDENA_DATAS } from './FuncoesGerais';
import { houveErroInicializacao } from "./AuxiliaresAcoes";

// altera a atualização de rotas de postagens
export const modificaRotasPostagensAtualizadas = valor => {
	return {
		type: "modifica_rotas_postagens_atualizadas",
		payload: valor
	}
}

// obtem as urls das postagens do blog
export const verificaRotasPostagens = () => {
	return dispatch => {

		dispatch({ 
			type: "modifica_rotas_postagens_atualizadas", 
			payload: false 
		});

		let rotas = [],
			colecoes = ["blog"],
			colecoesVerificadas = 0;

		colecoes.forEach(item => {

			firestore.collection("blog").get()
			.then(snapshot => {

				snapshot.docs.forEach(doc => {

					let { caminho, tipoArtigo, imgDestaque } = doc.data();

					if(imgDestaque !== "")
						rotas.push({ caminho, tipoArtigo });
				});

				setTimeout(() => {
					colecoesVerificadas++;
				}, 300);
			})
		});	

		let timer = setInterval(() => {

			if(colecoesVerificadas === colecoes.length) {

				clearInterval(timer);

				dispatch({ type: "modifica_rotas_postagens", payload: rotas });
			}
		}, 300);
	}
}

const organizaPosts = (artigos, categoria) => {

	let dados = {
		destaques: [],
		artigosSelecionados: [],
		postagens: []
	}

	artigos.forEach(doc => {

		let { categorias, titulo, data, tempoLeitura, imgDestaque, tipoArtigo, caminho, conteudo } = doc.data();

		if(imgDestaque !== "") 
			dados.postagens.push({ titulo, imgDestaque, categorias, data, tempoLeitura, tipoArtigo, caminho, conteudo });
	});

	ORDENA_DATAS(dados.postagens, "decrescente", "data");

	dados.postagens = dados.postagens.filter(item => {

		if(item.categorias.includes("Destaque") && dados.destaques.length < 3) {

			dados.destaques.push(item);

			return false;
		}

		if(item.categorias.includes(categoria) && dados.artigosSelecionados.length < 3) {

			dados.artigosSelecionados.push(item);

			return false;
		}

		return item;
	});	

	return dados;
}

// pega todas as postagens do blog, separando os 3 para destaque mais recentes e 3 para artigos selecionados mais recentes conforme a categoria definida
export const obtemPostagens = () => {
	return dispatch => {

		dispatch({ 
			type: "modifica_houve_erro_inicializacao", 
			payload: { status: false, msg: '' } 
		});

		dispatch({ type: "modifica_carregando_postagens_blog", payload: true });

		const colecao = firestore.collection("blog");

		colecao.get().then(snapshot => {

			if(snapshot.docs.length > 0) {

				let dados = organizaPosts(snapshot.docs, "Gestão de pessoas"),
					timer = setInterval(() => {

						if(dados) {

							clearInterval(timer);

							let { destaques, artigosSelecionados, postagens } = dados;

							dispatch({
								type: "modifica_postagens_blog",
								payload: { 
									destaques, artigosSelecionados, postagens 
								}
							});		
						}

					}, 300)

			} else {

				dispatch({
					type: "modifica_postagens_blog",
					payload: { 
						destaques: [], 
						artigosSelecionados: [], 
						postagens: [] 
					}
				});
			}
		})
		.catch(erro => houveErroInicializacao(erro))
	}
}

// pega todas as postagens do blog do tipo RH ou Saúde e bem estar
export const obtemPostagensTipoArtigo = (tipo, categoria) => {
	return dispatch => {

		dispatch({ 
			type: "modifica_houve_erro_inicializacao", 
			payload: { status: false, msg: '' } 
		});

		dispatch({ type: "modifica_carregando_postagens_blog", payload: true });

		const colecao = firestore.collection("blog");

		colecao.where("tipoArtigo", "==", tipo).get()
		.then(snapshot => {

			if(snapshot.docs.length > 0) {

				let dados = organizaPosts(snapshot.docs, categoria),
					timer = setInterval(() => {

						if(dados) {

							clearInterval(timer);

							let { destaques, artigosSelecionados, postagens } = dados;

							dispatch({
								type: "modifica_postagens_blog",
								payload: { 
									destaques, artigosSelecionados, postagens 
								}
							});		
						}

					}, 300)

			} else {

				dispatch({
					type: "modifica_postagens_blog",
					payload: { 
						destaques: [], 
						artigosSelecionados: [], 
						postagens: [] 
					}
				});
			}
		})
		.catch(erro => houveErroInicializacao(erro))
	}
}

export const obtemConteudoPostagem = url => {
	return dispatch => {

		dispatch({ 
			type: "modifica_houve_erro_inicializacao", 
			payload: { status: false, msg: '' } 
		});

		dispatch({ type: "modifica_carregando_postagem", payload: true });

		let blocoUrl = url.split("/"),
			colecao = firestore.collection("blog");

		blocoUrl.forEach(item => {

			colecao.where("caminho", "==", item).get()
			.then(snapshot => {

				if(snapshot.docs.length > 0) {

					snapshot.docs.map(doc => {

						let { autor, categorias, titulo, conteudo, data, tempoLeitura, imgDestaque } = doc.data();

						dispatch({ 
							type: "modifica_postagem", 
							payload: { autor, categorias, titulo, conteudo, data, tempoLeitura, imgDestaque } 
						});

						return false;
					});
				}
			})
			.catch(erro => houveErroInicializacao(erro))
		})
	}
}

export const obtemPostsAdmin = () => {
	return dispatch => {

		dispatch({ type: "modifica_houve_erro_inicializacao", payload: { status: false, msg: '' } });
		dispatch({ type: "modifica_carregando_posts_admin", payload: true });

		const colecao = firestore.collection("blog");

		colecao.get().then(snapshot => {

			if(snapshot.docs.length > 0) {

				let postagens = snapshot.docs.map(doc => {

					let { id, categorias, titulo, data, tipoArtigo, caminho, atualizadoEm, autor } = doc.data();

					return { id, categorias, titulo, data, tipoArtigo, caminho, atualizadoEm, autor }
				});

				ORDENA_DATAS(postagens, "decrescente", "data");

				dispatch({ type: "atualiza_postagens_admin", payload: postagens })		

			} else {
				dispatch({ type: "atualiza_postagens_admin", payload: [] })
			}
		})
		.catch(erro => houveErroInicializacao(erro))
	}
}