import { database } from '../../config/VariaveisFirebase';
import { confirmAlert } from 'react-confirm-alert';

// atualiza a lista de especialidades que os especialistas atendem
export const atualizaEspecialidadesAdicionadas = ({ id, especialidades, idEspecialidade }) => {
	return dispatch => {

		dispatch({ type: "modifica_loading", payload: true });

		database.ref('especialistas/' + id).update({ especialidades });

		database.ref(`especialidades/idEspecialistas/${idEspecialidade}/${id}`)
		.set({ autorizado: false })

		dispatch({ type: "seleciona_especialidade", payload: "" });

		dispatch({ type: "seleciona_locais_especialidade", payload: [] });

		dispatch({ 
			type: "modifica_especialidades_adicionadas", payload: especialidades 
		});

		dispatch({ 
			type: "modifica_especialidades_cadastradas_especialista", 
			payload: especialidades 
		});

		setTimeout(() => {
			dispatch({ type: "modifica_loading", payload: false });
		}, 1000)

		confirmAlert({
			title: 'Mais uma especialidade',
			message: 'Deseja adicionar uma nova especialidade?',
			buttons: [
				{
					label: 'Sim',
					onClick: () => {
						dispatch({ 
							type: "modifica_adicionar_nova_especialidade", 
							payload: true 
						})
					}
				},
				{
					label: 'Não',
					onClick: () => dispatch({ type: "modifica_passo", payload: 3 })
				}
			]
		});		
	}
}

// para o caso do(a) especialista não desejar adicionar uma nova especialidade
export const resetaAdicionarEspecialidade = () => {
	return {
		type: "modifica_adicionar_nova_especialidade", 
		payload: false 
	}
}