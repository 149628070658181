const initState = {
  nome: "",
  cpf: "",
  dataNascimento: "",
  tel: "",
  listaEspecialidadesBemEstar: [],
  listaEspecialidadesTelemedicina: [],
  especialidadesAdicionadas: {},
  adicionarNovaEspecialidade: false,
  diasSemanaAtendimento: [],
  horariosAtendimento: [],
  idiomasAtendimento: ["Português"],
  cep: "",
  numero: "",
  complemento: "",
  descricao: "",
  urlFotoPerfil: "",
  urlFotoMini: "",
  loadingUpFoto: false,
  videoApresentacao: "",
  cnpj: "",
  conselhoEntidade: "",
  conselhoNumero: "",
  passo: 0,
  contagemErros: 0,
  registroMsgErro: "",
  idRecrutadorQueIndicou: ''
}

const cadastroReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_nome":
      return { ...state, nome: action.payload }

    case "modifica_cpf":
      return { ...state, cpf: action.payload }

    case "modifica_data_nascimento":
      return { ...state, dataNascimento: action.payload }

    case "modifica_tel":
      return { ...state, tel: action.payload }

    case "modifica_lista_especialidades_bem_estar":
      return { ...state, listaEspecialidadesBemEstar: action.payload }

    case "modifica_lista_especialidades_telemedicina":
      return { ...state, listaEspecialidadesTelemedicina: action.payload }

    case "modifica_especialidades_adicionadas":
      return { ...state, especialidadesAdicionadas: action.payload }

    case "modifica_adicionar_nova_especialidade":
      return { ...state, adicionarNovaEspecialidade: action.payload }

    case "modifica_dias_semana":
      return { ...state, diasSemanaAtendimento: action.payload }

    case "modifica_horarios":
      return { ...state, horariosAtendimento: action.payload }

    case "modifica_idiomas":
      return { ...state, idiomasAtendimento: action.payload }

    case "modifica_cep":
      return { ...state, cep: action.payload }

    case "modifica_numero":
      return { ...state, numero: action.payload }

    case "modifica_complemento":
      return { ...state, complemento: action.payload }

    case "modifica_cnpj":
      return { ...state, cnpj: action.payload }

    case "modifica_url_foto_perfil":
      return { ...state, urlFotoPerfil: action.payload }

    case "modifica_url_foto_mini":
      return { ...state, urlFotoMini: action.payload }

    case "modifica_loading_up_foto":
      return { ...state, loadingUpFoto: action.payload }  

    case "modifica_descricao":
      return { ...state, descricao: action.payload }

    case "modifica_video_apresentacao":
      return { ...state, videoApresentacao: action.payload }

    case "modifica_conselho_entidade":
      return { ...state, conselhoEntidade: action.payload }

    case "modifica_conselho_numero":
      return { ...state, conselhoNumero: action.payload }

    case "modifica_passo":
      return { ...state, passo: action.payload }

    case "modifica_contagem_erros":
        return { ...state, contagemErros: action.payload }

    case "modifica_registro_msg_erro":
      return { ...state, registroMsgErro: action.payload }

    case "modifica_id_recrutador_que_indicou":
      return { ...state, idRecrutadorQueIndicou: action.payload }
       
    default:
      return state
  }
}

export default cadastroReducer;
