import React, { Component } from 'react';
import { connect } from "react-redux";

import Botao from "../../../global/BotaoAcao";
import RetornoFluxoCadastro from "../elementos/RetornoFluxoCadastro";
import SelecaoOpcoesAtendimento from "./formOpcoesAtendimentos/SelecaoOpcoesAtendimento";

import { verificaOpcoesAtendimento } from "../../../acoes/cadastroEspecialistas/GerenciaOpcoesAtendimento";

class FormOpcoesAtendimentos extends Component {

  verificaInfosAtendimentos = e => {

    e.preventDefault();

    let { id, diasSemana, horarios, idiomas } = this.props;

    this.props.verificaOpcoesAtendimento({ id, diasSemana, horarios, idiomas });
  }  

  render() {

    return (
      <form className="form-dados" id="form-selecao-atendimentos" onSubmit={this.verificaInfosAtendimentos}>

        <RetornoFluxoCadastro 
          passo={2} 
          titulo="Disponibilidade para atendimentos" 
        />

        <div className="corpo-form">

          <SelecaoOpcoesAtendimento 
            titulo="Selecione os dias da semana"
            opcoes={["SEG", "TER", "QUA", "QUI", "SEX", "SAB", "DOM"]}
            tipo="diasSemana"
          />

          <SelecaoOpcoesAtendimento 
            titulo="Selecione as faixas de horários"
            opcoes={["0 às 5h", "5 às 7h", "7 às 10h", "10 às 13h", "14 às 18h", "18 às 21h", "21 às 24h"]}
            tipo="horarios"
          />

          <SelecaoOpcoesAtendimento 
            titulo="Você tem interesse em atender em outro idioma?"
            opcoes={["Inglês", "Espanhol", "Italiano"]}
            tipo="idiomas"
          />

          <Botao textoBotao="avançar" classeBotao="botao-area" />

        </div>

      </form>
    );
  }
}

const mapStateToProps = state => (
	{
    id: state.authReducer.idUsuario,
    diasSemana: state.cadastroReducer.diasSemanaAtendimento,
    horarios: state.cadastroReducer.horariosAtendimento,
    idiomas: state.cadastroReducer.idiomasAtendimento
	}
)

export default connect(mapStateToProps, { verificaOpcoesAtendimento })(FormOpcoesAtendimentos)
