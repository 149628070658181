const initState = {
  mediaAvaliacoesEspecialista: 5,
  qtdVezesFavoritado: 0,
  infosBasicasEspecialista: {
    nome: "",
    email: "",
    cpf: "",
    dataNascimento: "",
    tel: ""
  },
  especialidadesCadastradasEspecialista: {},
  opcoesAtendimentoEspecialista: {
    diasSemana: [],
    horarios: [],
    idiomas: ["Português"]
  },
  coordenadasAtendimentoEspecialista: {
    latitude: 0,
    longitude: 0
  },
  enderecoAtendimentoEspecialista: {
    cep: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: ""
  },
  infosProfissionaisEspecialista: {
    descricao: "",
    videoApresentacao: "",
    cnpj: "",
    urlFotoPerfil: "",
    urlFotoMini: "",
    conselhoEntidade: "",
    conselhoNumero: ""
  },
  dadosBancariosEspecialista: {
    cpfCnpj: "",
    banco: "",
    tipoConta: "",
    numConta: "",
    agencia: "",
    nomeResponsavel: ""
  },
  documentosEspecialista: {
    urlFotoIdentidadeFrente: "",
    urlFotoIdentidadeVerso: "",
    urlFotoSegurandoIdentidade: "",
    urlFotoCarteiraConselho: "",
    urlComprovanteResidencia: "",
    urlRegistroMEICNPJ: "",
    urlComprovanteBancario: "",
    urlCertidaoNegativaCriminalEstadual: "",
    urlCertidaoNegativaCriminalFederal: "",
    urlSeguroResponsabilidadeCivil: ""
  },
  notificacoesEspecialista: {
    email: true,
    mobile: true
  },
  idEspecialidadeEditar: '',
  loadingUrlFotoIdentidadeFrente: false,
  loadingUrlFotoIdentidadeVerso: false,
  loadingUrlFotoSegurandoIdentidade: false,
  loadingUrlFotoCarteiraConselho: false,
  loadingUrlComprovanteResidencia: false,
  loadingUrlRegistroMEICNPJ: false,
  loadingUrlComprovanteBancario: false,
  loadingUrlCertidaoNegativaCriminalEstadual: false,
  loadingUrlCertidaoNegativaCriminalFederal: false,
  loadingUrlSeguroResponsabilidadeCivil: false,
  carregandoAlteracoesEspecialista: false,
  dadosAlteracoesEspecialista: [],
  verTutorial: false,
  modalTutorialVisivel: false,
  passoTutorial: 1
}

const especialistaReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_infos_basicas_especialista":
      return { ...state, infosBasicasEspecialista: action.payload }

    case "modifica_especialidades_cadastradas_especialista":
      return { ...state, especialidadesCadastradasEspecialista: action.payload }

    case "modifica_opcoes_atendimentos_especialista":
      return { ...state, opcoesAtendimentoEspecialista: action.payload }

    case "modifica_coordenadas_atendimento_especialista":
      return { ...state, coordenadasAtendimentoEspecialista: action.payload }

    case "modifica_endereco_atendimento_especialista":
      return { ...state, enderecoAtendimentoEspecialista: action.payload }

    case "modifica_infos_profissionais_especialista":
      return { ...state, infosProfissionaisEspecialista: action.payload }

    case "modifica_dados_bancarios_especialista":
      return { ...state, dadosBancariosEspecialista: action.payload }

    case "modifica_documentos_especialista":
      return { ...state, documentosEspecialista: action.payload }

    case "modifica_loading_url_foto_identidade_frente":
      return { ...state, loadingUrlFotoIdentidadeFrente: action.payload }   

    case "modifica_loading_url_foto_identidade_verso":
      return { ...state, loadingUrlFotoIdentidadeVerso: action.payload }    

    case "modifica_loading_url_foto_segurando_identidade":
      return { ...state, loadingUrlFotoSegurandoIdentidade: action.payload }   

    case "modifica_loading_url_foto_carteira_conselho":
      return { ...state, loadingUrlFotoCarteiraConselho: action.payload }   

    case "modifica_loading_url_comprovante_residencia":
      return { ...state, loadingUrlComprovanteResidencia: action.payload }    

    case "modifica_loading_url_registro_mei_cnpj":
      return { ...state, loadingUrlRegistroMEICNPJ: action.payload }   

    case "modifica_loading_url_comprovante_bancario":
      return { ...state, loadingUrlComprovanteBancario: action.payload }   

    case "modifica_loading_url_certidao_negativa_criminal_estadual":
      return { ...state, loadingUrlCertidaoNegativaCriminalEstadual: action.payload }   

    case "modifica_loading_url_certidao_negativa_criminal_federal":
      return { ...state, loadingUrlCertidaoNegativaCriminalFederal: action.payload }  
      
    case "modifica_loading_url_seguro_responsabilidade_civil":
      return { ...state, loadingUrlSeguroResponsabilidadeCivil: action.payload }  
      
    case "modifica_notificacoes_especialista":
      return { ...state, notificacoesEspecialista: action.payload }

    case "modifica_media_avaliacoes_especialista":
      return { ...state, mediaAvaliacoesEspecialista: action.payload }

    case "modifica_qtd_vezes_favoritado":
      return { ...state, qtdVezesFavoritado: action.payload }

    case "modifica_id_especialidade_editar":
      return { ...state, idEspecialidadeEditar: action.payload }

    case "modifica_carregando_alteracoes_especialista":
      return { ...state, carregandoAlteracoesEspecialista: action.payload }  

    case "modifica_dados_alteracoes_especialista":
      return { 
        ...state, 
        dadosAlteracoesEspecialista: action.payload,
        carregandoAlteracoesEspecialista: false
      }  

    case 'modifica_ver_tutorial':
      return { ...state, verTutorial: action.payload }    

    case 'remove_visibilidade_modal_tutorial': 
      return { ...state, modalTutorialVisivel: false }  

    case 'modifica_passo_tutorial':
      return { 
        ...state, 
        modalTutorialVisivel: true,
        passoTutorial: action.payload 
      }      

    default:
      return state
  }
}

export default especialistaReducer;
