const initState = {
  nomeUsuario: '',
	urlFotoMini: '',
	urlFotoPerfil: '',
	tel: '',
	cpf: '',
	dataNascimento: '',
  empresa: {
    id: "",
    nome: ""
  },
  //filiais: [],
  planoContratado: "",
  configuracoes: {
    promocoesPorNotificacoes: true,
    promocoesPorEmail: true
  },
  carregandoAcessoDashboard: false,
  habilitadosAcessoDashboard: [],
  atualizouHabilitados: false,
  atualizarTabelaHabilitados: false,
  listaColaboradoresAcessoDashoboard: [],
  carregandoLogsAlteracoes: false,
  logsAlteracoes: [],
  carregandoListaColaboradoresCreditos: false,
  listaColaboradoresCreditos: []
}

const rhEmpresaReducer = (state = initState, action) => {
  switch(action.type) {

    case "modifica_dados_usuario_rh":
      return{
        ...state,
        nomeUsuario: action.payload["nome"],
        tel: action.payload["tel"],
			 	urlFotoMini: action.payload['urlFotoMini'],
			 	urlFotoPerfil: action.payload['urlFotoPerfil'],
			 	cpf: action.payload['cpf'],
			 	dataNascimento: action.payload['dataNascimento'],
			 	empresa: action.payload['empresa'],
			 	//filiais: action.payload['filiais'],
			 	planoContratado: action.payload['planoContratado'],
			 	configuracoes: action.payload['configuracoes']
      }

    case "modifica_carregando_acesso_dashboard":
      return { ...state, carregandoAcessoDashboard: action.payload }

    case "modifica_dados_acesso_dashboard":
      return {
        ...state,
        habilitadosAcessoDashboard: action.payload["habilitados"],
        listaColaboradoresAcessoDashoboard: action.payload["listaColaboradores"],
        carregandoAcessoDashboard: false,
        atualizouHabilitados: true,
        atualizarTabelaHabilitados: true 
      }

    case "atualiza_habilitados_acesso_dashboard":
      return { 
        ...state, 
        habilitadosAcessoDashboard: action.payload, 
        atualizouHabilitados: true,
        atualizarTabelaHabilitados: true 
      }

    case "modifica_atualizou_habilitados":
      return { ...state, atualizouHabilitados: action.payload }  

    case "modifica_atualizar_tabela_habilitados":
      return { ...state, atualizarTabelaHabilitados: action.payload }    

    case "modifica_carregando_logs_alteracoes":
      return { ...state, carregandoLogsAlteracoes: action.payload }

    case "modifica_logs_alteracoes":
      return { ...state, logsAlteracoes: action.payload, carregandoLogsAlteracoes: false }

    case "modifica_carregando_lista_colaboradores_creditos":
      return { ...state, carregandoListaColaboradoresCreditos: action.payload }

    case "modifica_lista_colaboradores_creditos":
      return {
        ...state,
        listaColaboradoresCreditos: action.payload,
        carregandoListaColaboradoresCreditos: false
      }

    case "atualiza_dados_pessoais_rh":
      return {
        ...state,
        nomeUsuario: action.payload["nome"],
        tel: action.payload["tel"]
      }

    case "modifica_url_foto_perfil_usuario":
      return { ...state, urlFotoPerfil: action.payload }

    case "modifica_url_foto_mini_usuario":
      return { ...state, urlFotoMini: action.payload }

    default:
      return state
  }
}

export default rhEmpresaReducer;
